import axios from "axios";

// LIVE url:
// export const API_HOST = "http://backend1.redsling.com:8085/";
// export const API_HOST = "https://backend1.redsling.com/";

// staging url:
// export const API_HOST = "http://ec2-3-210-40-119.compute-1.amazonaws.com:8085/";
export const API_HOST = "https://staging-backend1.redsling.com/";

// export const API_HOST = "http://127.0.0.1:8085/";

var host_api = API_HOST;
export const create_page = async (name) => {
  try {
    const response = await axios.post(`${API_HOST}pages/`, { name });
    return response.data;
  } catch (error) {
    console.log("Error : >> ", error);
  }
};
