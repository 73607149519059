// BlocksIDE is a project to create a complete js Blocks Development Platform
//
// Copyright 2016 Juan Carlos Orozco
//
// BlocksIDE was written by Juan Carlos Orozco and released under an Apache version 2 license.
//
// Git repositories for BlocksIDE are available at
//
// https://github.com/JC-Orozco/BlocksIDE
//
// Licensed under the Apache License, Version 2.0 (the 'License');
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// import { API_HOST } from "../../../../../../../api_utils";

module.exports = function (Blockly, editor, tableName, tableData) {
  var hostModule = require("../../../../../../../api_utils");
  //var goog = Blockly.goog;
  ("use strict");

  //goog.provide('Blockly.Blocks.mm');

  //goog.require('Blockly.Blocks');

  // TODO: JCOA Add drop down list with operator options
  Blockly.Blocks["bi_comment"] = {
    init: function () {
      this.appendDummyInput().appendField("comment");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(90);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  // TODO: JCOA Add drop down list with operator options
  Blockly.Blocks["bi_assignment"] = {
    init: function () {
      this.appendValueInput("A").setCheck(null).appendField("");
      this.appendValueInput("B")
        .setCheck(null)
        .appendField(new Blockly.FieldTextInput("="), "OP");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  // TODO: JCOA Add drop down list with operator options
  Blockly.Blocks["bi_assignment_return"] = {
    init: function () {
      this.appendValueInput("A").setCheck(null).appendField("");
      this.appendValueInput("B")
        .setCheck(null)
        .appendField(new Blockly.FieldTextInput("="), "OP");
      this.setInputsInline(true);
      this.setOutput(true);
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_math_arithmetic"] = {
    /**
     * Block for basic arithmetic operator.
     * @this Blockly.Block
     */
    init: function () {
      var OPERATORS = [
        [Blockly.Msg.MATH_ADDITION_SYMBOL, "ADD"],
        [Blockly.Msg.MATH_SUBTRACTION_SYMBOL, "MINUS"],
        [Blockly.Msg.MATH_MULTIPLICATION_SYMBOL, "MULTIPLY"],
        [Blockly.Msg.MATH_DIVISION_SYMBOL, "DIVIDE"],
        [Blockly.Msg.MATH_POWER_SYMBOL, "POWER"],
      ];
      this.setHelpUrl(Blockly.Msg.MATH_ARITHMETIC_HELPURL);
      this.setColour(270);
      this.setOutput(true, "Number");
      this.appendValueInput("A").setCheck(null); // .setCheck('Number');
      this.appendValueInput("B")
        .setCheck(null) // .setCheck('Number')
        .appendField(new Blockly.FieldDropdown(OPERATORS), "OP");
      this.setInputsInline(true);
      // Assign 'this' to a variable for use in the tooltip closure below.
      var thisBlock = this;
      this.setTooltip(function () {
        var mode = thisBlock.getFieldValue("OP");
        var TOOLTIPS = {
          ADD: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_ADD,
          MINUS: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_MINUS,
          MULTIPLY: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_MULTIPLY,
          DIVIDE: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_DIVIDE,
          POWER: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_POWER,
        };
        return TOOLTIPS[mode];
      });
    },
  };

  Blockly.Blocks["bi_logic_compare"] = {
    /**
     * Block for comparison operator.
     * @this Blockly.Block
     */
    init: function () {
      var OPERATORS = this.RTL
        ? [
            ["=", "EQ"],
            ["\u2260", "NEQ"],
            [">", "LT"],
            ["\u2265", "LTE"],
            ["<", "GT"],
            ["\u2264", "GTE"],
          ]
        : [
            ["=", "EQ"],
            ["\u2260", "NEQ"],
            ["<", "LT"],
            ["\u2264", "LTE"],
            [">", "GT"],
            ["\u2265", "GTE"],
          ];
      this.setHelpUrl(Blockly.Msg.LOGIC_COMPARE_HELPURL);
      this.setColour(190);
      this.setOutput(true, "Boolean");
      this.appendValueInput("A");
      this.appendValueInput("B").appendField(
        new Blockly.FieldDropdown(OPERATORS),
        "OP"
      );
      this.setInputsInline(true);
      // Assign 'this' to a variable for use in the tooltip closure below.
      var thisBlock = this;
      this.setTooltip(function () {
        var op = thisBlock.getFieldValue("OP");
        var TOOLTIPS = {
          EQ: Blockly.Msg.LOGIC_COMPARE_TOOLTIP_EQ,
          NEQ: Blockly.Msg.LOGIC_COMPARE_TOOLTIP_NEQ,
          LT: Blockly.Msg.LOGIC_COMPARE_TOOLTIP_LT,
          LTE: Blockly.Msg.LOGIC_COMPARE_TOOLTIP_LTE,
          GT: Blockly.Msg.LOGIC_COMPARE_TOOLTIP_GT,
          GTE: Blockly.Msg.LOGIC_COMPARE_TOOLTIP_GTE,
        };
        return TOOLTIPS[op];
      });
      this.prevBlocks_ = [null, null];
    },
    /**
     * Called whenever anything on the workspace changes.
     * Prevent mismatched types from being compared.
     * @param {!Blockly.Events.Abstract} e Change event.
     * @this Blockly.Block
     */
    //  onchange: function(e) {
    //    var blockA = this.getInputTargetBlock('A');
    //    var blockB = this.getInputTargetBlock('B');
    //    // Disconnect blocks that existed prior to this change if they don't match.
    //    if (blockA && blockB &&
    //        !blockA.outputConnection.checkType_(blockB.outputConnection)) {
    //      // Mismatch between two inputs.  Disconnect previous and bump it away.
    //      // Ensure that any disconnections are grouped with the causing event.
    //      Blockly.Events.setGroup(e.group);
    //      for (var i = 0; i < this.prevBlocks_.length; i++) {
    //        var block = this.prevBlocks_[i];
    //        if (block === blockA || block === blockB) {
    //          block.unplug();
    //          block.bumpNeighbours_();
    //        }
    //      }
    //      Blockly.Events.setGroup(false);
    //    }
    //    this.prevBlocks_[0] = blockA;
    //    this.prevBlocks_[1] = blockB;
    //  }
  };

  Blockly.Blocks["bi_logic_operation"] = {
    /**
     * Block for logical operations: 'and', 'or'.
     * @this Blockly.Block
     */
    init: function () {
      var OPERATORS = [
        [Blockly.Msg.LOGIC_OPERATION_AND, "AND"],
        [Blockly.Msg.LOGIC_OPERATION_OR, "OR"],
      ];
      this.setHelpUrl(Blockly.Msg.LOGIC_OPERATION_HELPURL);
      this.setColour(190);
      this.setOutput(true, "Boolean");
      this.appendValueInput("A");
      //.setCheck('Boolean');
      this.appendValueInput("B")
        //.setCheck('Boolean')
        .appendField(new Blockly.FieldDropdown(OPERATORS), "OP");
      this.setInputsInline(true);
      // Assign 'this' to a variable for use in the tooltip closure below.
      var thisBlock = this;
      this.setTooltip(function () {
        var op = thisBlock.getFieldValue("OP");
        var TOOLTIPS = {
          AND: Blockly.Msg.LOGIC_OPERATION_TOOLTIP_AND,
          OR: Blockly.Msg.LOGIC_OPERATION_TOOLTIP_OR,
        };
        return TOOLTIPS[op];
      });
    },
  };

  Blockly.Blocks["bi_try_catch"] = {
    init: function () {
      this.appendStatementInput("try").setCheck(null).appendField("try");
      this.appendStatementInput("catch")
        .setCheck(null)
        .appendField("catch")
        .appendField(new Blockly.FieldTextInput(""), "parameter");
      this.appendStatementInput("finally")
        .setCheck(null)
        .appendField("finally");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(90);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_catch"] = {
    init: function () {
      this.appendStatementInput("catch")
        .setCheck(null)
        .appendField("catch")
        .appendField(new Blockly.FieldTextInput(""), "parameter");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(90);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_throw"] = {
    init: function () {
      this.appendValueInput("throw").setCheck(null).appendField("throw");
      // this.setOutput(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_yield"] = {
    init: function () {
      this.appendValueInput("yield")
        .setCheck(null)
        .appendField("yield")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "delegate");
      //this.setOutput(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_yield_return"] = {
    init: function () {
      this.appendValueInput("yield")
        .setCheck(null)
        .appendField("yield")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "delegate");
      this.setOutput(true);
      //    this.setPreviousStatement(true, null);
      //    this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_code_part"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Code part")
        .appendField(new Blockly.FieldTextInput(""), "code");
      this.setOutput(true, null);
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_code_line"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Code line")
        .appendField(new Blockly.FieldTextInput(""), "code");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_access_field"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldVariable("item"), "variable");
      this.appendDummyInput()
        .appendField(".")
        .appendField(new Blockly.FieldTextInput(""), "field");
      this.appendValueInput("input").appendField("to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setInputsInline(true);
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_set_to"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldTextInput(""), "code");
      this.appendValueInput("input").appendField("to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setInputsInline(true);
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_for"] = {
    init: function () {
      //    this.appendDummyInput()
      //        .appendField('for');
      this.appendStatementInput("init").appendField("for init");
      this.appendValueInput("test").appendField("test");
      this.appendStatementInput("update").appendField("update");
      this.appendStatementInput("chain").appendField("loop").setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      //this.setInputsInline(true);
      this.setColour(120);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  // Generate a let (variable name) instruction on the js generator.
  Blockly.Blocks["bi_for_in"] = {
    init: function () {
      this.appendValueInput("array")
        .appendField("for")
        .appendField(new Blockly.FieldTextInput("i"), "var")
        .appendField("in");
      this.appendStatementInput("chain").setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      //this.setInputsInline(true);
      this.setColour(120);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_continue"] = {
    init: function () {
      this.appendDummyInput().appendField("continue");
      this.setHelpUrl("http://www.example.com/");
      this.setColour(120);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip("");
    },
  };

  Blockly.Blocks["bi_break"] = {
    init: function () {
      this.appendDummyInput().appendField("break");
      this.setHelpUrl("http://www.example.com/");
      this.setColour(120);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip("");
    },
  };

  Blockly.Blocks["bi_s1"] = {
    /**
     * Block for creating a list with any number of elements of any type.
     * @this Blockly.Block
     */
    init: function () {
      this.setHelpUrl("http://www.example.com/");
      this.setColour(290);
      this.appendAddSubStatement("for init", "items", null, "");
      this.appendValueInput("test").appendField("test").setCheck(null);
      this.itemCount_ = 1;
      //this.updateShape_();
      this.setInputsInline(false);
      this.setPreviousStatement(true, null); // 'Method');
      this.setNextStatement(true, null); // 'Method');
      //this.setOutput(true, 'Array');
      this.setTooltip("");
    },
  };

  Blockly.Blocks["bi_return"] = {
    init: function () {
      this.appendValueInput("ret").setCheck(null).appendField("return");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(290);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_var"] = {
    init: function () {
      this.appendValueInput("val")
        .setCheck(null)
        .appendField(
          new Blockly.FieldDropdown([
            ["var", "var"],
            ["let", "let"],
            ["const", "const"],
          ]),
          "var_type"
        )
        .appendField(new Blockly.FieldTextInput("var1"), "var");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_var_name"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("")
        .appendField(new Blockly.FieldTextInput("var1"), "NAME");
      this.setOutput(true, null);
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_new"] = {
    init: function () {
      this.appendValueInput("chain").appendField("new").setCheck(null);
      //this.setPreviousStatement(true, null);
      this.setOutput(true, null);
      this.setColour(55);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_anonymous_class"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("class")
        .appendField(new Blockly.FieldTextInput(""), "NAME");
      this.appendDummyInput()
        .appendField("extends")
        .appendField(new Blockly.FieldTextInput(""), "extends");
      this.appendStatementInput("chain").setCheck(null);
      this.setHelpUrl("http://www.example.com/");
      this.setColour(55);
      //this.setPreviousStatement(true, null);
      //this.setNextStatement(true, null);
      this.setOutput(true, null);
      this.setTooltip("");
    },
  };

  Blockly.Blocks["bi_class"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("class")
        .appendField(new Blockly.FieldTextInput("Name"), "NAME");
      this.appendDummyInput()
        .appendField("extends")
        .appendField(new Blockly.FieldTextInput(""), "extends");
      this.appendStatementInput("chain").setCheck(null);
      this.setHelpUrl("http://www.example.com/");
      this.setColour(55);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      //this.setOutput(true, null);
      this.setTooltip("");
    },
  };

  Blockly.Blocks["bi_static"] = {
    init: function () {
      this.appendStatementInput("static").setCheck(null).appendField("static");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(55);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_get"] = {
    init: function () {
      this.appendStatementInput("get").setCheck(null).appendField("get");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(55);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_set"] = {
    init: function () {
      this.appendStatementInput("set").setCheck(null).appendField("set");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(55);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_field"] = {
    init: function () {
      this.appendValueInput("chain")
        .appendField(new Blockly.FieldTextInput("field1"), "NAME")
        .setCheck(null); // (['Field','Method']);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_field_return"] = {
    init: function () {
      this.appendValueInput("chain")
        .appendField("")
        .appendField(new Blockly.FieldTextInput("field1"), "NAME")
        .setCheck(null); // (['Field','Method']);
      this.setOutput(true, null); // 'Field');
      //this.setPreviousStatement(true, 'Field');
      this.setColour(330);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_string_return"] = {
    init: function () {
      this.appendValueInput("chain")
        .appendField('"')
        .appendField(new Blockly.FieldTextInput(""), "NAME")
        .appendField('"')
        .setCheck(null); // (['Field','Method']);
      this.setOutput(true, null); // 'Field');
      //this.setPreviousStatement(true, 'Field');
      this.setColour(160);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_index"] = {
    init: function () {
      this.appendValueInput("index").appendField("[").setCheck(null); // (['Field','Method']);
      this.appendValueInput("chain").appendField("]").setCheck(null); // (['Field','Method']);
      this.setInputsInline(true);
      this.setOutput(true, null); // 'Field');
      //this.setPreviousStatement(true, 'Field');
      this.setColour(260);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_adaptor"] = {
    init: function () {
      this.appendDummyInput().appendField("");
      this.appendStatementInput("chain").setCheck(null);
      //this.setPreviousStatement(true, null);
      this.setOutput(true, null);
      this.setColour(55);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_statement"] = {
    init: function () {
      this.appendDummyInput().appendField("");
      this.appendStatementInput("chain").setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      //this.setOutput(true, null);
      this.setColour(290);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  // TODO: JCOA Make a drop down list of unary operators
  Blockly.Blocks["bi_unary"] = {
    init: function () {
      this.appendValueInput("expression")
        .setCheck(null)
        .appendField(new Blockly.FieldTextInput("++"), "operator");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      //this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_unary_return"] = {
    init: function () {
      this.appendValueInput("expression")
        .setCheck(null)
        .appendField(new Blockly.FieldTextInput("++"), "operator");
      //    this.setPreviousStatement(true, null);
      //    this.setNextStatement(true, null);
      this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  // TODO: JCOA Make a drop down list of unary operators
  Blockly.Blocks["bi_unary_postfix"] = {
    init: function () {
      this.appendValueInput("expression")
        .appendField("postfix")
        .setCheck(null)
        .appendField(new Blockly.FieldTextInput("++"), "operator");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      //this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_unary_postfix_return"] = {
    init: function () {
      this.appendValueInput("expression")
        .appendField("postfix")
        .setCheck(null)
        .appendField(new Blockly.FieldTextInput("++"), "operator");
      //    this.setPreviousStatement(true, null);
      //    this.setNextStatement(true, null);
      this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_spread"] = {
    init: function () {
      this.appendValueInput("arg_array").setCheck(null).appendField("...");
      this.setOutput(true, null);
      this.setColour(290);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  Blockly.Blocks["bi_parenthesis"] = {
    init: function () {
      this.appendValueInput("expression").setCheck(null).appendField("(_)");
      this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("http://www.example.com/");
    },
  };

  // Custom blocks
  Blockly.Blocks["api_call_block"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Make POST API Call and Store Response in List"
      );
      this.appendValueInput("body")
        .setCheck(null)
        .appendField("Predefined Body");
      this.appendStatementInput("response_handler")
        .setCheck(null)
        .appendField("Response Handler");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
      // Add a predefined URL and body fields
      this.url = `${hostModule.API_HOST}customroutes/get`; // Modify with your predefined URL
      this.body = {};
    },
  };

  Blockly.Blocks["response_handler_block"] = {
    init: function () {
      this.appendDummyInput().appendField("On Response Received");
      this.appendStatementInput("response_code")
        .setCheck(null)
        .appendField("Response Code");
      this.appendStatementInput("response_data")
        .setCheck(null)
        .appendField("Response Data");
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["response_code_block"] = {
    init: function () {
      this.appendDummyInput().appendField("Response Code");
      this.setOutput(true, "Number");
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["response_data_block"] = {
    init: function () {
      this.appendDummyInput().appendField("Response Data");
      this.setOutput(true, "Array");
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["parent_connection"] = {
    init: function () {
      console.log("EDITORRRRRRRRRRR", editor);
      this.appendDummyInput().appendField("Connection Parent");
      this.setOutput(true, "Array");
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["custom_block_type"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck(null) // Allow any type of input value
        .appendField(new Blockly.FieldVariable("MYVAR"), "MYVAR")
        .appendField("=")
        .appendField(
          new Blockly.FieldTextInput("EDITOR_INSTANCE"),
          "EDITOR_INSTANCE"
        );

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["to_number"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck(null) // Allow any type of input value
        .appendField("to Number");

      this.setOutput(true, null); // Configure the block as an output value block
      this.setColour(230);
      this.setTooltip("Convert the value to a number.");
      this.setHelpUrl("");
    },
  };

  // type casting starts here::::::::::::::::::
  Blockly.Blocks["to_string"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck(null) // Allow any type of input value
        .appendField("to String");

      this.setOutput(true, null); // Configure the block as an output value block
      this.setColour(230);
      this.setTooltip("Convert the value to a String.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["check_nan"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck(null) // Allow any type of input value
        .appendField("Is NaN?");

      this.setOutput(true, "Boolean"); // Configure the block as an output boolean value block
      this.setColour(230);
      this.setTooltip("Check if the value is NaN.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["typeof"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck(null) // Allow any type of input value
        .appendField("typeof");

      this.setOutput(true, "String"); // Configure the block as an output string value block
      this.setColour(230);
      this.setTooltip("Get the type of the value.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["string_includes"] = {
    init: function () {
      this.appendValueInput("TEXT")
        .setCheck("String") // Allow a string as the text input
        .appendField("String");

      this.appendValueInput("SUBSTRING")
        .setCheck("String") // Allow a string as the substring input
        .appendField("includes");

      this.setOutput(true, "Boolean"); // Configure the block as an output boolean value block
      this.setColour(230);
      this.setTooltip("Check if a string contains a substring.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["string_includes"] = {
    init: function () {
      this.appendValueInput("TEXT")
        .setCheck("String") // Allow a string as the text input
        .appendField("String");

      this.appendValueInput("SUBSTRING")
        .setCheck("String") // Allow a string as the substring input
        .appendField("includes");

      this.setOutput(true, "Boolean"); // Configure the block as an output boolean value block
      this.setColour(230);
      this.setTooltip("Check if a string contains a substring.");
      this.setHelpUrl("");
    },
  };

  // style manipulation:::::::::::::::::::::::::::::
  Blockly.Blocks["grapesjs_display"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set display to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grapesjs_width"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set width to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grapesjs_height"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set height to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grapesjs_margin"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set margin to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grapesjs_padding"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set padding to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grapesjs_border"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set border to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grapesjs_border_radius"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set border radius to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grapesjs_color"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set text color to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grapesjs_background_color"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set background color to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grapesjs_background_image"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set background image to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  // Blockly.Blocks["grapesjs_linear_gradient"] = {
  //   init: function () {
  //     this.appendValueInput("ANGLE")
  //       .setCheck("String")
  //       .appendField("Set Linear Gradient with Angle");

  //     this.appendValueInput("COLORS")
  //       .setCheck("Array")
  //       .appendField("Color Stops (Array):");

  //     this.setPreviousStatement(true, null);
  //     this.setNextStatement(true, null);
  //     this.setColour(230);
  //     this.setTooltip(
  //       "Set a linear gradient background with angle and color stops to the selected component in GrapesJS."
  //     );
  //     this.setHelpUrl("");
  //   },
  // };

  Blockly.Blocks["onclick_event"] = {
    init: function () {
      this.appendDummyInput().appendField("On Click");

      this.appendStatementInput("DO").appendField("Do");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Add code to execute when the element is clicked.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["onchange_event"] = {
    init: function () {
      this.appendDummyInput().appendField("On Change");

      this.appendStatementInput("DO").appendField("Do");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Add code to execute when the element value is changed.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["onmouseover_event"] = {
    init: function () {
      this.appendDummyInput().appendField("On Mouseover");

      this.appendStatementInput("DO").appendField("Do");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip(
        "Add code to execute when the mouse is hovered over the element. "
      );
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["onmouseout_event"] = {
    init: function () {
      this.appendDummyInput().appendField("On Mouseout");

      this.appendStatementInput("DO").appendField("Do");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip(
        "Add code to execute when the mouse moves away from the element."
      );
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["get_property_from_event_target"] = {
    init: function () {
      this.appendDummyInput()
        .appendField(new Blockly.FieldTextInput("value"), "PROPERTY")
        .appendField("from event.target");

      this.setOutput(true, "String");
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["set_property_of_event_target"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Set")
        .appendField(new Blockly.FieldTextInput("value"), "PROPERTY")
        .appendField("of event.target to");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["set_css_property"] = {
    init: function () {
      this.appendValueInput("PROPERTY")
        .setCheck("String")
        .appendField("Set CSS property");
      this.appendValueInput("VALUE").setCheck("String").appendField("to");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  // FORM ELEMENTS BEGANS HERE::::::
  Blockly.Blocks["email_input"] = {
    init: function () {
      this.appendDummyInput().appendField("Email Input");
      this.setOutput(true, "String"); // Set the block to output a string value
      this.setColour(230);
      this.setTooltip("Get the value of the Email Input");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["text_input"] = {
    init: function () {
      this.appendDummyInput().appendField("Text Input");
      this.setOutput(true, "String"); // Set the block to output a string value
      this.setColour(230);
      this.setTooltip("Get the value of the Text Input");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["number_input"] = {
    init: function () {
      this.appendDummyInput().appendField("Number Input");
      this.setOutput(true, "String"); // Set the block to output a string value
      this.setColour(230);
      this.setTooltip("Get the value of the Number Input");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["password_input"] = {
    init: function () {
      this.appendDummyInput().appendField("Password Input");
      this.setOutput(true, "String"); // Set the block to output a string value
      this.setColour(230);
      this.setTooltip("Get the value of the Password Input");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["phone_input"] = {
    init: function () {
      this.appendDummyInput().appendField("Phone Input");
      this.setOutput(true, "String"); // Set the block to output a string value
      this.setColour(230);
      this.setTooltip("Get the value of the Phone Input");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["submit_button"] = {
    init: function () {
      this.appendDummyInput().appendField("Submit Button");
      this.appendDummyInput()
        .appendField("On Event")
        .appendField(
          new Blockly.FieldDropdown([
            ["Click", "click"],
            ["Change", "change"],
          ]),
          "EVENT"
        );
      this.appendStatementInput("DO").appendField("Do");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Add event handler for the Submit Button");
      this.setHelpUrl("");
    },
  };

  // Define the block for making API calls
  // Blockly.Blocks["api_call"] = {
  //   init: function () {
  //     this.appendDummyInput().appendField("Make API Call");
  //     this.appendDummyInput()
  //       .appendField("Method")
  //       .appendField(
  //         new Blockly.FieldDropdown([
  //           ["GET", "GET"],
  //           ["POST", "POST"],
  //           ["PUT", "PUT"],
  //           ["DELETE", "DELETE"],
  //           // Add more HTTP methods as needed
  //         ]),
  //         "METHOD"
  //       );
  //     this.appendDummyInput()
  //       .appendField("Authentication")
  //       .appendField(
  //         new Blockly.FieldDropdown([
  //           ["None", "none"],
  //           ["Basic Auth", "basic"],
  //           ["Bearer Token", "bearer"],
  //           // Add more authentication options as needed
  //         ]),
  //         "AUTHENTICATION"
  //       );
  //     this.appendValueInput("HEADERS")
  //       .setCheck("Object")
  //       .appendField("Headers");
  //     this.appendValueInput("PAYLOAD")
  //       .setCheck("json_payload")
  //       .appendField("Payload");
  //     this.setPreviousStatement(true, null);
  //     this.setNextStatement(true, null);
  //     this.setColour(230);
  //     this.setTooltip("Make an API call with the specified options");
  //     this.setHelpUrl("");
  //   },
  // };

  // Blockly.Blocks["json_payload"] = {
  //   init: function () {
  //     this.appendDummyInput().appendField("JSON Payload");
  //     this.appendStatementInput("KEY_VALUE_PAIRS")
  //       .setCheck("key_value_pair")
  //       .appendField("Key-Value Pairs");
  //     this.setPreviousStatement(true, null);
  //     this.setNextStatement(true, null);
  //     this.setColour(230);
  //     this.setTooltip("Define a JSON object for the API payload");
  //     this.setHelpUrl("");
  //   },
  // };

  // Blockly.Blocks["key_value_pair"] = {
  //   init: function () {
  //     this.appendValueInput("KEY").setCheck("String").appendField("Key");
  //     this.appendValueInput("VALUE").setCheck(null).appendField("Value");
  //     this.setInputsInline(true);
  //     this.setPreviousStatement(true, "key_value_pair");
  //     this.setNextStatement(true, "key_value_pair");
  //     this.setColour(210);
  //     this.setTooltip("Add a key-value pair to the JSON object");
  //     this.setHelpUrl("");
  //   },
  // };

  Blockly.Blocks["create_variable"] = {
    init: function () {
      this.appendDummyInput().appendField("Create Variable");
      this.appendValueInput("NAME").setCheck("String").appendField("Name:");
      this.appendValueInput("DEFAULT_VALUE")
        .setCheck("String")
        .appendField("Default Value:");
      this.appendValueInput("TYPE").setCheck("String").appendField("Type:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Create a variable");
    },
  };

  Blockly.Blocks["create_function"] = {
    init: function () {
      this.appendDummyInput().appendField("Create Function");
      this.appendValueInput("NAME").setCheck("String").appendField("Name:");
      this.appendValueInput("CODE").setCheck("String").appendField("Code:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Create a function");
    },
  };

  Blockly.Blocks["api_call"] = {
    init: function () {
      this.appendValueInput("URL")
        .setCheck("String")
        .appendField("Make API Call to URL:");
      this.appendDummyInput("METHOD")
        .appendField("Method:")
        .appendField(
          new Blockly.FieldDropdown([
            ["GET", "GET"],
            ["POST", "POST"],
            ["PUT", "PUT"],
            ["DELETE", "DELETE"],
          ]),
          "HTTP_METHOD"
        );
      this.appendStatementInput("HEADERS")
        .setCheck("header_block")
        .appendField("Headers:");
      this.appendStatementInput("PAYLOAD").appendField("Payload:");
      this.appendStatementInput("USER_LOGIC_SUCCESS")
        .setCheck(null)
        .appendField("User Logic on Success:");
      this.appendStatementInput("USER_LOGIC_FAILURE")
        .setCheck(null)
        .appendField("User Logic on Failure:");
      this.appendValueInput("RESPONSE_VARIABLE")
        .setCheck("Variable")
        .appendField("Store Response Data in Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip(
        "Make an API call with the specified URL, method, headers, and payload."
      );
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["api_call_V2"] = {
    init: function () {
      this.appendValueInput("URL")
        .setCheck("String")
        .appendField("Make API Call to URL:");
      this.appendDummyInput("Custom_Model")
        .appendField("Select Custom Model:")
        .appendField(new Blockly.FieldDropdown(tableName), "arrayName");
      this.appendDummyInput("customOrDefaultRoute")
        .appendField("Is This A Default Endpoint?")
        .appendField(
          new Blockly.FieldDropdown([
            ["Yes", "Yes"],
            ["No", "No"],
          ]),
          "CUSTOM_DEFAULT_ROUTE"
        );

      this.appendDummyInput("METHOD")
        .appendField("Method:")
        .appendField(
          new Blockly.FieldDropdown([
            ["GET", "GET"],
            ["POST", "POST"],
            ["PUT", "PUT"],
            ["DELETE", "DELETE"],
          ]),
          "HTTP_METHOD"
        );
      this.appendStatementInput("HEADERS")
        .setCheck("header_block")
        .appendField("Headers:");
      this.appendStatementInput("PAYLOAD").appendField("Payload:");
      this.appendStatementInput("USER_LOGIC_SUCCESS")
        .setCheck(null)
        .appendField("User Logic on Success:");
      this.appendStatementInput("USER_LOGIC_FAILURE")
        .setCheck(null)
        .appendField("User Logic on Failure:");
      this.appendValueInput("RESPONSE_VARIABLE")
        .setCheck("Variable")
        .appendField("Store Response Data in Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip(
        "Make an API call with the specified URL, method, headers, and payload."
      );
      this.setHelpUrl("");

      this._lastSelectedModelName = null;
      this._initialized = false;
      this.setOnChange(this.updatePayloadBlocks.bind(this));
      // this.createResponseVariable();
    },

    // createResponseVariable: function () {
    //   if (!this.variableName) {
    //     // Create a custom name based on the selected model name or any custom logic
    //     const customNamePrefix = "response_" + this.id;
    //     this.variableName = Blockly.Variables.generateUniqueName(
    //       this.workspace,
    //       customNamePrefix
    //     );

    //     this.workspace.createVariable(this.variableName);

    //     // Create a variable block and connect it to RESPONSE_VARIABLE
    //     const responseVarBlock = this.workspace.newBlock("variables_get");
    //     responseVarBlock.setFieldValue(this.variableName, "VAR");
    //     responseVarBlock.initSvg();
    //     responseVarBlock.render();

    //     const responseVarInput = this.getInput("RESPONSE_VARIABLE");
    //     responseVarInput.connection.connect(responseVarBlock.outputConnection);
    //   }
    // },

    updatePayloadBlocks: function () {
      if (this.workspace.isDragging()) {
        // Prevent updates while dragging
        return;
      }
      const selectedModelName = this.getFieldValue("arrayName");

      if (!this._initialized) {
        this._initialized = true;
        this._lastSelectedModelName = selectedModelName;
        return;
      }

      if (selectedModelName === this._lastSelectedModelName) {
        return;
      }

      const payloadInput = this.getInput("PAYLOAD");
      if (
        payloadInput &&
        payloadInput.connection &&
        this._lastSelectedModelName !== null
      ) {
        console.log("payloadInput::::::::", payloadInput);

        let connectedBlock = payloadInput.connection.targetBlock();
        while (connectedBlock) {
          const nextBlock = connectedBlock.getNextBlock();
          connectedBlock.dispose(true);
          connectedBlock = nextBlock;
        }
      }

      this._lastSelectedModelName = selectedModelName;

      console.log("selectedModelName:::::::::", selectedModelName);
      console.log("tableData::::::", tableData);
      const selectedModel = tableData.find(
        (model) => model.table_name === selectedModelName
      );

      console.log("selectedModel:::::", selectedModel);
      console.log("payloadInput:::::", payloadInput);

      if (selectedModel) {
        selectedModel.fields.forEach((obj) => {
          let keyName = Object.values(obj)[0];
          console.log("keyName::::::::::", keyName);
          let payloadBlock = this.workspace.newBlock("payload_block");
          payloadBlock.initSvg();
          console.log("payloadBlock:::::::", payloadBlock);

          let keyBlock = this.workspace.newBlock("text");
          keyBlock.setFieldValue(keyName, "TEXT");
          keyBlock.initSvg();
          keyBlock.render();

          console.log("keyBlock:", keyBlock);

          payloadBlock
            .getInput("PAYLOAD_KEY")
            .connection.connect(keyBlock.outputConnection);

          payloadBlock.render();

          payloadInput.connection.connect(payloadBlock.previousConnection);
        });
      }

      // Header addition
      const headerInput = this.getInput("HEADERS");
      if (
        headerInput &&
        headerInput.connection &&
        this._lastSelectedModelName !== null
      ) {
        // Dispose of all connected blocks to the Header input
        let connectedBlock = headerInput.connection.targetBlock();
        while (connectedBlock) {
          const nextBlock = connectedBlock.getNextBlock();
          connectedBlock.dispose(true);
          connectedBlock = nextBlock;
        }
      }

      let headerKey1 = "content-type";
      let headerValue1 = "application/json";
      let headerKey2 = "Authorization";

      let headerBlock1 = this.workspace.newBlock("header_block");
      headerBlock1.initSvg();
      console.log("header_block:::::::", headerBlock1);

      let headerKeyBlock1 = this.workspace.newBlock("text");
      headerKeyBlock1.setFieldValue(headerKey1, "TEXT");
      headerKeyBlock1.initSvg();
      headerKeyBlock1.render();

      console.log("keyBlock:", headerKeyBlock1);

      let headerValueBlock1 = this.workspace.newBlock("text");
      headerValueBlock1.setFieldValue(headerValue1, "TEXT");
      headerValueBlock1.initSvg();
      headerValueBlock1.render();

      headerBlock1
        .getInput("HEADER_KEY")
        .connection.connect(headerKeyBlock1.outputConnection);

      headerBlock1
        .getInput("HEADER_VALUE")
        .connection.connect(headerValueBlock1.outputConnection);

      headerBlock1.render();

      headerInput.connection.connect(headerBlock1.previousConnection);

      let headerBlock2 = this.workspace.newBlock("header_block");
      headerBlock2.initSvg();
      console.log("header_block:::::::", headerBlock1);

      let headerKeyBlock2 = this.workspace.newBlock("text");
      headerKeyBlock2.setFieldValue(headerKey2, "TEXT");
      headerKeyBlock2.initSvg();
      headerKeyBlock2.render();

      headerBlock2
        .getInput("HEADER_KEY")
        .connection.connect(headerKeyBlock2.outputConnection);

      headerBlock2.render();

      headerInput.connection.connect(headerBlock2.previousConnection);

      // RENDERING URL BASED ON USER SELECTION
      const endPointInput = this.getInput("URL");
      if (
        endPointInput &&
        endPointInput.connection &&
        this._lastSelectedModelName !== null
      ) {
        // Dispose of all connected blocks to the Header input
        let connectedBlock = endPointInput.connection.targetBlock();
        while (connectedBlock) {
          const nextBlock = connectedBlock.getNextBlock();
          connectedBlock.dispose(true);
          connectedBlock = nextBlock;
        }
      }

      let apihost = "http://127.0.0.1:8085/";
      // let apihost = "https://backend1.redsling.com/";

      // let apihost = "https://staging-backend1.redsling.com/";

      let urlKey = `${apihost}customroutes/table_data/${selectedModelName}`;
      let urlKeyBlock = this.workspace.newBlock("text");
      urlKeyBlock.setFieldValue(urlKey, "TEXT");
      urlKeyBlock.initSvg();
      urlKeyBlock.render();

      endPointInput.connection.connect(urlKeyBlock.outputConnection);
    },
  };

  Blockly.Blocks["header_block"] = {
    init: function () {
      this.appendValueInput("HEADER_KEY")
        .setCheck("String")
        .appendField("Header Key:");
      this.appendValueInput("HEADER_VALUE")
        .setCheck("String")
        .appendField("Header Value:");
      this.setPreviousStatement(true, "header_block");
      this.setNextStatement(true, "header_block");
      this.setColour(210);
      this.setTooltip("Add a custom header to the API request.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["header_block_v2"] = {
    init: function () {
      this.appendValueInput("HEADER_KEY")
        .setCheck("String")
        .appendField("(v2) Header Key:");
      this.appendValueInput("HEADER_VALUE")
        .setCheck("String")
        .appendField("Header Value:");
      this.setPreviousStatement(true, "header_block");
      this.setNextStatement(true, "header_block");
      this.setColour(210);
      this.setTooltip("Add a custom header to the API request.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["basic_auth"] = {
    init: function () {
      this.appendDummyInput().appendField("Basic Auth with Username/Password");
      this.appendValueInput("USERNAME").appendField("Username");
      this.appendValueInput("PASSWORD").appendField("Password");
      this.appendValueInput("RES_VAR").appendField(
        "Store Result In A Variable :"
      );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(330);
      this.setTooltip("Basic Auth with Username/Password");
      this.setHelpUrl("");
    },
    // onchange: function () {
    //   // Whenever inputs change, update the decoded string
    //   var username = Blockly.JavaScript.valueToCode(
    //     this,
    //     "USERNAME",
    //     Blockly.JavaScript.ORDER_ATOMIC
    //   );
    //   var password = Blockly.JavaScript.valueToCode(
    //     this,
    //     "PASSWORD",
    //     Blockly.JavaScript.ORDER_ATOMIC
    //   );
    //   var resultVar = Blockly.JavaScript.valueToCode(
    //     this,
    //     "RES_VAR",
    //     Blockly.JavaScript.ORDER_ATOMIC
    //   );

    //   // Generate JavaScript code to update the decoded string
    //   var code =
    //     resultVar +
    //     " = 'Basic ' + btoa(" +
    //     username +
    //     " + ':' + " +
    //     password +
    //     ");\n";

    //   // Update the generated code in the block
    //   this.setPreviousStatement(true, null);
    //   this.setNextStatement(true, null);
    //   this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    //   this.setFieldValue(code, "CODE");
    // },
  };

  // Define btoa function if not available
  if (!window.btoa) {
    window.btoa = function (str) {
      return new Buffer(str).toString("base64");
    };
  }

  Blockly.Blocks["payload_block"] = {
    init: function () {
      this.appendValueInput("PAYLOAD_KEY")
        .setCheck("String")
        .appendField("Payload Key:");
      this.appendValueInput("PAYLOAD_VALUE")
        .setCheck("String")
        .appendField("Payload Value:");
      this.setPreviousStatement(true, "payload_block");
      this.setNextStatement(true, "payload_block");
      this.setColour(160);
      this.setTooltip("Add a key-value pair to the API request payload.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["payload_block_v2"] = {
    init: function () {
      this.appendValueInput("PAYLOAD_KEY")
        .setCheck("String")
        .appendField("(v2) Payload Key:");
      this.appendValueInput("PAYLOAD_VALUE")
        .setCheck("String")
        .appendField("Payload Value:");
      this.setPreviousStatement(true, "payload_block");
      this.setNextStatement(true, "payload_block");
      this.setColour(160);
      this.setTooltip("Add a key-value pair to the API request payload.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["response_status"] = {
    init: function () {
      this.appendValueInput("RESPONSE")
        .setCheck(null)
        .appendField("Check Response Status of");
      this.setOutput(true, "Boolean");
      this.setColour(230);
      this.setTooltip("Check the status of an API response");
      this.setHelpUrl("");
    },
  };

  // Blockly.Blocks["response_status_block"] = {
  //   init: function () {
  //     this.appendDummyInput()
  //       .appendField("Store Response Status in Variable:")
  //       .appendField(
  //         new Blockly.FieldVariable(
  //           "responseStatus", // Variable name
  //           "Variable" // Variable type
  //         ),
  //         "RESPONSE_STATUS_VARIABLE"
  //       );
  //     this.setPreviousStatement(true, null);
  //     this.setNextStatement(true, null);
  //     this.setColour(120);
  //     this.setTooltip("Store the response status in a variable.");
  //     this.setHelpUrl("");
  //   },
  // };

  Blockly.Blocks["nested_payload_block"] = {
    init: function () {
      this.appendValueInput("SOME_FIELD")
        .setCheck("String")
        .appendField("someField Key:");
      this.appendStatementInput("PAYLOAD_PAIRS")
        .setCheck("payload_block")
        .appendField("Add Key-Value Pairs:");
      this.setPreviousStatement(true, "nested_payload_block");
      this.setNextStatement(true, "nested_payload_block");
      this.setColour(160);
      this.setTooltip(
        "Add key-value pairs to the API request payload inside someField."
      );
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["handle_response"] = {
    init: function () {
      this.appendDummyInput().appendField("Handle API Response:");
      this.appendStatementInput("RESPONSE_LOGIC").setCheck(null);
      this.appendValueInput("RESPONSE_VARIABLE")
        .setCheck("String")
        .appendField("Store Response Data in Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(260); // Choose an appropriate color.
      this.setTooltip(
        "Handle the API response data and store it in a variable."
      );
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["get_response_variable"] = {
    init: function () {
      this.appendDummyInput().appendField("Get Response Data Variable:");
      this.appendValueInput("RESPONSE_VARIABLE")
        .setCheck("String")
        .appendField("Variable Name:");
      this.setOutput(true, null);
      this.setColour(150);
      this.setTooltip("Get the variable that stores the API response data.");
      this.setHelpUrl("");
    },
  };
  // Blockly.Blocks["make_api_call"] = {
  //   init: function () {
  //     this.appendDummyInput().appendField("Make API Call");
  //     this.appendDummyInput()
  //       .appendField("Method")
  //       .appendField(
  //         new Blockly.FieldDropdown([
  //           ["GET", "GET"],
  //           ["POST", "POST"],
  //           ["PUT", "PUT"],
  //           ["DELETE", "DELETE"],
  //           // Add more HTTP methods as needed
  //         ]),
  //         "METHOD"
  //       );
  //     this.appendDummyInput()
  //       .appendField("Authentication")
  //       .appendField(
  //         new Blockly.FieldDropdown([
  //           ["None", "none"],
  //           ["Basic Auth", "basic"],
  //           ["Bearer Token", "bearer"],
  //           // Add more authentication options as needed
  //         ]),
  //         "AUTHENTICATION"
  //       );
  //     this.appendValueInput("HEADERS")
  //       .setCheck("Object")
  //       .appendField("Headers");
  //     this.appendValueInput("PAYLOAD")
  //       .setCheck("Object")
  //       .appendField("Payload");
  //     this.appendStatementInput("RESPONSE_HANDLER")
  //       .setCheck(null)
  //       .appendField("Response Handling");
  //     this.setPreviousStatement(true, null);
  //     this.setNextStatement(true, null);
  //     this.setColour(230);
  //     this.setTooltip("Make an API call with the specified options");
  //     this.setHelpUrl("");
  //   },
  // };

  Blockly.Blocks["create_variable_from_local_storage"] = {
    init: function () {
      this.appendDummyInput().appendField("Store in Local Storage");
      this.appendValueInput("VARIABLE_NAME")
        .setCheck("String")
        .appendField("Variable Name:");
      this.appendValueInput("VALUE").appendField("Value:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Store a value in local storage");
    },
  };

  Blockly.Blocks["get_variable_from_local_storage"] = {
    init: function () {
      this.appendDummyInput().appendField("Get from Local Storage");
      this.appendValueInput("VARIABLE_NAME")
        .setCheck("String")
        .appendField("Variable Name:");
      this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("Retrieve a value from local storage");
    },
  };

  Blockly.Blocks["remove_variable_from_local_storage"] = {
    init: function () {
      this.appendDummyInput().appendField("Remove variable from Local Storage");

      this.appendValueInput("VARIABLE_NAME")
        .setCheck("String")
        .appendField("Variable Name:");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Remove a variable from Local Storage.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["create_session_variable"] = {
    init: function () {
      this.appendDummyInput().appendField("Store in Session Storage");
      this.appendValueInput("VARIABLE_NAME")
        .setCheck("String")
        .appendField("Variable Name:");
      this.appendValueInput("VALUE").appendField("Value:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Store a value in session storage");
    },
  };

  Blockly.Blocks["get_session_variable"] = {
    init: function () {
      this.appendDummyInput().appendField("Get from Session Storage");
      this.appendValueInput("VARIABLE_NAME")
        .setCheck("String")
        .appendField("Variable Name:");
      this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("Retrieve a value from session storage");
    },
  };

  Blockly.Blocks["remove_session_variable"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Remove variable from Session Storage"
      );

      this.appendValueInput("VARIABLE_NAME")
        .setCheck("String")
        .appendField("Variable Name:");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Remove a variable from Session Storage.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["parse_json"] = {
    init: function () {
      this.appendDummyInput().appendField("Parse JSON from String");
      this.appendValueInput("JSON_STRING")
        .setCheck("String")
        .appendField("JSON String:");
      this.appendValueInput("VARIABLE_NAME")
        .setCheck(null)
        .appendField("Store Result in Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Parse JSON from a string and store it in a variable");
    },
  };

  Blockly.Blocks["stringify_json"] = {
    init: function () {
      this.appendDummyInput().appendField("Stringify JSON Object");
      this.appendValueInput("JSON_OBJECT")
        .setCheck(null)
        .appendField("JSON Object:");
      this.appendValueInput("VARIABLE_NAME")
        .setCheck("String")
        .appendField("Store Result in Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip(
        "Convert a JSON object into a string and store it in a variable"
      );
    },
  };

  Blockly.Blocks["iterate_array"] = {
    init: function () {
      this.appendValueInput("ARRAY")
        .setCheck("Array")
        .appendField("Iterate Over Array:");
      this.appendValueInput("VARIABLE_NAME")
        .setCheck("String")
        .appendField("Variable Name:");
      this.appendStatementInput("ITERATION_LOGIC")
        .setCheck(null)
        .appendField("For Each Item:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Iterate over an array of items.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["get_property"] = {
    init: function () {
      this.appendValueInput("ITEM")
        .setCheck(null)
        .appendField("Get Property of");
      this.appendValueInput("PROPERTY")
        .setCheck("String")
        .appendField("Property Name:");
      this.setOutput(true, null);
      this.setColour(330);
      this.setTooltip("Get a property value from an object.");
      this.setHelpUrl("");
    },
  };

  // CUSTOM COMPONENTS:::::::::::::::::::
  // Blockly.Blocks["email_input"] = {
  //   init: function () {
  //     this.appendDummyInput().appendField("Email Input");
  //     this.setOutput(true, "String"); // Set the block to output a string value
  //     this.setColour(230);
  //     this.setTooltip("Get the value of the Email Input");
  //     this.setHelpUrl("");
  //   },
  // };

  Blockly.Blocks["custom_logic_block"] = {
    init: function () {
      this.appendDummyInput().appendField("Custom Logic Block");

      this.appendStatementInput("INNER_LOGIC")
        .setCheck(null)
        .appendField("Add Logic:");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Add custom logic blocks.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["customize_component"] = {
    init: function () {
      this.appendValueInput("COMPONENT_TYPE")
        .setCheck("String")
        .appendField("Customize Component Type:");
      this.appendStatementInput("CSS_PROPERTIES").setCheck("CSS_Property");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Customize a component type with CSS properties.");
    },
  };

  Blockly.Blocks["css_property"] = {
    init: function () {
      this.appendValueInput("PROPERTY_NAME")
        .setCheck("String")
        .appendField("Property Name:");
      this.appendValueInput("PROPERTY_VALUE")
        .setCheck("String")
        .appendField("Value:");
      this.setPreviousStatement(true, "CSS_Property");
      this.setNextStatement(true, "CSS_Property");
      this.setColour(180);
      this.setTooltip("Specify a CSS property and its value.");
    },
  };

  Blockly.Blocks["js_block"] = {
    init: function () {
      this.appendStatementInput("EVENTS")
        .setCheck("Event")
        .appendField("Add Events:");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Add JavaScript logic with events.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["event_onClick"] = {
    init: function () {
      this.appendDummyInput().appendField("onClick");
      this.setPreviousStatement(true, "Event");
      this.setNextStatement(true, "Event");
      this.setColour(230);
      this.appendStatementInput("EVENT_LOGIC")
        .setCheck(null)
        .appendField("Event Logic:");
      this.setTooltip("Add onClick event and specify logic.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["event_onChange"] = {
    init: function () {
      this.appendDummyInput().appendField("onChange");
      this.setPreviousStatement(true, "Event");
      this.setNextStatement(true, "Event");
      this.setColour(230);
      this.appendStatementInput("EVENT_LOGIC")
        .setCheck(null)
        .appendField("Event Logic:");
      this.setTooltip("Add onChange event.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["set_timeout"] = {
    init: function () {
      this.appendValueInput("TIME")
        .setCheck("Number")
        .appendField("Set Timeout (ms):");
      this.appendStatementInput("HANDLER")
        .setCheck(null)
        .appendField("Execute:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip(
        "Set a timeout and specify the code to execute after the specified time."
      );
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["set_interval"] = {
    init: function () {
      this.appendValueInput("TIME")
        .setCheck("Number")
        .appendField("Set Interval (ms):");
      this.appendStatementInput("HANDLER")
        .setCheck(null)
        .appendField("Execute:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip(
        "Set an interval and specify the code to repeatedly execute at the specified interval."
      );
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["set_interval_new"] = {
    init: function () {
      this.appendValueInput("TIME")
        .setCheck("Number")
        .appendField("(New) Set Interval (ms) :");
      this.appendValueInput("RESULT_VAR")
        .setCheck("Variable")
        .appendField("Store Current Date In Variable:");
      this.appendStatementInput("HANDLER")
        .setCheck(null)
        .appendField("Execute:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(5);
      this.setTooltip(
        "Set an interval and specify the code to repeatedly execute at the specified interval."
      );
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["clear_interval"] = {
    init: function () {
      this.appendDummyInput().appendField("Clear Interval");
      this.appendDummyInput()
        .appendField("Function name:")
        .appendField(
          new Blockly.FieldTextInput("functionName"),
          "FUNCTION_NAME"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(5);
      this.setTooltip("Clear the specified interval.");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["concate_string"] = {
    init: function () {
      this.appendDummyInput().appendField("Concate Strings to Variable");
      this.appendValueInput("ITEM").appendField("First String");
      this.appendValueInput("PROPERTY").appendField("Add Variable");
      this.setOutput(true, null);
      this.setColour(330);
      this.setTooltip("Concate String To Variable");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["create_object"] = {
    init: function () {
      this.appendDummyInput().appendField("Create Object with Key-Value Pairs");

      this.appendDummyInput()
        .appendField("Number of Key-Value Pairs:")
        .appendField(
          new Blockly.FieldDropdown(
            [
              ["1", "1"],
              ["2", "2"],
              ["3", "3"],
              ["4", "4"],
              ["5", "5"],
              ["6", "6"],
              ["7", "7"],
              ["8", "8"],
              ["9", "9"],
              ["10", "10"],
              ["11", "11"],
              ["12", "12"],
              ["13", "13"],
              ["14", "14"],
              ["15", "15"],
            ],
            function (value) {
              // Update the block when dropdown changes
              this.getSourceBlock().updateShape_(value);
            }
          ),
          "PAIRS"
        );

      this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("Create an object with key-value pairs");
      this.setHelpUrl("");

      // Initialize with 1 pair
      this.updateShape_("1");
    },

    // Function to update the shape (add or remove fields)
    updateShape_: function (numberOfPairs) {
      this.inputList.slice(2).forEach((input) => {
        this.removeInput(input.name);
      });

      for (let i = 1; i <= numberOfPairs; i++) {
        this.appendValueInput(`KEY${i}`)
          .setCheck(null)
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(`Key ${i}`);

        this.appendValueInput(`VALUE${i}`)
          .setCheck(null)
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(`Value ${i}`);
      }
    },
  };

  Blockly.Blocks["create_object_v_2"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Create Object with Key-Value Pairs V2"
      );

      this.appendDummyInput()
        .appendField("Number of Key-Value Pairs:")
        .appendField(
          new Blockly.FieldDropdown(
            [
              ["1", "1"],
              ["2", "2"],
              ["3", "3"],
              ["4", "4"],
              ["5", "5"],
              ["6", "6"],
              ["7", "7"],
              ["8", "8"],
              ["9", "9"],
              ["10", "10"],
              ["11", "11"],
              ["12", "12"],
              ["13", "13"],
              ["14", "14"],
              ["15", "15"],
              ["16", "16"],
              ["17", "17"],
              ["18", "18"],
              ["19", "19"],
              ["20", "20"],
              ["21", "21"],
              ["22", "22"],
              ["23", "23"],
              ["24", "24"],
              ["25", "25"],
              ["26", "26"],
              ["27", "27"],
              ["28", "28"],
              ["29", "29"],
              ["30", "30"],
            ],
            function (value) {
              // Update the block when dropdown changes
              this.getSourceBlock().updateShape_(value);
            }
          ),
          "PAIRS"
        );

      this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("Create an object with key-value pairs");
      this.setHelpUrl("");

      // Initialize with 1 pair
      this.updateShape_("1");
    },

    // Function to update the shape (add or remove fields)
    updateShape_: function (numberOfPairs) {
      this.inputList.slice(2).forEach((input) => {
        this.removeInput(input.name);
      });

      for (let i = 1; i <= numberOfPairs; i++) {
        this.appendValueInput(`KEY${i}`)
          .setCheck(null)
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(`Key ${i}`);

        this.appendValueInput(`VALUE${i}`)
          .setCheck(null)
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(`Value ${i}`);
      }
    },
  };

  Blockly.Blocks["create_object_v_3"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Create Object with Key-Value Pairs V3"
      );

      this.appendValueInput("NUM_PAIRS")
        .setCheck("Number")
        .appendField("Number of Key-Value Pairs:");

      this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("Create an object with key-value pairs");
      this.setHelpUrl("");

      // Initialize with 1 pair
      this.updateShape_(2);
    },

    // Function to update the shape (add or remove fields)
    updateShape_: function (numberOfPairs) {
      this.inputList.slice(2).forEach((input) => {
        this.removeInput(input.name);
      });

      for (let i = 1; i <= numberOfPairs; i++) {
        this.appendValueInput(`KEY${i}`)
          .setCheck(null)
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(`Key ${i}`);

        this.appendValueInput(`VALUE${i}`)
          .setCheck(null)
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(`Value ${i}`);
      }

      console.log("this:::::::::::v3", this);
      console.log("this input:::::::::::v3", this.inputList);
    },

    // Update the block when the number of pairs changes
    onchange: function () {
      const numPairs = Blockly.JavaScript.valueToCode(
        this,
        "NUM_PAIRS",
        Blockly.JavaScript.ORDER_ATOMIC
      );

      let newNUm = parseInt(numPairs, 10) || 2;
      this.updateShape_(newNUm);
    },
  };

  Blockly.Blocks["merge_objects"] = {
    init: function () {
      this.appendDummyInput().appendField("Merge Objects Into Single Object");
      this.appendValueInput("OBJ1").setCheck(null).appendField("Object 1:");

      this.appendValueInput("OBJ2").setCheck(null).appendField("Object 2:");
      this.appendValueInput("Merged_Var").appendField(
        "Stored Merged Result In Variable:"
      );

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Merge two objects into a single object");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["timestamp"] = {
    init: function () {
      this.appendDummyInput().appendField("generate time stamp for a date ");
      this.appendDummyInput()
        .appendField("Format:")
        .appendField(
          new Blockly.FieldDropdown([
            ["IST", "ist"],
            ["Universal", "utf"],
            ["Timestamp", "timestamp"],
            ["Regular", "regular"],
            ["Australian Eastern Standard Time (AEST) ", "aest"],
            ["Australian Central Standard Time (ACST)", "acst"],
            ["Australian Western Standard Time (AWST) ", "awst"],
            ["Thailand", "thailand"],
          ]),
          "FORMAT"
        );
      this.appendValueInput("VALUE").setCheck(null).appendField("Value:");
      this.appendValueInput("RESULT_VAR")
        .setCheck("Variable")
        .appendField("Store Current Date In Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Set Property of the Component");
    },
  };

  Blockly.Blocks["current date"] = {
    init: function () {
      this.appendDummyInput().appendField("get current date");
      this.setOutput(true, null);
      this.setColour(160);
      this.setTooltip("get current date");
    },
  };

  Blockly.Blocks["convert_to_date_object"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Convert String Date To Date Object:"
      );
      this.appendValueInput("VALUE")
        .setCheck(null)
        .appendField("Insert String Date:");
      this.setOutput(true, null);
      this.setColour(160);
      this.setTooltip("get current date");
    },
  };

  Blockly.Blocks["local date"] = {
    init: function () {
      this.appendDummyInput().appendField("get local date time");
      this.setOutput(true, null);
      this.setColour(160);
      this.setTooltip("get local date time");
    },
  };

  Blockly.Blocks["utc date"] = {
    init: function () {
      this.appendDummyInput().appendField("get UTC date time ");
      this.appendValueInput("VALUE").setCheck(null).appendField("Value:");
      this.setOutput(true, null);
      this.setColour(160);
      this.setTooltip("get UTC date time");
    },
  };

  Blockly.Blocks["local dateV2"] = {
    init: function () {
      this.appendDummyInput().appendField("get local date timeV2");
      this.appendValueInput("RESULT_VAR")
        .setCheck("Variable")
        .appendField("Store Current Date In Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("get local date time");
    },
  };

  Blockly.Blocks["current dateV2"] = {
    init: function () {
      this.appendDummyInput().appendField("get current dateV2");
      this.appendValueInput("RESULT_VAR")
        .setCheck("Variable")
        .appendField("Store Current Date In Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("get current date");
    },
  };

  Blockly.Blocks["utc dateV2"] = {
    init: function () {
      this.appendDummyInput().appendField("get UTC date timeV2 ");
      this.appendValueInput("VALUE").setCheck(null).appendField("Value:");
      this.appendValueInput("RESULT_VAR")
        .setCheck("Variable")
        .appendField("Store Current Date In Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("get UTC date time");
    },
  };

  Blockly.Blocks["dom_content_loaded_with_code"] = {
    init: function () {
      this.appendDummyInput().appendField("get current date");
      this.setOutput(true, null);
      this.setColour(160);
      this.setTooltip("get current date");
    },
  };

  Blockly.Blocks["try_catch_block"] = {
    init: function () {
      this.appendStatementInput("TRY").setCheck(null).appendField("try");
      this.appendDummyInput().appendField("catch (error)");
      this.appendStatementInput("CATCH").setCheck(null);
      this.setColour(260);
      this.setTooltip("This block represents a try-catch block.");
      this.setHelpUrl("https://example.com");
    },
  };

  Blockly.Blocks["formatnumber"] = {
    init: function () {
      this.appendDummyInput().appendField("Format a number value ");
      this.appendDummyInput()
        .appendField("Format:")
        .appendField(
          new Blockly.FieldDropdown([
            ["IST", "ist"],
            ["INTERNATIONAL", "international"],
          ]),
          "FORMAT"
        );
      this.appendValueInput("VALUE").setCheck(null).appendField("Value:");
      this.appendValueInput("RESPONSE_VAR")
        .setCheck(null)
        .appendField("Store Response In A Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Set Property of the Component");
    },
  };

  Blockly.Blocks["convert_to_number"] = {
    init: function () {
      this.appendValueInput("VALUE")
        .setCheck("String")
        .appendField("Convert formatted number string to number");
      this.setOutput(true, "Number");
      this.setColour(230);
      this.setTooltip("Converts a formatted number string to a number");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["convert_datetime"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Grab Date/Time Part From Date String")
        .appendField(
          new Blockly.FieldDropdown([
            ["date", "DATE"],
            ["time", "TIME"],
          ]),
          "TYPE"
        )
        .appendField("Insert Date-Time:");
      this.appendValueInput("TIMESTAMP").setCheck("String");
      this.setOutput(true, "String");
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["date_formats_to_ui"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Show Date As Formatted String In Ui"
      );
      this.appendValueInput("DATE_INPUT")
        .setCheck(null)
        .appendField("Attach date Input Here");
      this.appendDummyInput()
        .appendField("Choose Date Format :")
        .appendField(
          new Blockly.FieldDropdown([
            ["DD MMM YYYY", "DD MMM YYYY"],
            ["DD MMMM YYYY", "DD MMMM YYYY"],
            ["DD/MM/YYYY", "DD/MM/YYYY"],
            ["MM/DD/YYYY", "MM/DD/YYYY"],
            ["DD-MM-YYYY", "DD-MM-YYYY"],
            ["YYYY-MM-DD", "YYYY-MM-DD"],
            ["DD MMM YYYY HH:mm am/pm", "DD MMM YYYY HH:mm am/pm"],
            ["DD MMMM YYYY HH:mm am/pm", "DD MMMM YYYY HH:mm am/pm"],
            ["DD/MM/YYYY HH:mm am/pm", "DD/MM/YYYY HH:mm am/pm"],
            ["MM/DD/YYYY HH:mm am/pm", "MM/DD/YYYY HH:mm am/pm"],
            ["DD-MM-YYYY HH:mm am/pm", "DD-MM-YYYY HH:mm am/pm"],
            ["DD MMM YYYY 24:00", "DD MMM YYYY 24:00"],
            ["DD MMMM YYYY 24:00", "DD MMMM YYYY 24:00"],
            ["DD/MM/YYYY 24:00", "DD/MM/YYYY 24:00"],
            ["MM/DD/YYYY 24:00", "MM/DD/YYYY 24:00"],
            ["DD-MM-YYYY 24:00", "DD-MM-YYYY 24:00"],
            ["YYYY-MM-DD 24:00", "YYYY-MM-DD 24:00"]
          ]),
          "DATE_FORMAT"
        );
      this.appendValueInput("RESULT_VAR")
        .setCheck("Variable")
        .appendField("Store New Date In Variable:");
      this.setInputsInline(false);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  // Blockly.Blocks["increment_decrement_date"] = {
  //   init: function () {
  //     this.appendDummyInput()
  //       .appendField("Increment/Decrement Date by No. of Days")
  //       .appendField(
  //         new Blockly.FieldDropdown([
  //           ["increment", "INCREMENT"],
  //           ["decrement", "DECREMENT"],
  //         ]),
  //         "OPERATION"
  //       );
  //     this.appendValueInput("DAYS").appendField("Days :").setCheck("Number");
  //     this.appendValueInput("DATE")
  //       .setCheck("String")
  //       .appendField("Provided Date :");
  //     this.appendValueInput("RESULT_VAR")
  //       .setCheck("Variable")
  //       .appendField("Store New Date In Variable:");
  //     this.setInputsInline(true);
  //     this.setPreviousStatement(true, null);
  //     this.setNextStatement(true, null);
  //     this.setColour(230);
  //     this.setTooltip("");
  //     this.setHelpUrl("");
  //   },
  // };

  Blockly.Blocks["increment_decrement_date"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Increment/Decrement Date :")
        .appendField(
          new Blockly.FieldDropdown([
            ["increment", "INCREMENT"],
            ["decrement", "DECREMENT"],
          ]),
          "OPERATION"
        );
      this.appendDummyInput()
        .appendField("Choose The Unit :")
        .appendField(
          new Blockly.FieldDropdown([
            ["days", "days"],
            ["weeks", "weeks"],
            ["months", "months"],
            ["year", "year"],
          ]),
          "PART"
        );
      this.appendValueInput("DAYS_HOURS")
        .appendField("Insert Increment/Decrement Value :")
        .setCheck("Number");
      this.appendValueInput("DATE")
        .setCheck(null)
        .appendField("Provided Date :");
      this.appendValueInput("RESULT_VAR")
        .setCheck("Variable")
        .appendField("Store New Date In Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["increment_decrement_time"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Increment/Decrement Time :")
        .appendField(
          new Blockly.FieldDropdown([
            ["increment", "INCREMENT"],
            ["decrement", "DECREMENT"],
          ]),
          "OPERATION"
        );
      this.appendDummyInput()
        .appendField("Choose The Unit :")
        .appendField(
          new Blockly.FieldDropdown([
            ["hours", "hours"],
            ["minutes", "minutes"],
            ["seconds", "seconds"],
          ]),
          "PART"
        );
      this.appendValueInput("DAYS_HOURS")
        .appendField("Insert Increment/Decrement Value :")
        .setCheck("Number");
      this.appendValueInput("DATE")
        .setCheck(null)
        .appendField("Provided Date :");
      this.appendValueInput("RESULT_VAR")
        .setCheck("Variable")
        .appendField("Store New Time In Variable:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["multilineText"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("multiline text input:")
        .appendField(
          new Blockly.FieldMultilineInput(
            "default text\n with newline character"
          ),
          "FIELDNAME"
        );
      this.appendValueInput("RESULT_VAR")
        .setCheck("Variable")
        .appendField("Store New Date In Variable:");
      this.setColour("#c2410c");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
    },
  };

  Blockly.Blocks["add_line_break"] = {
    init: function () {
      this.appendDummyInput().appendField("Add Line Break");
      this.setOutput(true, null);
      this.setColour(230);
      this.setTooltip("Add a line break or new line");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["store_session_time"] = {
    init: function () {
      this.appendDummyInput().appendField("Store Session Start Time");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["is_session_expired"] = {
    init: function () {
      this.appendDummyInput().appendField("Create Logic When Session Expires");
      this.appendDummyInput()
        .appendField("Choose The Unit :")
        .appendField(
          new Blockly.FieldDropdown([
            ["hours", "hours"],
            ["minutes", "minutes"],
          ]),
          "PART"
        );
      this.appendValueInput("DAYS_HOURS")
        .appendField("Add Duration for Token Expiry:")
        .setCheck("Number");

      this.appendStatementInput("SUCCESS")
        .setCheck(null)
        .appendField("Logic For Session Expiry:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["remaining_session_time"] = {
    init: function () {
      this.appendDummyInput().appendField("Remaining Session Time");
      this.appendValueInput("Res_Day")
        .setCheck("Variable")
        .appendField("Store Remaining Days in a Variable:");
      this.appendValueInput("Res_Hours")
        .setCheck("Variable")
        .appendField("Store Remaining Hours in a Variable:");
      this.appendValueInput("Res_Minutes")
        .setCheck("Variable")
        .appendField("Store Remaining Minutes in a Variable:");
      this.appendValueInput("Res_Seconds")
        .setCheck("Variable")
        .appendField("Store Remaining Seconds in a Variable:");
      this.appendStatementInput("SUCCESS")
        .setCheck(null)
        .appendField("Logic For Session Displaying Remaining Time:");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  // ARRAY METHODS
  Blockly.Blocks["array_manipulation_method"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Use Find Method To Grab Matching Key From Array"
      );
      this.appendValueInput("ARRAY_VAR")
        .appendField("Insert Array Data:")
        .setCheck(null);
      this.appendDummyInput()
        .appendField("Select Method :")
        .appendField(
          new Blockly.FieldDropdown([
            ["find", "find"],
            ["filter", "filter"],
          ]),
          "METHOD"
        );
      this.appendValueInput("searchKey")
        .appendField("Insert Key To Search:")
        .setCheck(null);
      this.appendValueInput("searchValue")
        .appendField("Insert Matching Value To Key:")
        .setCheck(null);
      this.appendValueInput("RESULT_VAR")
        .appendField("Store Result In Variable:")
        .setCheck(null);
      this.setInputsInline(false);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["async_function_invoked"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Invoked Function to Handle Sync Flow"
      );
      this.appendValueInput("RES_VAR")
        .appendField("Add Variable Containing Data : ")
        .setCheck(null);
      this.appendValueInput("ITEM_REF")
        .appendField("Add Variable To Store Value At Iterating Index")
        .setCheck(null);
      this.appendStatementInput("SUCCESS")
        .setCheck(null)
        .appendField("Add Function Logic :");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["async_function_mesh"] = {
    init: function () {
      this.appendDummyInput().appendField("Sync Nested Api Calls");
      this.appendValueInput("RES_VAR")
        .appendField("Add Variable to store all API Calls : ")
        .setCheck(null);
      this.appendStatementInput("SUCCESS")
        .setCheck(null)
        .appendField("Add Function Logic :");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["async_function_promise"] = {
    init: function () {
      this.appendDummyInput().appendField("Promise Block to Sync Api response");
      this.appendValueInput("RES_VAR")
        .appendField("Provide Variable Containing API Calls : ")
        .setCheck(null);
      this.appendStatementInput("SUCCESS")
        .setCheck(null)
        .appendField("Add Logic To Be Executed After Api calls :");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["array_push_method"] = {
    init: function () {
      this.appendDummyInput().appendField("Array Push Method");
      this.appendValueInput("ARR_VAR")
        .appendField("Provide array variable : ")
        .setCheck(null);
      this.appendValueInput("RES_VAR")
        .appendField("Provide Data-set to be pushed : ")
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["navigate_back_shorthand"] = {
    init: function () {
      this.appendDummyInput().appendField("Navigate Back To Last PageV2  :");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grab_query_from_url"] = {
    init: function () {
      this.appendDummyInput().appendField("Grab Token From Url:");
      this.appendValueInput("RES_VAR")
        .appendField("Store Value In Variable : ")
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grab_query_parameter_from_url"] = {
    init: function () {
      this.appendDummyInput().appendField("Grab Query Parameter From Url:");
      this.appendValueInput("PARAM")
        .setCheck(null)
        .appendField(`URL Parameter Name:`);
      this.appendValueInput("RES_VAR")
        .appendField("Store Value In Variable : ")
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["set_last_page"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Set Page Checkout From Back Functionality  :"
      );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["manipulate_window_location"] = {
    init: function () {
      this.appendDummyInput().appendField("Go To The Last Page (Back)  :");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["session_client_track"] = {
    init: function () {
      this.appendDummyInput().appendField("Check for session InActivity :");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour("#1976D2");
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["register_push_notification"] = {
    init: function () {
      this.appendDummyInput().appendField("Register for Push Notifications");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["send_push_notification"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Send Push Notification with message")
        .appendField(new Blockly.FieldTextInput("Hello!"), "message");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["grab_geolocation"] = {
    init: function () {
      this.appendDummyInput().appendField("Grab Geo-Location :");
      this.appendValueInput("STATUS")
        .appendField("Store Status in variable : ")
        .setCheck(null);
      this.appendValueInput("LAT_VAR")
        .appendField("Store Latitude in variable : ")
        .setCheck(null);
      this.appendValueInput("LONG_VAR")
        .appendField("Store Longitude in variable : ")
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["calc_distance_of_geo"] = {
    init: function () {
      this.appendDummyInput().appendField(
        "Calculate Distance between Two Locations :"
      );
      this.appendValueInput("LAT_1")
        .appendField("Provide Latitude of location A : ")
        .setCheck(null);
      this.appendValueInput("LONG_1")
        .appendField("Provide Longitude of location A : ")
        .setCheck(null);
      this.appendValueInput("LAT_2")
        .appendField("Provide Latitude of location B : ")
        .setCheck(null);
      this.appendValueInput("LONG_2")
        .appendField("Provide Longitude of location B : ")
        .setCheck(null);
      this.appendValueInput("Res_var")
        .appendField("Store Distance in a variable : ")
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["random_variable_generator"] = {
    init: function () {
      this.appendDummyInput().appendField("Generate random variable");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Generates a random variable with a random value");
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["nearest_matching_day"] = {
    init: function () {
      this.appendValueInput("DATE").setCheck("Date").appendField("Given Date");
      this.appendValueInput("DAY")
        .setCheck("String")
        .appendField("Find nearest");
      this.setOutput(true, "Date");
      this.setColour(230);
      this.setTooltip("Find the nearest date for the specified day");
      this.setHelpUrl("");
    },
  };

  // practice block
  // Blockly.Blocks["practice_block_a"] = {
  //   init: function () {
  //     this.appendDummyInput().appendField("Add 2 numbers:");
  //     this.appendValueInput("VAL1").setCheck("Number").appendField("Num A");
  //     this.appendValueInput("VAL2").setCheck("Number").appendField("Num B");
  //     this.setOutput(true, "Number");
  //     this.setColour("#ebed74");
  //   },
  // };

  // block to return the substring at the index mentioned in the splitted array of strings using split('')
  Blockly.Blocks['text_split_substring'] = {
    init: function() {
      this.appendValueInput("INPUT_STRING")
          .setCheck("String")
          .appendField("Split text");
      this.appendDummyInput()
          .appendField("by substring")
          .appendField(new Blockly.FieldTextInput("_"), "SUBSTRING");
      this.appendDummyInput()
          .appendField("and return index")
          .appendField(new Blockly.FieldNumber(0, 0), "INDEX");
      this.setOutput(true, "String");
      this.setColour(160);
      this.setTooltip("Splits the input string by the specified substring and returns the value at the specified index.");
      this.setHelpUrl("");
    }
  };

  // // block to convert a number into different formats
  // Blockly.Blocks["number_formats"] = {
  //   init: function () {
  //       // Define conversion format options
  //       var options = [
  //           ["Default format", "default"],
  //           ["No Separator", "no_sep"],
  //           ["Apostrophe Separator", "aps_sep"],
  //           ["Space Separator", "spc_sep"],
  //           ["Underscore Separator", "spc_sep_underscore"],
  //           ["Western (International) Format", "wes_int_frt"],
  //           ["European Format", "eu_frt"],
  //           ["Indian Numbering System", "ind_num_sys"],
  //           ["Chinese/Japanese/Korean (East Asian) Format", "est_asn_frt"],

  //           // Additional options for decimal places
  //           ["Two Decimal Places", "two_dec"],
  //           ["Four Decimal Places", "four_dec"],
  //           ["Six Decimal Places", "six_dec"],
  //           ["Eight Decimal Places", "eight_dec"],

  //           // Combined options for separators and decimal places
  //           ["Apostrophe Separator + Two Decimal Places", "aps_sep_two_dec"],
  //           ["Space Separator + Two Decimal Places", "spc_sep_two_dec"],
  //           ["Underscore Separator + Two Decimal Places", "spc_sep_underscore_two_dec"],
  //           ["Western (International) Format + Two Decimal Places", "wes_int_frt_two_dec"],
  //           ["European Format + Two Decimal Places", "eu_frt_two_dec"],
  //           ["Indian Numbering System + Two Decimal Places", "ind_num_sys_two_dec"],
  //           ["Chinese/Japanese/Korean (East Asian) Format + Two Decimal Places", "est_asn_frt_two_dec"],

  //           ["Apostrophe Separator + Four Decimal Places", "aps_sep_four_dec"],
  //           ["Space Separator + Four Decimal Places", "spc_sep_four_dec"],
  //           ["Underscore Separator + Four Decimal Places", "spc_sep_underscore_four_dec"],
  //           ["Western (International) Format + Four Decimal Places", "wes_int_frt_four_dec"],
  //           ["European Format + Four Decimal Places", "eu_frt_four_dec"],
  //           ["Indian Numbering System + Four Decimal Places", "ind_num_sys_four_dec"],
  //           ["Chinese/Japanese/Korean (East Asian) Format + Four Decimal Places", "est_asn_frt_four_dec"],

  //           ["Apostrophe Separator + Six Decimal Places", "aps_sep_six_dec"],
  //           ["Space Separator + Six Decimal Places", "spc_sep_six_dec"],
  //           ["Underscore Separator + Six Decimal Places", "spc_sep_underscore_six_dec"],
  //           ["Western (International) Format + Six Decimal Places", "wes_int_frt_six_dec"],
  //           ["European Format + Six Decimal Places", "eu_frt_six_dec"],
  //           ["Indian Numbering System + Six Decimal Places", "ind_num_sys_six_dec"],
  //           ["Chinese/Japanese/Korean (East Asian) Format + Six Decimal Places", "est_asn_frt_six_dec"],

  //           ["Apostrophe Separator + Eight Decimal Places", "aps_sep_eight_dec"],
  //           ["Space Separator + Eight Decimal Places", "spc_sep_eight_dec"],
  //           ["Underscore Separator + Eight Decimal Places", "spc_sep_underscore_eight_dec"],
  //           ["Western (International) Format + Eight Decimal Places", "wes_int_frt_eight_dec"],
  //           ["European Format + Eight Decimal Places", "eu_frt_eight_dec"],
  //           ["Indian Numbering System + Eight Decimal Places", "ind_num_sys_eight_dec"],
  //           ["Chinese/Japanese/Korean (East Asian) Format + Eight Decimal Places", "est_asn_frt_eight_dec"]
  //       ];

  //       // Add "Conversion Format:" label and dropdown
  //       this.appendDummyInput()
  //           .appendField("Number Conversion Format:")
  //           .appendField(new Blockly.FieldDropdown(options), "CONVFORMAT");

  //       // Add the input for the number
  //       this.appendValueInput("NUM")
  //           .setCheck('Number')
  //           .appendField("Insert A Number:");

  //       // Set output and block color
  //       this.setOutput(true);
  //       this.setColour("#5ba58c");
  //   },
  // };

  Blockly.Blocks['format_number'] = {
    init: function() {
        this.appendValueInput('NUMBER')
            .setCheck(['Number', 'String'])
            .appendField('Format number or string:');
        
        this.appendDummyInput()
            .appendField('Using format:')
            .appendField(new Blockly.FieldDropdown([
                ["No Separator", "no_sep"],
                ["Apostrophe Separator", "aps_sep"],
                ["Space Separator", "spc_sep"],
                ["Underscore Separator", "spc_sep_underscore"],
                ["Western (International) Format", "wes_int_frt"],
                ["European Format", "eu_frt"],
                ["Indian Numbering System", "ind_num_sys"],
                ["East Asian Format", "est_asn_frt"]
            ]), "FORMAT");
        
        this.setOutput(true, 'String');  // Output type is String
        this.setColour(160);
        this.setTooltip('Formats a number or string into specified formats.');
        this.setHelpUrl('');
    }
  };

  Blockly.Blocks['format_number_decimal'] = {
    init: function() {
      this.appendValueInput('NUMBER')
        .setCheck(['Number', 'String'])  // Input can be a number or string
        .appendField('Format number or string:');

      this.appendDummyInput()
        .appendField('Decimal Places:')
        .appendField(new Blockly.FieldDropdown([
          ['Two Decimal Places', 'two_dec'],
          ['Four Decimal Places', 'four_dec'],
          ['Six Decimal Places', 'six_dec'],
          ['Eight Decimal Places', 'eight_dec']
        ]), 'DECIMAL_PLACES');

      this.setOutput(true, 'String');  // Output is a string
      this.setColour(160);  // Color of the block
      this.setTooltip('Formats a number or string with specific decimal places.');
      this.setHelpUrl('');
    }
  };

  // to slice a list/array
  Blockly.Blocks['slice_list'] = {
    init: function() {
      this.appendValueInput('LIST')
          .setCheck('Array')
          .appendField('From list:');
      
      this.appendValueInput('START')
          .setCheck('Number')
          .appendField('Start at index:');
      
      this.appendValueInput('END')
          .setCheck('Number')
          .appendField('End at index (optional):');
      
      this.appendValueInput('INDEX')
          .setCheck('Number')
          .appendField('Get element at index (optional):');
      
      this.setOutput(true);
      this.setColour(160);
      this.setTooltip('Slices a list/array or gets an element at a specific index or between a specific range.');
      this.setHelpUrl('');
    }
  };

  Blockly.Blocks['array_push'] = {
    init: function() {
      this.appendValueInput("ARRAY")
          .setCheck("Array")
          .appendField("Add to array");
      this.appendValueInput("VALUE")
          .setCheck(null)
          .appendField("value");
      this.setInputsInline(true);
      this.setOutput(true, "Number"); // Set output to return a number (new length of the array)
      this.setColour(230);
      this.setTooltip("Adds a value to the end of an array and returns the new length.");
      this.setHelpUrl("https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/push");
    }
  };

  Blockly.Blocks['array_join'] = {
    init: function() {
      this.appendValueInput("ARRAY")
          .setCheck("Array")
          .appendField("Join array");
      this.appendValueInput("SEPARATOR")
          .setCheck("String")
          .appendField("with separator");
      this.setInputsInline(true);
      this.setOutput(true, "String"); // The output type is a string
      this.setColour(230);
      this.setTooltip("Joins all elements of an array into a string with the specified separator.");
      this.setHelpUrl("https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/join");
    }
  };

  // mutation observer block to monitor changes in element tags
  Blockly.Blocks['generalized_mutation_observer'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Observe element with ID")
          .appendField(new Blockly.FieldTextInput("element_id"), "ELEMENT_ID");
      this.appendDummyInput()
          .appendField("Attributes to monitor (comma-separated)")
          .appendField(new Blockly.FieldTextInput("attribute1,attribute2"), "ATTRIBUTES");
      this.appendStatementInput("DO")
          .setCheck(null)
          .appendField("Do");
      this.appendDummyInput()
          .appendField("Stop observer")
          .appendField(new Blockly.FieldCheckbox("FALSE"), "STOP_OBSERVER");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip('Observes changes to specified attributes of a specified element and executes the contained statements. Optionally, can stop the observer.');
      this.setHelpUrl('');
    }
  };

  // block to get the attributes of a target DOM element
  Blockly.Blocks['get_attributes'] = {
    init: function() {
      this.appendValueInput("ELEMENT_ID")
          .setCheck("String")
          .appendField("get attribute from element with ID");
      this.appendValueInput("ATTRIBUTE_NAME")
          .setCheck("String")
          .appendField("attribute name");
      this.setOutput(true, "String");
      this.setColour(230);
      this.setTooltip('Gets the value of a specified attribute from a specified element.');
      this.setHelpUrl('');
    }
  };

  // block to set the attributes of a target DOM element
  Blockly.Blocks['set_attributes'] = {
    init: function() {
      this.appendValueInput("ELEMENT_ID")
          .setCheck("String")
          .appendField("set attribute for element with ID");
      this.appendValueInput("ATTRIBUTE_NAME")
          .setCheck("String")
          .appendField("attribute name");
      this.appendValueInput("ATTRIBUTE_VALUE")
          .setCheck("String")
          .appendField("attribute value");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip('Sets an attribute and its value for a specified element.');
      this.setHelpUrl('');
    }
  };

  Blockly.Blocks['generate_iframe'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Generate Iframe");
        this.appendDummyInput()
            .appendField("allowfullscreen")
            .appendField(new Blockly.FieldCheckbox("TRUE"), "ALLOWFULLSCREEN");
        this.appendStatementInput("DO")
            .setCheck(null)
            .appendField("Do");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("Generates an iframe with allowfullscreen and a nested document.");
        this.setHelpUrl("");
    }
  };

  Blockly.Blocks['clear_session_storage'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Clear Session Storage");
        this.setPreviousStatement(true, null); // Allows this block to connect to previous blocks
        this.setNextStatement(true, null); // Allows this block to connect to next blocks
        this.setColour("#fb5353");
        this.setTooltip("Clears the whole session storage.");
        this.setHelpUrl("");
    }
  };

  Blockly.Blocks['clear_local_storage'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Clear Local Storage");
        this.setPreviousStatement(true, null); // Allows this block to connect to previous blocks
        this.setNextStatement(true, null); // Allows this block to connect to next blocks
        this.setColour("#fb5353");
        this.setTooltip("Clears the whole local storage.");
        this.setHelpUrl("");
    }
  };

  // blocks to listen to storage events in local/session storage
  Blockly.Blocks['storage_event_handler'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Handle Local/Session Storage Event");
      this.appendStatementInput("DO")
          .setCheck(null)
          .appendField("Do");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Handles storage events and executes the 'Do' block.");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['storage_event_storage_area'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Storage Area");
      this.setOutput(true, "String");
      this.setColour(230);
      this.setTooltip("Returns the storage area (localStorage or sessionStorage).");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['storage_event_new_value'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("New Storage Value");
      this.setOutput(true, "String");
      this.setColour(230);
      this.setTooltip("Returns the new value of the storage item.");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['storage_event_old_value'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Old Storage Value");
      this.setOutput(true, "String");
      this.setColour(230);
      this.setTooltip("Returns the old value of the storage item.");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['storage_event_key'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Changed Storage Value's Key");
      this.setOutput(true, "String");
      this.setColour(230);
      this.setTooltip("Returns the key of the storage item.");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['get_parent_id'] = {
    init: function() {
      this.appendValueInput("ID")
          .setCheck("String")
          .appendField("Get Parent ID of ID:");
      this.setOutput(true, "String");
      this.setColour(230);
      this.setTooltip("Returns the ID of the parent node for the given ID.");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['convert_to_base64'] = {
    init: function () {
      this.appendValueInput("DATA")
        .setCheck(null)  // Accept any data type
        .appendField("Encode in Base64:");
      this.setOutput(true, "String");  // The block returns a string (Base64 encoded value)
      this.setColour(160);
      this.setTooltip("Converts any data type to Base64 encoded string");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['decode_from_base64'] = {
    init: function () {
      this.appendValueInput("BASE64")
        .setCheck("String")  // Accept a Base64 string
        .appendField("Decode from Base64:");
      this.setOutput(true, null);  // The block returns any data type (decoded)
      this.setColour(160);
      this.setTooltip("Decodes a Base64 string back to the original data type");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['url_encode_decode'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("URL")
          .appendField(new Blockly.FieldDropdown([
            ["encode", "ENCODE"],
            ["decode", "DECODE"]
          ]), "ACTION");

      this.appendValueInput("URL_STRING")
          .setCheck("String")
          .appendField("The URL string:");

      this.setOutput(true, "String"); // Set output type to String
      this.setColour(160); // Set block color
      this.setTooltip("Encode or decode a URL string.");
      this.setHelpUrl(""); // Add help URL if needed
    }
  };

  Blockly.Blocks['generate_md5'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Generate MD5 Hash");
      this.appendValueInput("MESSAGE")
          .setCheck("String")
          .appendField("Message");
      this.setOutput(true, "String");
      this.setColour(160);
      this.setTooltip("Generate an MD5 hash for the given message.");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['verify_md5'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Verify MD5 Hash");
      this.appendValueInput("MESSAGE")
          .setCheck("String")
          .appendField("Message");
      this.appendValueInput("HASH")
          .setCheck("String")
          .appendField("Hash");
      this.setOutput(true, "Boolean");
      this.setColour(230);
      this.setTooltip("Verify if the given message generates the specified MD5 hash.");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['aes_256_keygen'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("AES 256 keygen");
        
        this.setPreviousStatement(true, null); // Allow connection to previous blocks
        this.setNextStatement(true, null); // Allow connection to next blocks
        this.setColour(230);
        this.setTooltip("Generates a 256-bit AES key for encryption and decryption.");
        this.setHelpUrl("");

        // Add a statement input for additional logic (DO block)
        this.appendStatementInput("DO")
            .setCheck(null) // This allows any block type to be added here
            .appendField("do");
    }
  };

  Blockly.Blocks['aes_256_encrypt'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("AES 256 Encrypt");  // Block Label

        this.appendValueInput("PLAINTEXT")
            .setCheck("String")
            .appendField("Plaintext");  // Label for Plaintext input

        this.appendValueInput("KEY")
            .setCheck("String")  // Change from Promise to String for key input
            .appendField("Key");  // Label for Key input

        this.setPreviousStatement(true, null); // Allow connection to previous blocks
        this.setNextStatement(true, null); // Allow connection to next blocks
        this.setColour(230);
        this.setTooltip("Encrypts plaintext using AES 256 with GCM mode and sets the encrypted data in session storage.");
        this.setHelpUrl("");

        // Add a statement input for additional logic (DO block)
        this.appendStatementInput("DO")
            .setCheck(null) // Allow any block type to be added here
            .appendField("do");
    }
  };

  Blockly.Blocks['aes_256_decrypt'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("AES 256 Decrypt");  // Block Label

        this.appendValueInput("ENCRYPTED_OBJ")
            .setCheck("Object")
            .appendField("Encrypted Object");  // Label for Encrypted Object input

        this.appendValueInput("KEY")
            .setCheck("String")  // Change from Promise to String for key input
            .appendField("Key");  // Label for Key input

        this.setPreviousStatement(true, null); // Allow connection to previous blocks
        this.setNextStatement(true, null); // Allow connection to next blocks
        this.setColour(230);
        this.setTooltip("Decrypts encrypted data using AES 256 with GCM mode and sets the plaintext in session storage.");
        this.setHelpUrl("");

        // Add a statement input for additional logic (DO block)
        this.appendStatementInput("DO")
            .setCheck(null) // Allow any block type to be added here
            .appendField("do");
    }
  };

  // redirection block that redirects automatically to a given url/link
  Blockly.Blocks['redirect_to_url'] = {
    init: function () {
      this.appendValueInput("URL")
        .setCheck("String")
        .appendField("Redirect to URL:");

      this.appendDummyInput()
        .appendField("Automatically redirect")
        .appendField(new Blockly.FieldCheckbox("FALSE"), "AUTO_REDIRECT");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Redirects to a given URL if the checkbox is checked.");
      this.setHelpUrl("");
    }
  };

  // triggers events on the target element programmatically whether they are present in nested iframe doms or showdow doms
  Blockly.Blocks['trigger_browser_event'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Trigger event")
          .appendField(new Blockly.FieldDropdown([
            ["click", "click"],
            ["change", "change"],
            ["mouseover", "mouseover"],
            ["mouseout", "mouseout"],
            ["dblclick", "dblclick"],
            ["keydown", "keydown"],
            ["keyup", "keyup"],
            ["focus", "focus"],
            ["blur", "blur"]
          ]), "EVENT_TYPE");

      this.appendValueInput("TARGET_ID")
          .setCheck("String")
          .appendField("on element with ID");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Triggers a browser event on a target element, searching in nested documents.");
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks['function_toString'] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Store function:")
        .appendField(new Blockly.FieldDropdown(this.getProcedureList), "FUNCTION_NAME");
  
      this.appendValueInput("KEYNAME")
        .setCheck("String")
        .appendField("Store in your session with key:");
  
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Converts a user-defined function into a string and stores it in local storage.");
      this.setHelpUrl("");
    },
    getProcedureList: function () {
      // Get the list of user-defined procedures
      const procedures = Blockly.Procedures.allProcedures(Blockly.getMainWorkspace());
      const procedureNames = procedures[0].concat(procedures[1]); // Includes both return and no-return procedures
  
      // Map procedure names to dropdown options
      if (procedureNames.length === 0) {
        return [["<no functions>", ""]];
      }
      return procedureNames.map(proc => [proc[0], proc[0]]);
    }
  };  
  
  return Blockly.Blocks;
};
