import { API_HOST } from "../../api_utils";
import { createFileUploadButton } from "../../creatorFunctions";
import $ from "jquery";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

const customFormElements = (editor, opts = {}) => {
  const notifyTheme = {
    position: "top-right",
    autoClose: 7500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  const notifyTheme2 = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  const successTheme = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  // toast notification for empty tables
  const infoTheme = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  if (editor !== null && editor !== undefined) {
    // COMPLETE FORM WRAPPER
    editor.DomComponents.addType("complete_form_wrapper", {
      model: {
        defaults: {
          traits: [],
          tagName: "form",
          style: {
            width: "100%",
            "min-height": "550px",
            padding: "2rem",
          },
          attributes: {
            name: "customFormWrapper",
          },
        },
      },
    });

    // editor.BlockManager.add("complete_form_wrapper", {
    //   label: "Complete Form",
    //   category: "Form-Control",
    //   select: true,
    //   media: '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
    //   content: { type: "complete_form_wrapper" },
    //   draggable: "target, target *",
    // });

    // TITLE FOR FORM
    editor.DomComponents.addType("form_title", {
      model: {
        defaults: {
          traits: [],
          style: {
            width: "100%",
          },

          components: [
            {
              type: "text",
              style: {
                width: "100%",
                "text-align": "center",
                "font-weight": "800",
                "font-size": "24px",
              },
              content: `Dummy Title for Form Container`,
            },
          ],
        },
      },
    });

    editor.BlockManager.add("form_title", {
      label: "Form Title",
      category: "Form-Control",
      select: true,
      media:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
      content: { type: "form_title" },
      draggable: "target, target *",
    });

    // TEXT FIELD FOR FORM
    editor.DomComponents.addType("form_text_field", {
      model: {
        defaults: {
          traits: [],
          tagName: "div",
          style: {
            width: "100%",
            display: "flex",
            "justify-content": "start",
            "align-items": "center",
          },
          components: [
            {
              type: "text",
              style: {
                width: "8rem",
                "font-weight": "600",
                "margin-right": "0.5rem",
              },
              content: "Dummy Label :",
              attributes: {
                for: "textField",
              },
            },
            {
              type: "default",
              tagName: "input",
              style: {
                "flex-grow": "1",
                padding: "10px",
                outline: "none",
                border: "1px solid #D3D3D3",
              },
              attributes: {
                placeholder: "Enter a text field here...",
                name: "textField",
                type: "text",
              },
            },
          ],
        },
      },
    });

    editor.BlockManager.add("form_text_field", {
      label: "Text Input",
      category: "Form-Control",
      select: true,
      media:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
      content: { type: "form_text_field" },
      draggable: "target, target *",
    });

    // NUMBER FIELD FOR FORM
    editor.DomComponents.addType("form_number_field", {
      model: {
        defaults: {
          traits: [],
          tagName: "div",
          style: {
            width: "100%",
            display: "flex",
            "justify-content": "start",
            "align-items": "center",
          },
          components: [
            {
              type: "text",
              style: {
                width: "8rem",
                "font-weight": "600",
                "margin-right": "0.5rem",
              },
              content: "Number :",
            },
            {
              type: "default",
              tagName: "input",
              style: {
                "flex-grow": "1",
                padding: "10px",
                outline: "none",
                border: "1px solid #D3D3D3",
              },
              attributes: {
                placeholder: "Enter a number  here...",
                type: "number",
              },
            },
          ],
        },
      },
    });

    editor.BlockManager.add("form_number_field", {
      label: "Number Input",
      category: "Form-Control",
      select: true,
      media:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
      content: { type: "form_number_field" },
      draggable: "target, target *",
    });

    // EMAIL FIELD FOR FORM
    editor.DomComponents.addType("form_email_field", {
      model: {
        defaults: {
          traits: [],
          tagName: "div",
          style: {
            width: "100%",
            display: "flex",
            "justify-content": "start",
            "align-items": "center",
          },
          components: [
            {
              type: "text",
              style: {
                width: "8rem",
                "font-weight": "600",
                "margin-right": "0.5rem",
              },
              content: "Email :",
            },
            {
              type: "default",
              tagName: "input",
              style: {
                "flex-grow": "1",
                padding: "10px",
                outline: "none",
                border: "1px solid #D3D3D3",
              },
              attributes: {
                placeholder: "Enter your Email Id  here...",
                type: "email",
              },
            },
          ],
        },
      },
    });

    editor.BlockManager.add("form_email_field", {
      label: "Email Input",
      category: "Form-Control",
      select: true,
      media:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
      content: { type: "form_email_field" },
      draggable: "target, target *",
    });

    // PASSWORD FIELD FOR FORM
    editor.DomComponents.addType("form_password_field", {
      model: {
        defaults: {
          traits: [],
          tagName: "div",
          style: {
            width: "100%",
            display: "flex",
            "justify-content": "start",
            "align-items": "center",
          },
          components: [
            {
              type: "text",
              style: {
                width: "8rem",
                "font-weight": "600",
                "margin-right": "0.5rem",
              },
              content: "Password :",
            },
            {
              type: "default",
              tagName: "input",
              style: {
                "flex-grow": "1",
                padding: "10px",
                outline: "none",
                border: "1px solid #D3D3D3",
              },
              attributes: {
                placeholder: "Enter your password here...",
                type: "password",
              },
            },
          ],
        },
      },
    });

    editor.BlockManager.add("form_password_field", {
      label: "Password Input",
      category: "Form-Control",
      select: true,
      media:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
      content: { type: "form_password_field" },
      draggable: "target, target *",
    });

    // PHONE FIELD FOR FORM
    editor.DomComponents.addType("form_phone_field", {
      model: {
        defaults: {
          traits: [],
          tagName: "div",
          style: {
            width: "100%",
            display: "flex",
            "justify-content": "start",
            "align-items": "center",
          },
          components: [
            {
              type: "text",
              style: {
                width: "8rem",
                "font-weight": "600",
                "margin-right": "0.5rem",
              },
              content: "Phone Number :",
            },
            {
              type: "default",
              tagName: "input",
              style: {
                "flex-grow": "1",
                padding: "10px",
                outline: "none",
                border: "1px solid #D3D3D3",
              },
              attributes: {
                placeholder: "Enter your phone Number as 91-1234567890",
                type: "tel",
                pattern: "[0-9]{2}-[0-9]{10}",
              },
            },
          ],
        },
      },
    });

    editor.BlockManager.add("form_phone_field", {
      label: "Phone Input",
      category: "Form-Control",
      select: true,
      media:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
      content: { type: "form_phone_field" },
      draggable: "target, target *",
    });

    // FILE FIELD FOR FORM
    editor.Components.addType("upload-file", {
      model: {
        defaults: {
          tagName: "div",
          attributes: {
            id: "upload-file",
            style: "max-width: 100%; min-height: 5px; padding: 0.5em;",
          },
          // script: createUploadBtn,

          components: [
            {
              tagName: "div",
              attributes: {
                class: "FileUploadBtnDiv",
                id: "FileUploadBtnDiv",
              },

              "style-default": {
                width: "max-content",
              },

              style: {
                width: "max-content",
              },

              components: [
                {
                  tagName: "label",
                  attributes: {
                    id: `file_upload_id`,
                    class: "file-upload-btn-label",
                    for: "file-upload-btn-input",
                    title: "Double click to upload",
                  },

                  "style-default": {
                    width: "max-content",
                    display: "inline-block",
                    padding: "0.5em",
                    border: "1px solid black",
                    "border-radius": "10px",
                    pointer: "cursor",
                  },

                  style: {
                    width: "max-content",
                    display: "inline-block",
                    padding: "0.5em",
                    border: "1px solid black",
                    "border-radius": "10px",
                    pointer: "cursor",
                  },

                  // content: 'Upload a file',

                  components: {
                    tagName: "p",
                    type: "text",
                    editable: true,

                    components: {
                      type: "textnode",
                      content: "Upload a file",
                    },

                    "style-default": {
                      margin: "auto",
                      "text-align": "center",
                    },

                    style: {
                      margin: "auto",
                      "text-align": "center",
                    },
                  },
                },
                {
                  tagName: "input",
                  attributes: {
                    type: "flie",
                    id: "file-upload-btn-input",
                    class: "file-upload-btn-input",
                    name: "file-upload-btn-input",
                  },

                  "style-default": {
                    display: "none",
                  },

                  style: {
                    display: "none",
                  },
                },
              ],
            },
          ],

          // traits
          Lock_System_File_Dialog: true,

          traits: [
            {
              type: "select",
              name: "Lock_System_File_Dialog",
              lable: "Lock System File Dialog",
              options: [
                { id: "true", value: true, name: "Lock" },
                { id: "false", value: false, name: "Unlock" },
              ],
              changeProp: true,
            },
          ],

          changeProp: true,

          "script-props": ["Lock_System_File_Dialog"],
        },
      },

      view: {
        onRender({ el, model }) {
          // console.log("el:", el);
          // if (el.className === 'upload-file') {
          // }
        },

        events: {
          "click .file-upload-btn-label": "openSystemFileDialog",
        },

        openSystemFileDialog(event) {
          // event.stopPropagation();
          console.log("double clicked");

          const lockSystemFileDialog = this.model.get(
            "Lock_System_File_Dialog"
          );
          // console.log("lockSystemFileDialog:", lockSystemFileDialog);

          if (lockSystemFileDialog && lockSystemFileDialog !== undefined) {
            let container = document.querySelector(".FileUploadBtnDiv");
            let inputFile = document.createElement("input");
            inputFile.type = "file";
            inputFile.addEventListener("change", async (event) => {
              let file = inputFile.files[0];

              console.log("file is:", file);

              let formData = new FormData();
              formData.append("file", file);

              try {
                const response = await fetch(`${API_HOST}uploadRoutes/upload`, {
                  method: "POST",
                  body: formData,
                  headers: {
                    // 'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo?.token}`,
                  },
                });

                if (response.ok) {
                  const data = await response.json();
                  console.log("File uploaded:", data);
                } else {
                  console.error("Error uploading file:", response.statusText);
                }
              } catch (error) {
                console.error("Error uploading file:", error);
              }
            });
            container?.appendChild(inputFile);
            inputFile.click();
          }
        },
      },
    });

    // editor.BlockManager.add("upload-file", {
    //   label: "File Input",
    //   category: "Form-Control",
    //   select: true,
    //   media:
    //     '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
    //   content: { type: "upload-file" },
    //   draggable: "target, target *",
    // });

    // url FIELD FOR FORM

    editor.DomComponents.addType("form_url_field", {
      model: {
        defaults: {
          traits: [],
          tagName: "div",
          style: {
            width: "100%",
            display: "flex",
            "justify-content": "start",
            "align-items": "center",
          },
          components: [
            {
              type: "text",
              style: {
                width: "8rem",
                "font-weight": "600",
                "margin-right": "0.5rem",
              },
              content: "Add a Url :",
            },
            {
              type: "default",
              tagName: "input",
              style: {
                "flex-grow": "1",
                padding: "10px",
                outline: "none",
                border: "1px solid #D3D3D3",
              },
              attributes: {
                placeholder: "Enter a url here",
                type: "url",
              },
            },
          ],
        },
      },
    });

    editor.BlockManager.add("form_url_field", {
      label: "Url Input",
      category: "Form-Control",
      select: true,
      media:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
      content: { type: "form_url_field" },
      draggable: "target, target *",
    });

    // checkbox comp
    const checkboxScript = function (props) {
      // if (props.nameToCheck) {
      //     this.setAttribute('name', `${props.nameToCheck}`)
      // }
      // this.setAttribute('id', `${nameTrait}`)
    };

    editor.DomComponents.addType("basicCheckBox", {
      model: {
        defaults: {
          script: checkboxScript,
          tagName: "basicCheckBox",
          nameToCheck: "",
          isChecked: "",
          traits: [
            {
              name: "nameToCheck",
              label: "Name",
              type: "text",
              changeProp: true,
            },
            {
              name: "isChecked",
              type: "select",
              label: "is_checked",
              options: [
                {
                  value: true,
                  name: true,
                },
                {
                  value: false,
                  name: false,
                },
              ],
              changeProp: true,
            },
          ],
          "script-props": ["nameToCheck", "isChecked"],
          // Add some style, just to make the component visible
          components: `<p id="wrapperP">
                                  <input type="checkbox"/>
                                  </p>
                                  
                                  <style>
                                  #wrapperP{
                                      display:flex;
                                      justify-content:space-between;
                                      align-items:center
                                  }
                                  </style>
                                  
                                  `,
        },
      },
    });

    editor.BlockManager.add("checkbox-regular-form", {
      label: "Checkbox Input",
      category: "Form-Control",
      select: true,
      media:
        '<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/><path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/></svg>',
      content: { type: "basicCheckBox" },
      draggable: "target, target *",
    });

    editor.DomComponents.addType("form_submit_field", {
      model: {
        defaults: {
          traits: [],
          tagName: "div",
          style: {
            width: "100%",
            display: "flex",
            "justify-content": "start",
            "align-items": "center",
          },
          components: [
            {
              type: "default",
              tagName: "button",
              style: {
                padding: "0.3rem 0.8rem",
              },

              content: `submit`,
            },
          ],
          traits: [
            {
              type: "Label",

              name: "Label",

              label: "Label",

              changeProp: true,
            },
          ],

          "script-props": ["Label"],
        },
      },
      view: {
        init({ model }) {
          // to attach event listener on initialization
          this.listenTo(model, "change:Label", this.handleBlockModalTrait);
        },

        handleBlockModalTrait() {
          const blockModal = this.model.get("Label");
          // console.log("blockModal:", blockModal);

          // Get the button component
          const buttonComponent = this.model.get("components").at(0);

          // Manipulate the content of the button based on the Label trait
          if (blockModal) {
            buttonComponent.set("content", blockModal);
          } else {
            buttonComponent.set("content", "Submit"); // Reset to the default content
          }

          // console.log("Button content updated:", buttonComponent.get("content"));
        },
      },
    });

    editor.BlockManager.add("form_submit_field", {
      label: "Submit Button",
      category: "Form-Control",
      select: true,
      media:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>',
      content: { type: "form_submit_field" },
      draggable: "target, target *",
    });

    // searchable input_select dropdown
    // editor.BlockManager.add("input_select_with_search", {
    //   label: "Form Dropdown (With Search)",
    //   category: "Form-Control",
    //   select: true,
    //   media:
    //     '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8A1.5 1.5 0 0 1 11 1.5v2A1.5 1.5 0 0 1 9.5 5h-8A1.5 1.5 0 0 1 0 3.5zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5z"/><path d="m7.823 2.823-.396-.396A.25.25 0 0 1 7.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0M0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"/></svg>',
    //   content: { type: "input_select_with_search" },
    //   draggable: true,
    // });

    // https://www.npmjs.com/package/bootstrap-select
    // https://developer.snapappointments.com/bootstrap-select/options/#bootstrap-version
    const inputSelectSearchScript = function (props) {
      console.log("inputSelectSearchScript", props);
      let container = this;

      const initLib = function ($, props) {
        $(document).ready(function () {
          let select = $(container).find(".selectpicker");

          // $('.selectpicker').on('shown.bs.select', function (e, clickedIndex, isSelected, previousValue) {
          //   console.log('++++++++++++++');
          // });

          // select.selectpicker();
          if (select.length) {
            select.selectpicker("destroy");
            select.addClass("selectpicker"); // Add the class back
            select.selectpicker();

            // // Attach events
            // select.on('shown.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            //   console.log('Bootstrap select loaded');
            //   const $dropdownMenu = select.parent().find('.dropdown-menu');
            //   if ($dropdownMenu.length) {
            //     const $emptyLi = $dropdownMenu.find('li').filter(function () {
            //       return $(this).find('span.text').text().trim() === '';
            //     });
            //     if ($emptyLi.length) {
            //       $emptyLi.remove();
            //     }
            //   }
            // });

            // select.on('rendered.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            //   console.log('Bootstrap select loaded 3');
            //   const $dropdownMenu = select.parent().find('.dropdown-menu');
            //   if ($dropdownMenu.length) {
            //     const $emptyLi = $dropdownMenu.find('li').filter(function () {
            //       return $(this).find('span.text').text().trim() === '';
            //     });
            //     if ($emptyLi.length) {
            //       $emptyLi.remove();
            //     }
            //   }
            // });

            if (!props.Multiple) {
              select.on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
                console.log('Option changed 1', e, clickedIndex, isSelected, previousValue);
                // Do something...
              });
            }            
          } else {
            select.addClass("selectpicker"); // Add the class for new elements
            select.selectpicker();

            // // Attach events
            // select.on('shown.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            //   console.log('Bootstrap select loaded 1');
            //   const $dropdownMenu = select.parent().find('.dropdown-menu');
            //   if ($dropdownMenu.length) {
            //     const $emptyLi = $dropdownMenu.find('li').filter(function () {
            //       return $(this).find('span.text').text().trim() === '';
            //     });
            //     if ($emptyLi.length) {
            //       $emptyLi.remove();
            //     }
            //   }
            // });

            // select.on('rendered.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            //   console.log('Bootstrap select loaded 4');
            //   const $dropdownMenu = select.parent().find('.dropdown-menu');
            //   if ($dropdownMenu.length) {
            //     const $emptyLi = $dropdownMenu.find('li').filter(function () {
            //       return $(this).find('span.text').text().trim() === '';
            //     });
            //     if ($emptyLi.length) {
            //       $emptyLi.remove();
            //     }
            //   }
            // });

            if (!props.Multiple) {
              select.on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
                console.log('Option changed 2', e, clickedIndex, isSelected, previousValue);
                // Do something...
              });
            }            
          }
        });
      };
      
      const loadScript = (src) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.onload = resolve;
          script.onerror = reject;
          script.src = src;
          document.body.appendChild(script);
        });
      };

      const loadCSS = (href) => {
        return new Promise((resolve, reject) => {
          const link = document.createElement("link");
          link.rel = "stylesheet";
          link.href = href;
          link.onload = resolve;
          link.onerror = reject;
          document.head.appendChild(link);
        });
      };

      const loadScriptForRedSlingPreview = (src) => {
        return new Promise((resolve, reject) => {
          // to prevent adding the same cdn multiple times, otherwise the component doesn' t work well
          if (!document.querySelector(`script[src="${src}"]`)) {
            const script = document.createElement("script");
            script.onload = resolve;
            script.onerror = reject;
            script.src = src;
            document.body.appendChild(script);
          } else {
            resolve();
          }
        });
      };

      const loadCSSForRedSlingPreview = (href) => {
        return new Promise((resolve, reject) => {
          // to prevent adding the same cdn multiple times, otherwise the component doesn' t work well
          if (!document.querySelector(`link[href="${href}"]`)) {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = href;
            link.onload = resolve;
            link.onerror = reject;
            document.head.appendChild(link);
          } else {
            resolve();
          }
        });
      };

      // to check if this script is loaded in the redSling editor or preview. If opened in preview, load the cdns only once, and if in the editor, then load the cdns as many times as traits are updated so that the component initializes everytime in the editor with updated traits/props and if neither in preview nor editor, then don't load the cdns
      const url = window.top.location.href;
      const isInRedSlingPreview =
        url.indexOf("/large_preview/") !== -1 ||
        url.indexOf("/tab_preview/") !== -1 ||
        url.indexOf("/mobile_preview/") !== -1;
      const isInRedSlingEnv =
        (url.indexOf("/editor/") !== -1 && url.indexOf("?projectId=") !== -1) ||
        url.indexOf("/fragment_editor/") !== -1;

      if (isInRedSlingPreview && !isInRedSlingEnv) {
        // Load CSS and scripts sequentially using promises
        loadCSSForRedSlingPreview(
          "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
        )
          .then(() =>
            loadScriptForRedSlingPreview(
              "https://code.jquery.com/jquery-3.5.1.min.js"
            )
          )
          .then(() =>
            loadScriptForRedSlingPreview(
              "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.bundle.min.js"
            )
          )
          .then(() =>
            loadCSSForRedSlingPreview(
              "https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.18/dist/css/bootstrap-select.min.css"
            )
          )
          .then(() =>
            loadScriptForRedSlingPreview(
              "https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/js/bootstrap-select.min.js"
            )
          )
          .then(() => {
            window.$.fn.selectpicker.Constructor.BootstrapVersion = "4";
            initLib(window.jQuery, props);
          })
          .catch((error) => {
            console.error("Resource loading error:", error);
          });
      } else if (!isInRedSlingPreview && isInRedSlingEnv) {
        // Load CSS and scripts sequentially using promises
        loadCSS(
          "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
        )
          .then(() => loadScript("https://code.jquery.com/jquery-3.5.1.min.js"))
          .then(() =>
            loadScript(
              "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.bundle.min.js"
            )
          )
          .then(() =>
            loadCSS(
              "https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.18/dist/css/bootstrap-select.min.css"
            )
          )
          .then(() =>
            loadScript(
              "https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/js/bootstrap-select.min.js"
            )
          )
          .then(() => {
            window.$.fn.selectpicker.Constructor.BootstrapVersion = "4";
            initLib(window.jQuery, props);
          })
          .catch((error) => {
            console.error("Resource loading error:", error);
          });
      } else if (!isInRedSlingPreview && !isInRedSlingEnv) {
        initLib(window.jQuery, props);
      }

      console.log("+++ this", this);
      const selectElements =
        container.getElementsByClassName("selectpicker")[0];
      console.log("+++", selectElements);
      // Array.from(selectElements).forEach(select => {
      // 	const dropdownMenu = select.parentNode.querySelectorAll('.dropdown.bootstrap-select');
      // 	console.log("+++ dropdownMenu", dropdownMenu);
      // 	// .querySelector('ul.dropdown-menu')
      // });
      // let dropdown1 = container.querySelectorAll('.dropdown.bootstrap-select')[0];
      // let dropdown2 = container.getElementsByClassName('bootstrap-select')[0];
      // console.log("+++ dropdown", dropdown1, dropdown2);
    };

    editor.DomComponents.addType("input_select_with_search", {
      model: {
        defaults: {
          tagName: "div",
          type: "input_select_with_search",
          attributes: {
            class: "input_select_with_search",
            "data-child-search-select-id": "",
          },
          script: inputSelectSearchScript,

          // traits
          LiveSearch: true,
          Title: "Choose one of the following...",
          SetOptionsManually: "",
          ButtonStyle: "default",
          HideMenu: true,

          CustomUrl: "",
          BaseUrl: "",
          APIPath: "",
          BearerToken: "",
          Username: "",
          Password: "",
          MoreHeaders: "",
          APIMethod: "GET",
          APIBody: "",
          ArrOfObjKyename: "",
          NestedKeyname: "",

          Multiple: false,
          Disabled: false,
          Size: false,
          SelectDeselectAllOptions: false,

          options: opts,

          projectId: localStorage.getItem("project_id")
            ? localStorage.getItem("project_id")
            : "",

          traits: [
            {
              type: "checkbox",
              name: "HideMenu",
              label: "Hide Menu",
              changeProp: true,
            },{
              type: "checkbox",
              name: "LiveSearch",
              label: "Live Search",
              changeProp: true,
            },
            {
              type: "text",
              name: "Title",
              label: "Title Text",
              placeholder: "Choose one of the following...",
              changeProp: true,
            },
            {
              type: "select",
              name: "ButtonStyle",
              label: "Button Style",
              options: [
                { id: "default", value: "default", name: "Default" },
                { id: "primary", value: "primary", name: "Primary" },
                { id: "info", value: "info", name: "Info" },
                { id: "success", value: "success", name: "Success" },
                { id: "warning", value: "warning", name: "Warning" },
                { id: "danger", value: "danger", name: "Danger" },
              ],
              changeProp: true,
            },
            {
              type: "text",
              name: "SetOptionsManually",
              label: "Set Options Manually",
              placeholder: "Option 1,Option 2,Option 3",
              changeProp: true,
            },
            {
              type: "text",
              name: "CustomUrl",
              label: "Custom URL",
              changeProp: true,
            },
            {
              type: "select",
              name: "BaseUrl",
              label: "Base URL",
              options: [
                { id: "baseUrl1", name: "Base URL #1" },
                { id: "baseUrl2", name: "Base URL #2" },
                { id: "baseUrl3", name: "Base URL #3" },
                { id: "baseUrl4", name: "Base URL #4" },
                { id: "baseUrl5", name: "Base URL #5" },
                { id: "null", name: "No Base URL" },
              ],
              changeProp: true,
            },
            {
              type: "text",
              name: "APIPath",
              label: "API Path",
              placeholder: "Don't start with '/'",
              changeProp: true,
            },
            {
              type: "text",
              name: "BearerToken",
              label: "Bearer Token",
              placeholder: "No bearer token provided",
              changeProp: true,
            },
            {
              type: "text",
              name: "Username",
              label: "Username",
              changeProp: true,
            },
            {
              type: "text",
              name: "Password",
              label: "Password",
              changeProp: true,
            },
            {
              type: "text",
              name: "MoreHeaders",
              label: "More Headers",
              placeholder: "k1:v1,k2:v2,k3:v3,...",
              changeProp: true,
            },
            {
              type: "select",
              name: "APIMethod",
              label: "API Method",
              options: [
                { id: "GET", name: "Get" },
                { id: "POST", name: "Post" },
              ],
              changeProp: true,
            },
            {
              type: "text",
              name: "APIBody",
              label: "API Body",
              placeholder: "Json format",
              changeProp: true,
            },
            {
              type: "text",
              name: "ArrOfObjKyename",
              label: "Keyname From Array Of Object",
              placeholder: "[ {k1:v1}, {k1:v2} ]",
              changeProp: true,
            },
            {
              type: "text",
              name: "NestedKeyname",
              label: "Keyname For Nested Structure",
              placeholder: "{ k1: [{...}, {...}] }",
              changeProp: true,
            },
            {
              type: "checkbox",
              name: "Multiple",
              label: "Multiple",
              changeProp: true,
            },
            {
              type: "checkbox",
              name: "Disabled",
              label: "Disabled",
              changeProp: true,
            },
            {
              type: "text",
              name: "Size",
              label: "Size",
              changeProp: true,
            },
            {
              type: "checkbox",
              name: "SelectDeselectAllOptions",
              label: "Select/Deselect All Options Buttons",
              changeProp: true,
            },
          ],

          changeProp: true,

          "script-props": [
            "HideMenu",
            "LiveSearch",
            "Title",
            "ButtonStyle",
            "SetOptionsManually",
            "CustomUrl",
            "BaseUrl",
            "APIPath",
            "BearerToken",
            "Username",
            "Password",
            "MoreHeaders",
            "APIMethod",
            "APIBody",
            "ArrOfObjKyename",
            "NestedKeyname",
            "Multiple",
            "Disabled",
            "Size",
            "SelectDeselectAllOptions",
            "options",
            "projectId",
          ],

          components: [
            {
              tagName: "select",
              type: "default",
              attributes: {
                class: "selectpicker",
                "data-live-search": "true",
                title: "Choose one of the following...",
              },

              components: [
                {
                  tagName: "option",
                  attributes: { value: "1" },
                  components: {
                    type: "textnode",
                    content: "Option 1",
                  },
                },
                {
                  tagName: "option",
                  attributes: { value: "2" },
                  components: {
                    type: "textnode",
                    content: "Option 2",
                  },
                },
                {
                  tagName: "option",
                  attributes: { value: "3" },
                  components: {
                    type: "textnode",
                    content: "Option 3",
                  },
                },
                {
                  tagName: "option",
                  attributes: { value: "4" },
                  components: {
                    type: "textnode",
                    content: "Option 4",
                  },
                },
              ],
            },
          ],
        },

        init() {
          this.on("change:HideMenu", this.hideMenuTraitHandler);
          this.on("change:LiveSearch", this.liveSearchTraitHandler);
          this.on("change:Title", this.titleTraitHandler);
          this.on("change:ButtonStyle", this.buttonStyleTraitHandler);
          this.on("change:SetOptionsManually", this.setOptionsManuallyTraitHandler );
          this.on("change:Multiple", this.multipleTraitHandler);
          this.on("change:Disabled", this.disabledTraitHandler);
          this.on("change:Size", this.sizeTraitHandler);
          this.on("change:SelectDeselectAllOptions", this.selectDeselectAllOptionsTraitHandler );

          this.on("change:CustomUrl", this.plottingSelectDDTraitHandler);
          this.on("change:BaseUrl", this.plottingSelectDDTraitHandler);
          this.on("change:APIPath", this.plottingSelectDDTraitHandler);
          this.on("change:BearerToken", this.plottingSelectDDTraitHandler);
          this.on("change:Username", this.plottingSelectDDTraitHandler);
          this.on("change:Password", this.plottingSelectDDTraitHandler);
          this.on("change:MoreHeaders", this.plottingSelectDDTraitHandler);
          this.on("change:APIBody", this.plottingSelectDDTraitHandler);
          this.on("change:CustomUrl", this.plottingSelectDDTraitHandler);
          this.on("change:ArrOfObjKyename", this.plottingSelectDDTraitHandler);
          this.on("change:NestedKeyname", this.plottingSelectDDTraitHandler);
        },

        // to change the label/title of the select-dropdown
        titleTraitHandler() {
          let { Title } = this.props();
          let container = this;
          let selectDD = container.find(".selectpicker")[0];

          // if Title is provided then add it, else remove it
          if (Title) {
            selectDD.addAttributes({
              class: "selectpicker",
              "data-live-search": "true",
              title: Title,
            });
          } else {
            selectDD.addAttributes({
              class: "selectpicker",
              "data-live-search": "true",
              title: "",
            });
          }

          // this.attributes.script.call(this.getEl(), this.props());
        },

        setOptionsManuallyTraitHandler() {
          let { SetOptionsManually } = this.props();
          let container = this;
          let selectDD = container.find(".selectpicker")[0];
          console.log(
            "SetOptionsManually, selectDD",
            SetOptionsManually,
            selectDD
          );

          // if SetOptionsManually is provided then add new options, else add default options
          if (SetOptionsManually) {
            selectDD.components("");

            let options = SetOptionsManually.split(",");
            options.forEach((opt, idx) => {
              let newOption = {
                tagName: "option",
                attributes: { value: `${opt}` },
                components: {
                  type: "textnode",
                  content: `${opt}`,
                },
              };

              selectDD.append(newOption);
            });
          } else {
            selectDD.components("");

            for (let opt = 1; opt <= 4; opt++) {
              let defaultOption = {
                tagName: "option",
                attributes: { value: `${opt}` },
                components: {
                  type: "textnode",
                  content: `Option ${opt}`,
                },
              };

              selectDD.append(defaultOption);
            }
          }

          // this.attributes.script.call(this.getEl(), this.props());
        },

        multipleTraitHandler() {
          let { Title, Multiple } = this.props();
          let container = this;
          let selectDD = container.find(".selectpicker")[0];

          // if SetOptionsManually is provided then add new options, else add default options
          if (Multiple) {
            selectDD.addAttributes({
              class: "selectpicker",
              multiple: "multiple",
            });
          } else {
            selectDD.removeAttributes("multiple");
          }

          // this.attributes.script.call(this.getEl(), this.props());
        },

        disabledTraitHandler() {
          let { Disabled } = this.props();
          let container = this;
          let selectDD = container.find(".selectpicker")[0];

          // if SetOptionsManually is provided then add new options, else add default options
          if (Disabled) {
            selectDD.addAttributes({
              class: "selectpicker",
              disabled: "disabled",
            });
          } else {
            selectDD.removeAttributes("disabled");
          }

          // this.attributes.script.call(this.getEl(), this.props());
        },

        sizeTraitHandler() {
          let { Size } = this.props();
          let container = this;
          let selectDD = container.find(".selectpicker")[0];

          // if SetOptionsManually is provided then add new options, else add default options
          if (Size) {
            selectDD.addAttributes({
              class: "selectpicker",
              "data-size": Size,
            });
          } else {
            selectDD.addAttributes({
              class: "selectpicker",
              "data-size": false,
            });
          }

          // this.attributes.script.call(this.getEl(), this.props());
        },

        liveSearchTraitHandler() {
          let { LiveSearch } = this.props();
          let container = this;
          let selectDD = container.find(".selectpicker")[0];

          // if SetOptionsManually is provided then add new options, else add default options
          if (!LiveSearch) {
            selectDD.addAttributes({
              ...selectDD.getAttributes(),
              class: "selectpicker",
              "data-live-search": false,
            });
          } else {
            selectDD.addAttributes({
              ...selectDD.getAttributes(),
              class: "selectpicker",
              "data-live-search": true,
            });
          }

          // this.attributes.script.call(this.getEl(), this.props());
        },

        buttonStyleTraitHandler() {
          let { ButtonStyle } = this.props();
          let container = this;
          let selectDD = container.find(".selectpicker")[0];

          // if SetOptionsManually is provided then add new options, else add default options
          if (ButtonStyle === "default") {
            selectDD.removeAttributes('data-style');
          } else {
            selectDD.addAttributes({
              ...selectDD.getAttributes(),
              class: "selectpicker",
              "data-style": `btn-${ButtonStyle}`,
            });
          }

          // this.attributes.script.call(this.getEl(), this.props());
        },

        hideMenuTraitHandler() {
          let container = this;
          let selectDD = container.find(".selectpicker")[0];

          // Check if jQuery is available
            const $ = window.jQuery || window.$;
            if (!$) {
                console.error("jQuery is not available.");
                return;
            }

            if (selectDD) {
                // Access the parent .dropdown and trigger 'toggle' to close it if open
                const parentDropdown = $(selectDD).parent('.dropdown');
                if (parentDropdown.hasClass('show')) {
                    parentDropdown.find('button').dropdown('toggle'); // This will hide the dropdown
                }
            }
        },

        selectDeselectAllOptionsTraitHandler() {
          let { SelectDeselectAllOptions } = this.props();
          let container = this;
          let selectDD = container.find(".selectpicker")[0];

          // if SetOptionsManually is provided then add new options, else add default options
          if (SelectDeselectAllOptions) {
            selectDD.addAttributes({
              class: "selectpicker",
              multiple: "multiple",
              "data-actions-box": "true",
            });
          } else {
            selectDD.removeAttributes(["data-actions-box", "multiple"]);
          }

          // this.attributes.script.call(this.getEl(), this.props());
        },

        async plottingSelectDDTraitHandler() {
          let {
            CustomUrl,
            BaseUrl,
            APIPath,
            BearerToken,
            Username,
            Password,
            MoreHeaders,
            APIMethod,
            APIBody,
            ArrOfObjKyename,
            NestedKeyname,
            options,
          } = this.props();

          let apiData;
          let container = this;
          let dropdown = container.find(".selectpicker")[0];

          // to rename a variable because API var is getting used in many places in this block scope
          let API;
          if (APIPath) {
            API = APIPath;
          }

          // helper function to check if a variable is an array-of-objects or not
          function isArrayofObjects(variable) {
            // Check if the variable is an array
            if (!Array.isArray(variable)) {
              return false;
            }

            // Check if all elements in the array are objects
            for (const element of variable) {
              if (
                typeof element !== "object" ||
                Array.isArray(element) ||
                element === null
              ) {
                return false;
              }
            }
            return true;
          }

          // helper function to get value of a key in nested object
          function findKeyValue(obj, key) {
            if (obj.hasOwnProperty(key)) {
              // If the current object has the key, return its value
              return obj[key];
            }

            for (var prop in obj) {
              if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
                // If the current property is an object, recursively search within it
                var result = findKeyValue(obj[prop], key);
                if (result !== undefined) {
                  return result;
                }
              }
            }
            // Key not found in the object
            return undefined;
          }

          // -------- Getting API Data (GET method)

          // if CustomUrl is provided, use only that
          if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "GET" ) {
            console.log("CustomUrl", CustomUrl);
            // if nothing is provided for auth
            if (!BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          else if (BaseUrl && API && !CustomUrl && APIMethod === "GET") {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            if (BaseUrl === "baseUrl1") {
              url = options.baseUrl1 + API;
              baseUrlBearerToken = options.baseUrl1BearerToken;
            } else if (BaseUrl === "baseUrl2") {
              url = options.baseUrl2 + API;
              baseUrlBearerToken = options.baseUrl2BearerToken;
            } else if (BaseUrl === "baseUrl3") {
              url = options.baseUrl3 + API;
              baseUrlBearerToken = options.baseUrl3BearerToken;
            } else if (BaseUrl === "baseUrl4") {
              url = options.baseUrl4 + API;
              baseUrlBearerToken = options.baseUrl4BearerToken;
            } else if (BaseUrl === "baseUrl5") {
              url = options.baseUrl5 + API;
              baseUrlBearerToken = options.baseUrl5BearerToken;
            }
            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

            // if nothing is provided for auth
            if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              baseUrlBearerToken &&
              !BearerToken &&
              !Username &&
              !Password
            ) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: `Bearer ${baseUrlBearerToken}`,
                  "Content-Type": "application/json",
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // -------- Getting API Data (POST method)

          // if CustomUrl is provided, use only that
          else if (
            CustomUrl &&
            (!BaseUrl || BaseUrl === "null") &&
            APIMethod === "POST"
          ) {
            console.log("CustomUrl", CustomUrl);
            // if nothing is provided for auth
            if (!BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          else if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            if (BaseUrl === "baseUrl1") {
              url = options.baseUrl1 + API;
              baseUrlBearerToken = options.baseUrl1BearerToken;
            } else if (BaseUrl === "baseUrl2") {
              url = options.baseUrl2 + API;
              baseUrlBearerToken = options.baseUrl2BearerToken;
            } else if (BaseUrl === "baseUrl3") {
              url = options.baseUrl3 + API;
              baseUrlBearerToken = options.baseUrl3BearerToken;
            } else if (BaseUrl === "baseUrl4") {
              url = options.baseUrl4 + API;
              baseUrlBearerToken = options.baseUrl4BearerToken;
            } else if (BaseUrl === "baseUrl5") {
              url = options.baseUrl5 + API;
              baseUrlBearerToken = options.baseUrl5BearerToken;
            }
            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

            // if nothing is provided for auth
            if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              baseUrlBearerToken &&
              !BearerToken &&
              !Username &&
              !Password
            ) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${baseUrlBearerToken}`,
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          console.log("apiData:", apiData);

          // -------- Populating API Data, into the dropdown list
          if (apiData !== undefined) {
            console.log("#");
            // if API response is directly an array-of-objects
            if (isArrayofObjects(apiData)) {
              if (ArrOfObjKyename) {
                console.log("##");
                dropdown.components("");

                apiData.forEach((opt) => {
                  let optionEle = {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      // value: `${opt[`${ArrOfObjKyename}`]}`,
                      value: JSON.stringify({
                        _id: opt['_id'],
                        ArrOfObjKyename: opt[`${ArrOfObjKyename}`],
                      }),
                      data_db_id: `${opt[`_id`]}` ? `${opt[`_id`]}` : `${opt[`${ArrOfObjKyename}`]}`,
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: `${opt[`${ArrOfObjKyename}`]}`,
                        },
                      },
                    ],
                  };

                  dropdown.append(optionEle);
                });
              }
            }

            // if API response is a plain array of single elements
            else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
              console.log("###");
              dropdown.components("");

              apiData.forEach((opt) => {
                let optionEle = {
                  tagName: "option",
                  type: "default",
                  attributes: {
                    value: `${opt}`,
                  },

                  components: [
                    {
                      tagName: "p",
                      type: "text",
                      editable: true,

                      components: {
                        type: "textnode",
                        content: `${opt}`,
                      },
                    },
                  ],
                };

                dropdown.append(optionEle);
              });
            }

            // if API response is an object
            else if (
              !isArrayofObjects(apiData) &&
              typeof apiData === "object"
            ) {
              console.log("#@");
              // if API response to plot is a nested array-of-objects inside the main response object
              if (NestedKeyname && ArrOfObjKyename) {
                console.log("####");
                dropdown.components("");

                // find the array-of-objects value
                let data = findKeyValue(apiData, NestedKeyname);

                if (isArrayofObjects(data)) {
                  data.forEach((opt) => {
                    let optionEle = {
                      tagName: "option",
                      type: "default",
                      attributes: {
                        // value: `${opt[`${ArrOfObjKyename}`]}`,
                        value: JSON.stringify({
                          _id: opt['_id'],
                          ArrOfObjKyename: opt[`${ArrOfObjKyename}`],
                        }),
                      },

                      components: [
                        {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: `${opt[`${ArrOfObjKyename}`]}`,
                          },
                        },
                      ],
                    };

                    dropdown.append(optionEle);
                  });
                }
              } else if (NestedKeyname && !ArrOfObjKyename) {
                console.log("####@");
                dropdown.components("");

                // find the array value
                let data = findKeyValue(apiData, NestedKeyname);

                // if API response to plot is a nested plain array of single elements inside the main response object
                if (Array.isArray(data) && !isArrayofObjects(data)) {
                  console.log("#####");
                  data.forEach((opt) => {
                    let optionEle = {
                      tagName: "option",
                      type: "default",
                      attributes: {
                        value: `${opt}`,
                      },

                      components: [
                        {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: `${opt}`,
                          },
                        },
                      ],
                    };

                    dropdown.append(optionEle);
                  });
                }

                // if API response to plot are all key:value pairs of a plain object inside main object
                else if (
                  !Array.isArray(data) &&
                  !isArrayofObjects(data) &&
                  typeof data === "object"
                ) {
                  console.log("######");
                  Object.keys(data).forEach((key) => {
                    if (
                      !Array.isArray(data[`${key}`]) &&
                      typeof data[`${key}`] !== "object"
                    ) {
                      let optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          // value: `${data[`${key}`]}`,
                          value: JSON.stringify({
                            _id: data['_id'] ? data['_id'] : '',
                            key: data[`${key}`] ? data[`${key}`] : '',
                          }),
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${key}`,
                            },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    }
                  });
                }
              }

              // if API response to plot are all key:value pairs of the plain object
              else if (!NestedKeyname && !ArrOfObjKyename) {
                console.log("#######@");
                dropdown.components("");

                Object.keys(apiData).forEach((key) => {
                  if (
                    !Array.isArray(apiData[`${key}`]) &&
                    typeof apiData[`${key}`] !== "object"
                  ) {
                    let optionEle = {
                      tagName: "option",
                      type: "default",
                      attributes: {
                        // value: `${apiData[`${key}`]}`,
                        value: JSON.stringify({
                          _id: apiData['_id'] ? apiData['_id'] : '',
                          key: apiData[`${key}`] ? apiData[`${key}`] : '',
                        }),
                      },

                      components: [
                        {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: `${key}`,
                          },
                        },
                      ],
                    };

                    dropdown.append(optionEle);
                  }
                });
              }

              // if there is a 'message' in api response instead of data
              else if (Object.keys(apiData).includes("message")) {
                console.log("#@@");

                dropdown.components("");

                // initializing with a disabled first option
                let disabledOptionEle = {
                  tagName: "option",
                  attributes: {
                    disabled: true,
                    selected: true,
                  },

                  components: [
                    {
                      tagName: "p",
                      type: "text",
                      editable: true,

                      components: {
                        type: "textnode",
                        content: `No Values.`,
                        // content: `${apiData['message']}`,
                      },
                    },
                  ],
                };

                dropdown.append(disabledOptionEle);
              }
            }
          }

          // this.attributes.script.call(this.getEl(), this.props());
        },
      },

      view: {
        onRender({ el, model }) {
          console.log("el", el, el.tagName);
          if (el.getAttribute("data-gjs-type") === "input_select_with_search" && (el.tagName === "DIV" || el.tagName === "SPAN" || el.tagName === "FORM")) {
            console.log("rendering", el);
            // do api plotting only if manual plotting is not set
            if (model.get("SetOptionsManually") === "")
              model.plottingSelectDDTraitHandler();
          }
        },
      },
    });

    // searchable input_select dropdown
    editor.BlockManager.add("type-ahead-search-container", {
      label: "Form Type-Ahead Search",
      category: "Form-Control",
      select: true,
      media: '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8A1.5 1.5 0 0 1 11 1.5v2A1.5 1.5 0 0 1 9.5 5h-8A1.5 1.5 0 0 1 0 3.5zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5z"/><path d="m7.823 2.823-.396-.396A.25.25 0 0 1 7.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0M0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"/></svg>',
      content: { type: "type-ahead-search-container" },
      draggable: true,
    });

    // const typeAheadSearchScript = function (props) {
    //   console.log("typeAheadSearchScript", props, this);

    //   let container = this; // Ensure that `this` refers to the component instance

    //   // Use GrapesJS methods to find elements
    //   const $dropdownBtn = container.querySelector('.dropdown-btn'); // Use querySelector for single element
    //   const $dropdownText = $dropdownBtn.querySelector('p'); // Find the text inside the button
    //   const $dropdownContent = container.querySelector('.dropdown-content');
    //   const $dropdownDiv = container.querySelector('.dropdown'); // The div with class 'dropdown'
    //   const $searchInput = container.querySelector('.dropdown-search');

    //   if (!$dropdownBtn || !$dropdownContent || !$searchInput) {
    //       console.error("One or more elements not found");
    //       return; // Exit if elements are not found
    //   }

    //   // console.log("dropdownBtn, dropdownContent, searchInput", $dropdownBtn, $dropdownContent, $searchInput);

    //   // Set initial styles using JavaScript for dropdown options
    //   const options = $dropdownContent.querySelectorAll('div[data-value]');
    //   options.forEach(option => {
    //       option.style.padding = '10px';
    //       option.style.cursor = 'pointer';
    //       option.style.display = 'flex';
    //       option.style.alignItems = 'center';
    //       option.style.backgroundColor = 'white';
    //   });

    //   // Apply styles to checkboxes
    //   options.forEach(option => {
    //       const checkbox = option.querySelector('input[type="checkbox"]');
    //       if (checkbox) {
    //           checkbox.style.marginRight = '10px';
    //       }

    //       // Highlight on hover
    //       option.addEventListener('mouseenter', function() {
    //           this.style.backgroundColor = '#f1f1f1'; // Highlight on hover
    //       });
    //       option.addEventListener('mouseleave', function() {
    //           this.style.backgroundColor = 'white'; // Reset on mouse leave
    //       });
    //   });

    //   // Function to toggle the dropdown visibility
    //   const toggleDropdown = function() {
    //       console.log("click"); // Log to confirm button click
    //       // Toggle the display property
    //       if ($dropdownContent.style.display === 'block') {
    //           $dropdownContent.style.display = 'none'; // Hide dropdown
    //       } else {
    //           $dropdownContent.style.display = 'block'; // Show dropdown
    //       }
    //       console.log("Dropdown content style:", $dropdownContent.style.display); // Log style for debugging
    //   };

    //   // Show dropdown when button or text inside is clicked
    //   $dropdownBtn.addEventListener('click', toggleDropdown);
    //   $dropdownText.addEventListener('click', function(event) {
    //       event.stopPropagation(); // Prevent event bubbling to avoid conflict
    //       toggleDropdown();
    //   });

    //   let selectedOptions = [];

    //   // Function to update the 'data-select-value' attribute with the selected options
    //   const updateSelectedValuesAttribute = function() {
    //       $dropdownDiv.setAttribute('data-select-value', JSON.stringify(selectedOptions));
    //       // console.log('Updated data-select-value:', $dropdownDiv.getAttribute('data-select-value'));
    //   };

    //   options.forEach(option => {
    //     option.addEventListener('click', function(e) {
    //         // Prevent the dropdown from closing when clicking on the checkbox
    //         e.stopPropagation();

    //         const checkbox = this.querySelector('input[type="checkbox"]');
    //         const value = this.getAttribute('data-value'); // Get the 'data-value' of the option

    //         // Toggle the checkbox state
    //         checkbox.checked = !checkbox.checked;

    //         if (checkbox.checked) {
    //             // Add the selected 'data-value' to the array
    //             selectedOptions.push(value);
    //         } else {
    //             // Remove the deselected 'data-value' from the array
    //             selectedOptions = selectedOptions.filter(opt => opt !== value);
    //         }

    //         // Update the dropdown button text with selected labels
    //         const selectedLabels = Array.from(options)
    //             .filter(option => option.querySelector('input[type="checkbox"]').checked)
    //             .map(option => option.textContent.trim());

    //         $dropdownText.textContent = selectedLabels.length > 0 ? selectedLabels.join(', ') : 'Select options';

    //         // Update the 'data-select-value' attribute with the selected 'data-value' attributes
    //         updateSelectedValuesAttribute();
    //     });
    //   });

    //   // Improved filtering without breaking layout
    //   $searchInput.addEventListener('input', function() {
    //       const filter = this.value.toLowerCase();
    //       options.forEach(option => {
    //           const text = option.textContent.toLowerCase();
    //           if (text.includes(filter)) {
    //               option.style.visibility = 'visible'; // Keep visible
    //               option.style.height = 'auto'; // Ensure it takes up space
    //               option.style.pointerEvents = 'auto'; // Enable click
    //           } else {
    //               option.style.visibility = 'hidden'; // Hide but preserve layout
    //               option.style.height = '0'; // Collapse the space
    //               option.style.pointerEvents = 'none'; // Disable click
    //           }
    //       });
    //   });

    //   // Add hover effect for select-all and deselect-all buttons
    //   const selectAllButton = $dropdownContent.querySelector('.select-all-button');
    //   const deselectAllButton = $dropdownContent.querySelector('.deselect-all-button');

    //   // Function to apply ellipsis when the text exceeds certain size
    //   const applyEllipsisIfOverflow = function () {
    //       const heightLimit = 5; // Height limit in vh
    //       if ($dropdownBtn.scrollHeight > (window.innerHeight * heightLimit) / 100) {
    //           $dropdownText.style.whiteSpace = 'nowrap';
    //           $dropdownText.style.overflow = 'hidden';
    //           $dropdownText.style.textOverflow = 'ellipsis';
    //       } else {
    //           $dropdownText.style.whiteSpace = 'normal';
    //           $dropdownText.style.overflow = '';
    //           $dropdownText.style.textOverflow = '';
    //       }
    //   };

    //   if (selectAllButton) {
    //     selectAllButton.addEventListener('click', function () {
    //         // Select all options
    //         selectedOptions = [];
    //         options.forEach(option => {
    //             const checkbox = option.querySelector('input[type="checkbox"]');
    //             checkbox.checked = true; // Check all checkboxes
    //             selectedOptions.push(option.getAttribute('data-value')); // Add all 'data-value' attributes to the array
    //         });

    //         // Update the dropdown text and the data-select-value attribute
    //         $dropdownText.textContent = selectedOptions.join(', ');
    //         updateSelectedValuesAttribute();

    //         // Apply ellipsis if the text exceeds height/width constraints
    //         applyEllipsisIfOverflow();
    //     });
    //   }

    //   if (deselectAllButton) {
    //       deselectAllButton.addEventListener('click', function () {
    //           // Deselect all options
    //           selectedOptions = [];
    //           options.forEach(option => {
    //               const checkbox = option.querySelector('input[type="checkbox"]');
    //               checkbox.checked = false; // Uncheck all checkboxes
    //           });

    //           // Update the dropdown text and the data-select-value attribute
    //           $dropdownText.textContent = props.Title;
    //           updateSelectedValuesAttribute();

    //           // Reset ellipsis and text style
    //           applyEllipsisIfOverflow();
    //       });
    //   }

    //   if (selectAllButton) {
    //       selectAllButton.addEventListener('mouseover', function() {
    //           this.style.backgroundColor = 'lightgray';
    //       });
    //       selectAllButton.addEventListener('mouseleave', function() {
    //           this.style.backgroundColor = '';
    //       });
    //   }

    //   if (deselectAllButton) {
    //       deselectAllButton.addEventListener('mouseover', function() {
    //           this.style.backgroundColor = 'lightgray';
    //       });
    //       deselectAllButton.addEventListener('mouseleave', function() {
    //           this.style.backgroundColor = '';
    //       });
    //   }

    //   // Close dropdown if clicked outside
    //   document.addEventListener('click', function(e) {
    //       if (!$dropdownContent.contains(e.target) && e.target !== $dropdownBtn && e.target !== $dropdownText) {
    //           $dropdownContent.style.display = 'none'; // Hide dropdown
    //       }
    //   });
    // };

    const typeAheadSearchScript = function (props) {
      console.log("typeAheadSearchScript", props, this);

      let container = this; // Ensure that `this` refers to the component instance
      let idEl = container.id;
      const urlDownload1 = window.top.location.href;
      const isSubstringPresent = (urlDownload1.indexOf("/editor/") !== -1 && urlDownload1.indexOf("?projectId=") !== -1) || urlDownload1.indexOf("/large_preview/") !== -1 || urlDownload1.indexOf("/tab_preview/") !== -1 || urlDownload1.indexOf("/mobile_preview/") !== -1 || urlDownload1.indexOf("/fragment_editor/") !== -1;

      // Use GrapesJS methods to find elements
      const $dropdownBtn = container.querySelector('.dropdown-btn'); // Use querySelector for single element
      const $dropdownText = $dropdownBtn.querySelector('p'); // Find the text inside the button
      const $dropdownContent = container.querySelector('.dropdown-content');
      const $dropdownDiv = container.querySelector('.dropdown'); // The div with class 'dropdown'
      const $searchInput = container.querySelector('.dropdown-search');

      // helper function to check if a variable is an array-of-objects or not
      function isArrayofObjects(variable) {
        if (!Array.isArray(variable)) {
          return false;
        }
        for (const element of variable) {
          if (typeof element !== "object" || Array.isArray(element) || element === null) {
            return false;
          }
        }
        return true;
      }

      // Define the initLib function
      const initLib = function() {
        if (!$dropdownBtn || !$dropdownContent || !$searchInput) {
            console.error("One or more elements not found");
            return; // Exit if elements are not found
        }

        // Set initial styles using JavaScript for dropdown options
        const options = $dropdownContent.querySelectorAll('div[data-value]');
        options.forEach(option => {
            option.style.padding = '10px';
            option.style.cursor = 'pointer';
            option.style.display = 'flex';
            option.style.alignItems = 'center';
            option.style.backgroundColor = 'white';
        });

        // Apply styles to checkboxes
        options.forEach(option => {
            const checkbox = option.querySelector('input[type="checkbox"]');
            if (checkbox) {
                checkbox.style.marginRight = '10px';
            }

            // Highlight on hover
            option.onmouseenter = function() {
                this.style.backgroundColor = '#f1f1f1'; // Highlight on hover
            };
            option.onmouseleave = function() {
                this.style.backgroundColor = 'white'; // Reset on mouse leave
            };
        });

        // Function to toggle the dropdown visibility
        const toggleDropdown = function() {
            console.log("click"); // Log to confirm button click
            // Toggle the display property
            if ($dropdownContent.style.display === 'block') {
                $dropdownContent.style.display = 'none'; // Hide dropdown
            } else {
                $dropdownContent.style.display = 'block'; // Show dropdown
            }
            console.log("Dropdown content style:", $dropdownContent.style.display); // Log style for debugging
        };

        // Show dropdown when button or text inside is clicked
        $dropdownBtn.onclick = toggleDropdown;
        $dropdownText.onclick = function(event) {
            event.stopPropagation(); // Prevent event bubbling to avoid conflict
            toggleDropdown();
        };

        let selectedOptions = [];

        // Function to update the 'data-select-value' attribute with the selected options
        const updateSelectedValuesAttribute = function() {
            $dropdownDiv.setAttribute('data-select-value', JSON.stringify(selectedOptions));
        };

        // Check if preSelectedValues is an array of objects
        if (isArrayofObjects(props.preSelectedValues)) {
          console.log("isArrayofObjects(props.preSelectedValues)", isArrayofObjects(props.preSelectedValues));
          // Loop through the options
          options.forEach(option => {
            const value = option.getAttribute('data-value');
            if (value) {
              try {
                // JSON parse twice to get the object from the data-value attribute
                const parsedValue = JSON.parse(value);

                // Check if the _id exists in any object in props.preSelectedValues
                if (props.preSelectedValues.some(item => item._id === parsedValue._id)) {
                  const checkbox = option.querySelector('input[type="checkbox"]');
                  if (checkbox) {
                    checkbox.checked = true; // Mark the checkbox as checked
                    selectedOptions.push(value); // Add to selected options
                  }
                }
              } catch (error) {
                console.error('Error parsing data-value:', error);
              }
            }
          });

          // Update the dropdown text and the data-select-value attribute
          const selectedLabels = Array.from(options)
            .filter(option => option.querySelector('input[type="checkbox"]').checked)
            .map(option => option.textContent.trim());
          $dropdownText.textContent = selectedLabels.length > 0 ? selectedLabels.join(', ') : 'Select options';
          updateSelectedValuesAttribute();
        }

        options.forEach(option => {
            option.onclick = function(e) {
                // Prevent the dropdown from closing when clicking on the checkbox
                e.stopPropagation();

                const checkbox = this.querySelector('input[type="checkbox"]');
                const value = this.getAttribute('data-value'); // Get the 'data-value' of the option

                // Toggle the checkbox state
                checkbox.checked = !checkbox.checked;

                if (checkbox.checked) {
                    // Add the selected 'data-value' to the array
                    selectedOptions.push(value);
                } else {
                    // Remove the deselected 'data-value' from the array
                    selectedOptions = selectedOptions.filter(opt => opt !== value);
                }

                // Update the dropdown button text with selected labels
                const selectedLabels = Array.from(options)
                    .filter(option => option.querySelector('input[type="checkbox"]').checked)
                    .map(option => option.textContent.trim());

                $dropdownText.textContent = selectedLabels.length > 0 ? selectedLabels.join(', ') : 'Select options';

                // Update the 'data-select-value' attribute with the selected 'data-value' attributes
                updateSelectedValuesAttribute();
            };
        });

        // Improved filtering without breaking layout
        $searchInput.oninput = function() {
            const filter = this.value.toLowerCase();
            options.forEach(option => {
                const text = option.textContent.toLowerCase();
                if (text.includes(filter)) {
                    option.style.visibility = 'visible'; // Keep visible
                    option.style.height = 'auto'; // Ensure it takes up space
                    option.style.pointerEvents = 'auto'; // Enable click
                } else {
                    option.style.visibility = 'hidden'; // Hide but preserve layout
                    option.style.height = '0'; // Collapse the space
                    option.style.pointerEvents = 'none'; // Disable click
                }
            });
        };

        // Add hover effect for select-all and deselect-all buttons
        const selectAllButton = $dropdownContent.querySelector('.select-all-button');
        const deselectAllButton = $dropdownContent.querySelector('.deselect-all-button');

        // Function to apply ellipsis when the text exceeds certain size
        const applyEllipsisIfOverflow = function () {
            const heightLimit = 5; // Height limit in vh
            if ($dropdownBtn.scrollHeight > (window.innerHeight * heightLimit) / 100) {
                $dropdownText.style.whiteSpace = 'nowrap';
                $dropdownText.style.overflow = 'hidden';
                $dropdownText.style.textOverflow = 'ellipsis';
            } else {
                $dropdownText.style.whiteSpace = 'normal';
                $dropdownText.style.overflow = '';
                $dropdownText.style.textOverflow = '';
            }
        };

        if (selectAllButton) {
            selectAllButton.onclick = function () {
                // Select all options
                selectedOptions = [];
                options.forEach(option => {
                    const checkbox = option.querySelector('input[type="checkbox"]');
                    checkbox.checked = true; // Check all checkboxes
                    selectedOptions.push(option.getAttribute('data-value')); // Add all 'data-value' attributes to the array
                });

                // Update the dropdown text and the data-select-value attribute
                $dropdownText.textContent = selectedOptions.join(', ');
                updateSelectedValuesAttribute();

                // Apply ellipsis if the text exceeds height/width constraints
                applyEllipsisIfOverflow();
            };
        }

        if (deselectAllButton) {
            deselectAllButton.onclick = function () {
                // Deselect all options
                selectedOptions = [];
                options.forEach(option => {
                    const checkbox = option.querySelector('input[type="checkbox"]');
                    checkbox.checked = false; // Uncheck all checkboxes
                });

                // Update the dropdown text and the data-select-value attribute
                $dropdownText.textContent = props.Title;
                updateSelectedValuesAttribute();

                // Reset ellipsis and text style
                applyEllipsisIfOverflow();
            };
        }

        if (selectAllButton) {
            selectAllButton.onmouseenter = function() {
                this.style.backgroundColor = 'lightgray';
            };
            selectAllButton.onmouseleave = function() {
                this.style.backgroundColor = '';
            };
        }

        if (deselectAllButton) {
            deselectAllButton.onmouseenter = function() {
                this.style.backgroundColor = 'lightgray';
            };
            deselectAllButton.onmouseleave = function() {
                this.style.backgroundColor = '';
            };
        }

        // Close dropdown if clicked outside
        document.onclick = function(e) {
            if (!$dropdownContent.contains(e.target) && e.target !== $dropdownBtn && e.target !== $dropdownText) {
                $dropdownContent.style.display = 'none'; // Hide dropdown
            }
        };
      };

      async function plottingSelectDDTraitHandler() {
        let {
          CustomUrl,
          BaseUrl,
          APIPath,
          BearerToken,
          Username,
          Password,
          MoreHeaders,
          APIMethod,
          APIBody,
          ArrOfObjKyename,
          NestedKeyname,
          options,
        } = props;

        let apiData;
        let dropdown = container.getElementsByClassName("dropdown-content")[0];

        // to rename a variable because API var is getting used in many places in this block scope
        let API;
        if (APIPath) {
          API = APIPath;
        }

        // helper function to check if a variable is an array-of-objects or not
        function isArrayofObjects(variable) {
          // Check if the variable is an array
          if (!Array.isArray(variable)) {
            return false;
          }

          // Check if all elements in the array are objects
          for (const element of variable) {
            if (
              typeof element !== "object" ||
              Array.isArray(element) ||
              element === null
            ) {
              return false;
            }
          }
          return true;
        }

        // helper function to get value of a key in nested object
        function findKeyValue(obj, key) {
          if (obj.hasOwnProperty(key)) {
            // If the current object has the key, return its value
            return obj[key];
          }

          for (var prop in obj) {
            if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
              // If the current property is an object, recursively search within it
              var result = findKeyValue(obj[prop], key);
              if (result !== undefined) {
                return result;
              }
            }
          }
          // Key not found in the object
          return undefined;
        }

        // -------- Getting API Data (GET method)

        // if CustomUrl is provided, use only that
        if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "GET" ) {
          console.log("CustomUrl", CustomUrl);
          // if nothing is provided for auth
          if (!BearerToken && !Username && !Password) {
            // add extra headers, if needed
            let config = {
              headers: {},
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${CustomUrl}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a bearer token is provided but not api_key or usr+pwd
          else if (BearerToken && !Username && !Password) {
            // add extra headers, if needed
            let config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${BearerToken}`,
              },
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${CustomUrl}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a bearer token and api_key not provided but usr+pwd are provided
          else if (!BearerToken && Username && Password) {
            // add extra headers, if needed
            let config = {
              headers: {
                Authorization: "Basic " + btoa(Username + ":" + Password),
              },
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${CustomUrl}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }
        }

        // if base url is the choice and provided
        else if (BaseUrl && API && !CustomUrl && APIMethod === "GET") {
          // concatenating base url and its remaining part
          let url, baseUrlBearerToken;
          if (BaseUrl === "baseUrl1") {
            url = options.baseUrl1 + API;
            baseUrlBearerToken = options.baseUrl1BearerToken;
          } else if (BaseUrl === "baseUrl2") {
            url = options.baseUrl2 + API;
            baseUrlBearerToken = options.baseUrl2BearerToken;
          } else if (BaseUrl === "baseUrl3") {
            url = options.baseUrl3 + API;
            baseUrlBearerToken = options.baseUrl3BearerToken;
          } else if (BaseUrl === "baseUrl4") {
            url = options.baseUrl4 + API;
            baseUrlBearerToken = options.baseUrl4BearerToken;
          } else if (BaseUrl === "baseUrl5") {
            url = options.baseUrl5 + API;
            baseUrlBearerToken = options.baseUrl5BearerToken;
          }
          console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

          // if nothing is provided for auth
          if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
            // add extra headers, if needed
            let config = {
              headers: {},
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${url}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a bearer token is provided but not usr+pwd
          else if (BearerToken && !Username && !Password) {
            // add extra headers, if needed
            let config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${BearerToken}`,
              },
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${url}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
          else if (
            baseUrlBearerToken &&
            !BearerToken &&
            !Username &&
            !Password
          ) {
            // add extra headers, if needed
            let config = {
              headers: {
                Authorization: `Bearer ${baseUrlBearerToken}`,
                "Content-Type": "application/json",
              },
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config@:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${url}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a bearer token is not provided but usr+pwd are provided
          else if (!BearerToken && Username && Password) {
            // add extra headers, if needed
            let config = {
              headers: {
                Authorization: "Basic " + btoa(Username + ":" + Password),
              },
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${url}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }
        }

        // -------- Getting API Data (POST method)

        // if CustomUrl is provided, use only that
        else if (
          CustomUrl &&
          (!BaseUrl || BaseUrl === "null") &&
          APIMethod === "POST"
        ) {
          console.log("CustomUrl", CustomUrl);
          // if nothing is provided for auth
          if (!BearerToken && !Username && !Password) {
            // add extra headers, if needed
            let config = {
              method: "POST",
              headers: {},
              body:
                typeof APIBody === "object"
                  ? JSON.stringify(APIBody)
                  : APIBody,
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${CustomUrl}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a bearer token is provided but not api_key or usr+pwd
          else if (BearerToken && !Username && !Password) {
            // add extra headers, if needed
            let config = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${BearerToken}`,
              },
              body:
                typeof APIBody === "object"
                  ? JSON.stringify(APIBody)
                  : APIBody,
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${CustomUrl}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a bearer token and api_key not provided but usr+pwd are provided
          else if (!BearerToken && Username && Password) {
            // add extra headers, if needed
            let config = {
              method: "POST",
              headers: {
                Authorization: "Basic " + btoa(Username + ":" + Password),
              },
              body:
                typeof APIBody === "object"
                  ? JSON.stringify(APIBody)
                  : APIBody,
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${CustomUrl}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }
        }

        // if base url is the choice and provided
        else if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
          // concatenating base url and its remaining part
          let url, baseUrlBearerToken;
          if (BaseUrl === "baseUrl1") {
            url = options.baseUrl1 + API;
            baseUrlBearerToken = options.baseUrl1BearerToken;
          } else if (BaseUrl === "baseUrl2") {
            url = options.baseUrl2 + API;
            baseUrlBearerToken = options.baseUrl2BearerToken;
          } else if (BaseUrl === "baseUrl3") {
            url = options.baseUrl3 + API;
            baseUrlBearerToken = options.baseUrl3BearerToken;
          } else if (BaseUrl === "baseUrl4") {
            url = options.baseUrl4 + API;
            baseUrlBearerToken = options.baseUrl4BearerToken;
          } else if (BaseUrl === "baseUrl5") {
            url = options.baseUrl5 + API;
            baseUrlBearerToken = options.baseUrl5BearerToken;
          }
          console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

          // if nothing is provided for auth
          if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
            // add extra headers, if needed
            let config = {
              method: "POST",
              headers: {},
              body:
                typeof APIBody === "object"
                  ? JSON.stringify(APIBody)
                  : APIBody,
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${url}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a bearer token is provided but not usr+pwd
          else if (BearerToken && !Username && !Password) {
            // add extra headers, if needed
            let config = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${BearerToken}`,
              },
              body:
                typeof APIBody === "object"
                  ? JSON.stringify(APIBody)
                  : APIBody,
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${url}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
          else if (
            baseUrlBearerToken &&
            !BearerToken &&
            !Username &&
            !Password
          ) {
            // add extra headers, if needed
            let config = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${baseUrlBearerToken}`,
              },
              body:
                typeof APIBody === "object"
                  ? JSON.stringify(APIBody)
                  : APIBody,
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config@:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${url}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }

          // if a bearer token is not provided but usr+pwd are provided
          else if (!BearerToken && Username && Password) {
            // add extra headers, if needed
            let config = {
              method: "POST",
              headers: {
                Authorization: "Basic " + btoa(Username + ":" + Password),
              },
              body:
                typeof APIBody === "object"
                  ? JSON.stringify(APIBody)
                  : APIBody,
            };

            if (MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            try {
              // GET call to get the data
              let response = await fetch(`${url}`, config);
              let responseData = await response.json();
              apiData = responseData;
            } catch (e) {
              // statements
              console.log(e);
            }
          }
        }

        console.log("apiData:", apiData);

        // -------- Populating API Data, into the dropdown list
        if (apiData !== undefined) {
          console.log("#");
          // if API response is directly an array-of-objects
          if (isArrayofObjects(apiData)) {
            if (ArrOfObjKyename) {
              console.log("##");
              // remove all components in dropdown except the search input
              dropdown.innerHTML = "";
              dropdown.innerHTML = `<input style="width: 100%; padding: 8px; border: none; border-bottom: 1px solid #ddd; box-sizing": border-box;" type="text" class="dropdown-search" placeholder="Search...">`;

              // add new options
              apiData.forEach((opt) => {
                let optionEle = document.createElement("div");
                optionEle.setAttribute("data-value", `${JSON.stringify({_id: opt['_id'], ArrOfObjKyename: opt[`${ArrOfObjKyename}`]})}`);
                optionEle.setAttribute("data_db_id", `${opt[`_id`]}` ? `${opt[`_id`]}` : `${opt[`${ArrOfObjKyename}`]}`);

                let checkbox = document.createElement("input");
                checkbox.setAttribute("type", "checkbox");
                optionEle.appendChild(checkbox);

                optionEle.innerHTML += opt[`${ArrOfObjKyename}`];

                dropdown.appendChild(optionEle);
              });
            }
          }

          // if API response is a plain array of single elements
          else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
            console.log("###");
            // remove all components in dropdown except the search input
            dropdown.innerHTML = "";
            dropdown.innerHTML = `<input style="width: 100%; padding: 8px; border: none; border-bottom: 1px solid #ddd; box-sizing": border-box;" type="text" class="dropdown-search" placeholder="Search...">`;

            apiData.forEach((opt) => {
              let optionEle = document.createElement("div");
                optionEle.setAttribute("data-value", `${opt}`);

                let checkbox = document.createElement("input");
                checkbox.setAttribute("type", "checkbox");
                optionEle.appendChild(checkbox);

                optionEle.innerHTML += opt;

                dropdown.appendChild(optionEle);
            });
          }

          // if API response is an object
          else if (!isArrayofObjects(apiData) && typeof apiData === "object") {
            console.log("#@");
            // if API response to plot is a nested array-of-objects inside the main response object
            if (NestedKeyname && ArrOfObjKyename) {
              console.log("####");
              // remove all components in dropdown except the search input
              dropdown.innerHTML = "";
              dropdown.innerHTML = `<input style="width: 100%; padding: 8px; border: none; border-bottom: 1px solid #ddd; box-sizing": border-box;" type="text" class="dropdown-search" placeholder="Search...">`;

              // find the array-of-objects value
              let data = findKeyValue(apiData, NestedKeyname);

              if (isArrayofObjects(data)) {
                data.forEach((opt) => {
                  let optionEle = document.createElement("div");
                  optionEle.setAttribute("data-value", `${JSON.stringify({_id: opt['_id'], ArrOfObjKyename: opt[`${ArrOfObjKyename}`]})}`);
                  optionEle.setAttribute("data_db_id", `${opt[`_id`]}` ? `${opt[`_id`]}` : `${opt[`${ArrOfObjKyename}`]}`);

                  let checkbox = document.createElement("input");
                  checkbox.setAttribute("type", "checkbox");
                  optionEle.appendChild(checkbox);

                  optionEle.innerHTML += opt[`${ArrOfObjKyename}`];

                  dropdown.appendChild(optionEle);
                });
              }
            } else if (NestedKeyname && !ArrOfObjKyename) {
              console.log("####@");
              // remove all components in dropdown except the search input
              dropdown.innerHTML = "";
              dropdown.innerHTML = `<input style="width: 100%; padding: 8px; border: none; border-bottom: 1px solid #ddd; box-sizing": border-box;" type="text" class="dropdown-search" placeholder="Search...">`;

              // find the array value
              let data = findKeyValue(apiData, NestedKeyname);

              // if API response to plot is a nested plain array of single elements inside the main response object
              if (Array.isArray(data) && !isArrayofObjects(data)) {
                console.log("#####");
                data.forEach((opt) => {
                  let optionEle = document.createElement("div");
                  optionEle.setAttribute("data-value", `${opt}`);

                  let checkbox = document.createElement("input");
                  checkbox.setAttribute("type", "checkbox");
                  optionEle.appendChild(checkbox);

                  optionEle.innerHTML += opt;

                  dropdown.appendChild(optionEle);
                });
              }

              // if API response to plot are all key:value pairs of a plain object inside main object
              else if (!Array.isArray(data) && !isArrayofObjects(data) && typeof data === "object") {
                console.log("######");
                Object.keys(data).forEach((key) => {
                  if (!Array.isArray(data[`${key}`]) && typeof data[`${key}`] !== "object") {
                    let optionEle = document.createElement("div");
                    optionEle.setAttribute("data-value", `${JSON.stringify({_id: data['_id'] ? data['_id'] : '', key: data[`${key}`] ? data[`${key}`] : ''})}`);

                    let checkbox = document.createElement("input");
                    checkbox.setAttribute("type", "checkbox");
                    optionEle.appendChild(checkbox);

                    optionEle.innerHTML += key;

                    dropdown.appendChild(optionEle);
                  }
                });
              }
            }

            // if API response to plot are all key:value pairs of the plain object
            else if (!NestedKeyname && !ArrOfObjKyename) {
              console.log("#######@");
              // remove all components in dropdown except the search input
              dropdown.innerHTML = "";
              dropdown.innerHTML = `<input style="width: 100%; padding: 8px; border: none; border-bottom: 1px solid #ddd; box-sizing": border-box;" type="text" class="dropdown-search" placeholder="Search...">`;

              Object.keys(apiData).forEach((key) => {
                if (!Array.isArray(apiData[`${key}`]) && typeof apiData[`${key}`] !== "object") {
                  let optionEle = document.createElement("div");
                  optionEle.setAttribute("data-value", `${JSON.stringify({_id: apiData['_id'] ? apiData['_id'] : '', key: apiData[`${key}`] ? apiData[`${key}`] : ''})}`);

                  let checkbox = document.createElement("input");
                  checkbox.setAttribute("type", "checkbox");
                  optionEle.appendChild(checkbox);

                  optionEle.innerHTML += key;

                  dropdown.appendChild(optionEle);
                }
              });
            }

            // if there is a 'message' in api response instead of data
            else if (Object.keys(apiData).includes("message")) {
              console.log("#@@");
              // remove all components in dropdown except the search input
              dropdown.innerHTML = "";
              dropdown.innerHTML = `<input style="width: 100%; padding: 8px; border: none; border-bottom: 1px solid #ddd; box-sizing": border-box;" type="text" class="dropdown-search" placeholder="Search...">`;

              // initializing with a disabled first option
              let disabledOptionEle = document.createElement("div");
              disabledOptionEle.setAttribute("data-value", ``);

              let checkbox = document.createElement("input");
              checkbox.setAttribute("type", "checkbox");
              checkbox.style.pointerEvents = "none";
              disabledOptionEle.appendChild(checkbox);

              disabledOptionEle.innerHTML += "No Values.";

              dropdown.appendChild(disabledOptionEle);
            }
          }
        }

        initLib();
      }

      function titleTraitHandler() {
        let { Title } = props;
        let button = container.getElementsByClassName("dropdown-btn")[0];
        let originalTitle = button.textContent;
        console.log("originalTitle", originalTitle);

        // if Title is provided then add it, else remove it
        if (Title && button) {
          button.textContent = Title;
        } else {
          button.textContent = originalTitle;
        }

        // Call the initLib function
        initLib();
      }

      function liveSearchTraitHandler() {
        let { LiveSearch } = props;
        let dropdown = container.getElementsByClassName("dropdown-content")[0];
        let searchInput = container.getElementsByClassName("dropdown-search")[0];

        // if live search is off, then remove existing one, else add one
        if (!LiveSearch && searchInput) {
          searchInput.remove();
        } else {
          let inputEle = document.createElement("input");
          inputEle.setAttribute("type", "text");
          inputEle.setAttribute("class", "dropdown-search");
          inputEle.setAttribute("placeholder", "Search options here...");
          
          inputEle.style.width = "100%";
          inputEle.style.padding = "8px";
          inputEle.style.border = "none";
          inputEle.style.borderBottom = "1px solid #ddd";
          inputEle.style.boxSizing = "border-box";

          dropdown.insertBefore(inputEle, dropdown.firstChild);
        }

        // Call the initLib function
        initLib();
      }

      function disabledTraitHandler() {
        let { Disabled } = props;
        let dropdown = container.getElementsByClassName("dropdown")[0];

        if (Disabled && dropdown) {
          Object.assign(dropdown.style, {
            position: "relative",
            display: "inline-block",
            width: "200px",
            pointerEvents: "none",  // Use camelCase for CSS properties
            color: "lightgray",
            backgroundColor: "lightgray",  // Use camelCase for background-color
          });
        } else {
          Object.assign(dropdown.style, {
            position: "relative",
            display: "inline-block",
            width: "200px",
            pointerEvents: "",  // Reset pointer events
            color: "",          // Reset color
            backgroundColor: "", // Reset background-color
          });
        }

        // Call the initLib function
        initLib();
      }

      function selectDeselectAllOptionsTraitHandler() {
        let { SelectDeselectAllOptions } = props;
        let dropdown = container.getElementsByClassName("dropdown-content")[0];

        // if SetOptionsManually is provided then add new options, else add default options
        if (SelectDeselectAllOptions && dropdown) {
          let buttonCont = document.createElement("div");
          buttonCont.setAttribute("data-gjs-type", "select-deselect-all-options-buttons");
          buttonCont.setAttribute("class", "select-deselect-all-options-buttons");
          Object.assign(buttonCont.style, {
            width: "100%",
            border: "1px solid lightgray",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0",
          });

          let selectAllBtn = document.createElement("button");
          selectAllBtn.setAttribute("data-gjs-type", "select-all-button");
          selectAllBtn.setAttribute("class", "select-all-button");
          selectAllBtn.textContent = "Select all";
          Object.assign(selectAllBtn.style, {
            width: "50%",
            padding: "2%",
            borderRight: "1px solid lightgray",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0",
          });

          buttonCont.appendChild(selectAllBtn);

          let deSelectAllBtn = document.createElement("button");
          deSelectAllBtn.setAttribute("data-gjs-type", "deselect-all-button");
          deSelectAllBtn.setAttribute("class", "deselect-all-button");
          deSelectAllBtn.textContent = "Deselect all";
          Object.assign(deSelectAllBtn.style, {
            width: "50%",
            padding: "2%",
            borderRight: "1px solid lightgray",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0",
          });

          buttonCont.appendChild(deSelectAllBtn);

          dropdown.insertBefore(buttonCont, dropdown.firstChild);
        } else {
          // else remove these buttons if they already exists or dont add new ones
          if (dropdown) {
            let buttonsExistsAlready = dropdown.getElementsByClassName("select-deselect-all-options-buttons")[0];
            if (buttonsExistsAlready) {
              buttonsExistsAlready.remove();
            }
          }
        }

        // Call the initLib function
        initLib();
      }

      // Call the initLib function
      initLib();

      // to update table props as soon as window is updated and then trigger rendering
      if (!isSubstringPresent) {
        setInterval(function () {
          Object.keys(props).forEach(function (key) {
            if (window[`${key}${idEl}`] !== undefined || window[`${key}${idEl}`] !== null) {
              if(props[key] === window[`${key}${idEl}`]){
                // console.log('');
              }else{
                props[key] = window[`${key}${idEl}`];
                plottingSelectDDTraitHandler();
                titleTraitHandler();
                liveSearchTraitHandler();
                disabledTraitHandler();
                selectDeselectAllOptionsTraitHandler();
              }
            }
          });
        }, 500);
      }
    };

    editor.DomComponents.addType("type-ahead-search-container", {
      model: {
        defaults: {
          tagName: "div",
          type: "type-ahead-search-container",
          attributes: {
            class: "type-ahead-search-container",
            "data-child-search-select-id": "",
          },
          script: typeAheadSearchScript,

          // traits
          LiveSearch: true,
          Title: "Select options",
          SetOptionsManually: "",
          ButtonStyle: "default",

          CustomUrl: "",
          BaseUrl: "",
          APIPath: "",
          BearerToken: "",
          Username: "",
          Password: "",
          MoreHeaders: "",
          APIMethod: "GET",
          APIBody: "",
          ArrOfObjKyename: "",
          NestedKeyname: "",

          Disabled: false,
          SelectDeselectAllOptions: false,

          preSelectedValues: [],

          options: opts,

          projectId: localStorage.getItem("project_id") ? localStorage.getItem("project_id") : "",

          traits: [
            {
              type: "checkbox",
              name: "LiveSearch",
              label: "Live Search",
              changeProp: true,
            },
            {
              type: "text",
              name: "Title",
              label: "Title Text",
              placeholder: "Choose one of the following...",
              changeProp: true,
            },
            {
              type: "select",
              name: "ButtonStyle",
              label: "Button Style",
              options: [
                { id: "default", value: "default", name: "Default" },
                { id: "primary", value: "primary", name: "Primary" },
                { id: "info", value: "info", name: "Info" },
                { id: "success", value: "success", name: "Success" },
                { id: "warning", value: "warning", name: "Warning" },
                { id: "danger", value: "danger", name: "Danger" },
              ],
              changeProp: true,
            },
            {
              type: "text",
              name: "SetOptionsManually",
              label: "Set Options Manually",
              placeholder: "Option 1,Option 2,Option 3",
              changeProp: true,
            },
            {
              type: "text",
              name: "CustomUrl",
              label: "Custom URL",
              changeProp: true,
            },
            {
              type: "select",
              name: "BaseUrl",
              label: "Base URL",
              options: [
                { id: "baseUrl1", name: "Base URL #1" },
                { id: "baseUrl2", name: "Base URL #2" },
                { id: "baseUrl3", name: "Base URL #3" },
                { id: "baseUrl4", name: "Base URL #4" },
                { id: "baseUrl5", name: "Base URL #5" },
                { id: "null", name: "No Base URL" },
              ],
              changeProp: true,
            },
            {
              type: "text",
              name: "APIPath",
              label: "API Path",
              placeholder: "Don't start with '/'",
              changeProp: true,
            },
            {
              type: "text",
              name: "BearerToken",
              label: "Bearer Token",
              placeholder: "No bearer token provided",
              changeProp: true,
            },
            {
              type: "text",
              name: "Username",
              label: "Username",
              changeProp: true,
            },
            {
              type: "text",
              name: "Password",
              label: "Password",
              changeProp: true,
            },
            {
              type: "text",
              name: "MoreHeaders",
              label: "More Headers",
              placeholder: "k1:v1,k2:v2,k3:v3,...",
              changeProp: true,
            },
            {
              type: "select",
              name: "APIMethod",
              label: "API Method",
              options: [
                { id: "GET", name: "Get" },
                { id: "POST", name: "Post" },
              ],
              changeProp: true,
            },
            {
              type: "text",
              name: "APIBody",
              label: "API Body",
              placeholder: "Json format",
              changeProp: true,
            },
            {
              type: "text",
              name: "ArrOfObjKyename",
              label: "Keyname From Array Of Object",
              placeholder: "[ {k1:v1}, {k1:v2} ]",
              changeProp: true,
            },
            {
              type: "text",
              name: "NestedKeyname",
              label: "Keyname For Nested Structure",
              placeholder: "{ k1: [{...}, {...}] }",
              changeProp: true,
            },
            {
              type: "checkbox",
              name: "Disabled",
              label: "Disabled",
              changeProp: true,
            },
            {
              type: "checkbox",
              name: "SelectDeselectAllOptions",
              label: "Select/Deselect All Options Buttons",
              changeProp: true,
            },
          ],

          changeProp: true,

          "script-props": [
            "LiveSearch",
            "Title",
            "ButtonStyle",
            "SetOptionsManually",
            "CustomUrl",
            "BaseUrl",
            "APIPath",
            "BearerToken",
            "Username",
            "Password",
            "MoreHeaders",
            "APIMethod",
            "APIBody",
            "ArrOfObjKyename",
            "NestedKeyname",
            "Multiple",
            "Disabled",
            "Size",
            "SelectDeselectAllOptions",
            "preSelectedValues",
            "options",
            "projectId",
          ],

          components: [
            {
              tagName: "div",
              type: "dropdown",
              attributes: {
                class: "dropdown",
              },

              "style-default": {
                position: "relative",
                display: "inline-block",
                width: "200px",
              },

              style: {
                position: "relative",
                display: "inline-block",
                width: "200px",
              },

              components: [
                {
                  tagName: "button",
                  type: "dropdown-btn",
                  attributes: {
                    class: "dropdown-btn",
                  },

                  "style-default": {
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    "border-radius": "5px",
                    "background-color": "white",
                    cursor: "pointer",
                    "text-align": "left",
                  },

                  style: {
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    "border-radius": "5px",
                    "background-color": "white",
                    cursor: "pointer",
                    "text-align": "left",
                  },

                  components: {
                    tagName: "p",
                    type: "text",
                    editable: true,

                    components: {
                      type: "textnode",
                      content: "Select options",
                    },

                    "style-default": {
                      margin: "auto",
                      "text-align": "center",
                    },

                    style: {
                      margin: "auto",
                      "text-align": "center",
                    },
                  },
                }, {
                  tagName: "div",
                  type: "dropdown-content",
                  attributes: {
                    class: "dropdown-content",
                  },

                  "style-default": {
                    display: "none",
                    position: "absolute",
                    "background-color": "white",
                    "box-shadow": "0px 8px 16px rgba(0,0,0,0.1)",
                    width: "100%",
                    "max-height": "200px",
                    "overflow-y": "auto",
                    "z-index": "1",
                    border: "1px solid #ccc",
                    "border-radius": "5px",
                  },

                  style: {
                    display: "none",
                    position: "absolute",
                    "background-color": "white",
                    "box-shadow": "0px 8px 16px rgba(0,0,0,0.1)",
                    width: "100%",
                    "max-height": "200px",
                    "overflow-y": "auto",
                    "z-index": "1",
                    border: "1px solid #ccc",
                    "border-radius": "5px",
                  },

                  components: [
                    {
                      tagName: "input",
                      type: "dropdown-search",
                      attributes: {
                        type: "text",
                        class: "dropdown-search",
                        placeholder: "Search options here...",
                      },

                      "style-default": {
                        width: "100%",
                        padding: "8px",
                        border: "none",
                        "border-bottom": "1px solid #ddd",
                        "box-sizing": "border-box",
                      },

                      style: {
                        width: "100%",
                        padding: "8px",
                        border: "none",
                        "border-bottom": "1px solid #ddd",
                        "box-sizing": "border-box",
                      },
                    }, {
                      tagName: "div",
                      type: "dropdown-option",
                      attributes: {
                        "data-value": "option1",
                      },

                      components: [
                        {
                          tagName: "input",
                          type: "dropdown-checkbox",
                          attributes: {
                            type: "checkbox",
                          },
                        }, {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: "Option 1",
                          },

                          "style-default": {
                            margin: "auto",
                            "text-align": "center",
                          },

                          style: {
                            margin: "auto",
                            "text-align": "center",
                          },
                        },
                      ],
                    }, {
                      tagName: "div",
                      type: "dropdown-option",
                      attributes: {
                        "data-value": "option2",
                      },

                      components: [
                        {
                          tagName: "input",
                          type: "dropdown-checkbox",
                          attributes: {
                            type: "checkbox",
                          },
                        }, {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: "Option 2",
                          },

                          "style-default": {
                            margin: "auto",
                            "text-align": "center",
                          },

                          style: {
                            margin: "auto",
                            "text-align": "center",
                          },
                        },
                      ],
                    }, {
                      tagName: "div",
                      type: "dropdown-option",
                      attributes: {
                        "data-value": "option3",
                      },

                      components: [
                        {
                          tagName: "input",
                          type: "dropdown-checkbox",
                          attributes: {
                            type: "checkbox",
                          },
                        }, {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: "Option 3",
                          },

                          "style-default": {
                            margin: "auto",
                            "text-align": "center",
                          },

                          style: {
                            margin: "auto",
                            "text-align": "center",
                          },
                        },
                      ],
                    }, {
                      tagName: "div",
                      type: "dropdown-option",
                      attributes: {
                        "data-value": "option4",
                      },

                      components: [
                        {
                          tagName: "input",
                          type: "dropdown-checkbox",
                          attributes: {
                            type: "checkbox",
                          },
                        }, {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: "Option 4",
                          },

                          "style-default": {
                            margin: "auto",
                            "text-align": "center",
                          },

                          style: {
                            margin: "auto",
                            "text-align": "center",
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        init() {
          this.on("change:LiveSearch", this.liveSearchTraitHandler);
          this.on("change:Title", this.titleTraitHandler);
          this.on("change:ButtonStyle", this.buttonStyleTraitHandler);
          this.on("change:SetOptionsManually", this.setOptionsManuallyTraitHandler );
          this.on("change:Disabled", this.disabledTraitHandler);
          this.on("change:SelectDeselectAllOptions", this.selectDeselectAllOptionsTraitHandler );

          this.on("change:CustomUrl", this.plottingSelectDDTraitHandler);
          this.on("change:BaseUrl", this.plottingSelectDDTraitHandler);
          this.on("change:APIPath", this.plottingSelectDDTraitHandler);
          this.on("change:BearerToken", this.plottingSelectDDTraitHandler);
          this.on("change:Username", this.plottingSelectDDTraitHandler);
          this.on("change:Password", this.plottingSelectDDTraitHandler);
          this.on("change:MoreHeaders", this.plottingSelectDDTraitHandler);
          this.on("change:APIBody", this.plottingSelectDDTraitHandler);
          this.on("change:CustomUrl", this.plottingSelectDDTraitHandler);
          this.on("change:ArrOfObjKyename", this.plottingSelectDDTraitHandler);
          this.on("change:NestedKeyname", this.plottingSelectDDTraitHandler);
        },

        titleTraitHandler() {
          let { Title } = this.props();
          let container = this;
          let button = container.find(".dropdown-btn")[0];
          let originalTitle = button.find("p")[0].getLastChild().get("content");

          // if Title is provided then add it, else remove it
          if (Title && button) {
            button.getLastChild().getLastChild().set("content", `${Title}`);
          } else {
            button.getLastChild().getLastChild().set("content", `${originalTitle}`);
          }

          // to refresh the view after the title has been updated in the UI
          this.view.render();
        },

        setOptionsManuallyTraitHandler() {
          let { SetOptionsManually } = this.props();
          let container = this;
          let dropdown = container.find(".dropdown-content")[0];

          // if SetOptionsManually is provided then add new options, else add default options
          if (SetOptionsManually) {
            // remove all components in dropdown except the search input
            dropdown.components("");
            dropdown.append({
              tagName: "input",
              type: "dropdown-search",
              attributes: {
                type: "text",
                class: "dropdown-search",
                placeholder: "Search options here...",
              },

              "style-default": {
                width: "100%",
                padding: "8px",
                border: "none",
                "border-bottom": "1px solid #ddd",
                "box-sizing": "border-box",
              },

              style: {
                width: "100%",
                padding: "8px",
                border: "none",
                "border-bottom": "1px solid #ddd",
                "box-sizing": "border-box",
              },
            });

            let options = SetOptionsManually.split(",");
            options.forEach((opt, idx) => {
              let newOption = {
                tagName: "div",
                type: "dropdown-option",
                attributes: {
                  "data-value": `${opt}`,
                  "idx": idx,
                },

                components: [
                  {
                    tagName: "input",
                    type: "dropdown-checkbox",
                    attributes: {
                      type: "checkbox",
                    },
                  }, {
                    tagName: "p",
                    type: "text",
                    editable: true,

                    components: {
                      type: "textnode",
                      content: `${opt}`,
                    },

                    "style-default": {
                      margin: "auto",
                      "text-align": "center",
                    },

                    style: {
                      margin: "auto",
                      "text-align": "center",
                    },
                  },
                ],
              };

              dropdown.append(newOption);
            });
          }
          // else {
          //   dropdown.components("");
          //   dropdown.append({
          //     tagName: "input",
          //     type: "dropdown-search",
          //     attributes: {
          //       type: "text",
          //       class: "dropdown-search",
          //       placeholder: "Search options here...",
          //     },

          //     "style-default": {
          //       width: "100%",
          //       padding: "8px",
          //       border: "none",
          //       "border-bottom": "1px solid #ddd",
          //       "box-sizing": "border-box",
          //     },

          //     style: {
          //       width: "100%",
          //       padding: "8px",
          //       border: "none",
          //       "border-bottom": "1px solid #ddd",
          //       "box-sizing": "border-box",
          //     },
          //   });

          //   for (let opt = 1; opt <= 4; opt++) {
          //     let defaultOption = {
          //       tagName: "div",
          //       type: "dropdown-option",
          //       attributes: {
          //         "data-value": `${opt}`,
          //       },

          //       components: [
          //         {
          //           tagName: "input",
          //           type: "dropdown-checkbox",
          //           attributes: {
          //             type: "checkbox",
          //           },
          //         }, {
          //           tagName: "p",
          //           type: "text",
          //           editable: true,

          //           components: {
          //             type: "textnode",
          //             content: `Option ${opt}`,
          //           },

          //           "style-default": {
          //             margin: "auto",
          //             "text-align": "center",
          //           },

          //           style: {
          //             margin: "auto",
          //             "text-align": "center",
          //           },
          //         },
          //       ],
          //     };

          //     dropdown.append(defaultOption);
          //   }
          // }

          // this.attributes.script.call(this.getEl(), this.props());
          this.view.render();
        },

        liveSearchTraitHandler() {
          let { LiveSearch } = this.props();
          let container = this;
          let dropdown = container.find(".dropdown-content")[0];
          let searchInput = container.find(".dropdown-search")[0];

          // if live search is off, then remove existing one, else add one
          if (!LiveSearch && searchInput) {
            searchInput.remove();
          } else {
            dropdown.append({
              tagName: "input",
              type: "dropdown-search",
              attributes: {
                type: "text",
                class: "dropdown-search",
                placeholder: "Search options here...",
              },

              "style-default": {
                width: "100%",
                padding: "8px",
                border: "none",
                "border-bottom": "1px solid #ddd",
                "box-sizing": "border-box",
              },

              style: {
                width: "100%",
                padding: "8px",
                border: "none",
                "border-bottom": "1px solid #ddd",
                "box-sizing": "border-box",
              },
            }, {at: 0});
          }

          // this.attributes.script.call(this.getEl(), this.props());
          this.view.render();
        },

        disabledTraitHandler() {
          let { Disabled } = this.props();
          let container = this;
          let dropdown = container.find(".dropdown")[0];

          if (Disabled && dropdown) {
            dropdown.setStyle({
              position: "relative",
              display: "inline-block",
              width: "200px",
              "pointer-events": "none",
              "color": "lightgray",
              "background-color": "lightgray",
            });
          } else {
            dropdown.setStyle({
              position: "relative",
              display: "inline-block",
              width: "200px",
            });
          }

          // this.attributes.script.call(this.getEl(), this.props());
          this.view.render();
        },

        selectDeselectAllOptionsTraitHandler() {
          let { SelectDeselectAllOptions } = this.props();
          let container = this;
          let dropdown = container.find(".dropdown-content")[0];

          // if SetOptionsManually is provided then add new options, else add default options
          if (SelectDeselectAllOptions && dropdown) {
            let buttons = {
              tagName: "div",
              type: "select-deselect-all-options-buttons",
              attributes: {
                class: "select-deselect-all-options-buttons",
              },

              "style-default": {
                width: "100%",
                border: "1px solid lightgray",
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                "gap": "0",
              },

              style: {
                width: "100%",
                border: "1px solid lightgray",
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                "gap": "0",
              },

              components: [
                {
                  tagName: "button",
                  type: "select-all-button",
                  attributes: {
                    class: "select-all-button",
                  },

                  "style-default": {
                    width: "50%",
                    padding: "2%",
                    "border-right": "1px solid lightgray",
                    cursor: "pointer",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    "gap": "0",
                  },

                  style: {
                    width: "50%",
                    padding: "2%",
                    "border-right": "1px solid lightgray",
                    cursor: "pointer",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    "gap": "0",
                  },

                  components: {
                    tagName: "p",
                    type: "text",
                    editable: true,

                    components: {
                      type: "textnode",
                      content: "Select all",
                    },

                    "style-default": {
                      margin: "auto",
                      "text-align": "center",
                    },

                    style: {
                      margin: "auto",
                      "text-align": "center",
                    },
                  },
                }, {
                  tagName: "button",
                  type: "deselect-all-button",
                  attributes: {
                    class: "deselect-all-button",
                  },

                  "style-default": {
                    width: "50%",
                    padding: "2%",
                    "border-left": "1px solid lightgray",
                    cursor: "pointer",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    "gap": "0",
                  },

                  style: {
                    width: "50%",
                    padding: "2%",
                    "border-left": "1px solid lightgray",
                    cursor: "pointer",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    "gap": "0",
                  },

                  components: {
                    tagName: "p",
                    type: "text",
                    editable: true,

                    components: {
                      type: "textnode",
                      content: "Deselect all",
                    },

                    "style-default": {
                      margin: "auto",
                      "text-align": "center",
                    },

                    style: {
                      margin: "auto",
                      "text-align": "center",
                    },
                  },
                },
              ],
            };

            dropdown.append(buttons, {at: 0});
          } else {
            // else remove these buttons if they already exists or dont add new ones
            if (dropdown) {
              let buttonsExistsAlready = dropdown.find(".select-deselect-all-options-buttons")[0];
              if (buttonsExistsAlready) {
                buttonsExistsAlready.remove();
              }
            }
          }

          // this.attributes.script.call(this.getEl(), this.props());
          this.view.render();
        },

        buttonStyleTraitHandler() {
          let { ButtonStyle } = this.props();
          let container = this;
          let dropdownBtn = container.find(".dropdown-btn")[0];

          let dropdownBtnStyle = dropdownBtn.getStyle();
          // Create a new object to hold the updated styles
          let updatedStyle = { ...dropdownBtnStyle };  // Start with the existing styles

          if (ButtonStyle === "default") {
            updatedStyle["background-color"] = "white";
          } else if (ButtonStyle === "primary") {
            updatedStyle["background-color"] = "#0d6efd";
          } else if (ButtonStyle === "info") {
            updatedStyle["background-color"] = "#0dcaf0";
          } else if (ButtonStyle === "success") {
            updatedStyle["background-color"] = "#198754";
          } else if (ButtonStyle === "warning") {
            updatedStyle["background-color"] = "#ffc107";
          } else {
            updatedStyle["background-color"] = "#dc3545";
          }

          // Now set the updated style back to the button
          dropdownBtn.setStyle(updatedStyle);
        },

        async plottingSelectDDTraitHandler() {
          let {
            CustomUrl,
            BaseUrl,
            APIPath,
            BearerToken,
            Username,
            Password,
            MoreHeaders,
            APIMethod,
            APIBody,
            ArrOfObjKyename,
            NestedKeyname,
            options,
          } = this.props();

          let apiData;
          let container = this;
          let dropdown = container.find(".dropdown-content")[0];

          // to rename a variable because API var is getting used in many places in this block scope
          let API;
          if (APIPath) {
            API = APIPath;
          }

          // helper function to check if a variable is an array-of-objects or not
          function isArrayofObjects(variable) {
            // Check if the variable is an array
            if (!Array.isArray(variable)) {
              return false;
            }

            // Check if all elements in the array are objects
            for (const element of variable) {
              if (
                typeof element !== "object" ||
                Array.isArray(element) ||
                element === null
              ) {
                return false;
              }
            }
            return true;
          }

          // helper function to get value of a key in nested object
          function findKeyValue(obj, key) {
            if (obj.hasOwnProperty(key)) {
              // If the current object has the key, return its value
              return obj[key];
            }

            for (var prop in obj) {
              if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
                // If the current property is an object, recursively search within it
                var result = findKeyValue(obj[prop], key);
                if (result !== undefined) {
                  return result;
                }
              }
            }
            // Key not found in the object
            return undefined;
          }

          // -------- Getting API Data (GET method)

          // if CustomUrl is provided, use only that
          if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "GET" ) {
            console.log("CustomUrl", CustomUrl);
            // if nothing is provided for auth
            if (!BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          else if (BaseUrl && API && !CustomUrl && APIMethod === "GET") {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            if (BaseUrl === "baseUrl1") {
              url = options.baseUrl1 + API;
              baseUrlBearerToken = options.baseUrl1BearerToken;
            } else if (BaseUrl === "baseUrl2") {
              url = options.baseUrl2 + API;
              baseUrlBearerToken = options.baseUrl2BearerToken;
            } else if (BaseUrl === "baseUrl3") {
              url = options.baseUrl3 + API;
              baseUrlBearerToken = options.baseUrl3BearerToken;
            } else if (BaseUrl === "baseUrl4") {
              url = options.baseUrl4 + API;
              baseUrlBearerToken = options.baseUrl4BearerToken;
            } else if (BaseUrl === "baseUrl5") {
              url = options.baseUrl5 + API;
              baseUrlBearerToken = options.baseUrl5BearerToken;
            }
            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

            // if nothing is provided for auth
            if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              baseUrlBearerToken &&
              !BearerToken &&
              !Username &&
              !Password
            ) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: `Bearer ${baseUrlBearerToken}`,
                  "Content-Type": "application/json",
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // -------- Getting API Data (POST method)

          // if CustomUrl is provided, use only that
          else if (
            CustomUrl &&
            (!BaseUrl || BaseUrl === "null") &&
            APIMethod === "POST"
          ) {
            console.log("CustomUrl", CustomUrl);
            // if nothing is provided for auth
            if (!BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          else if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            if (BaseUrl === "baseUrl1") {
              url = options.baseUrl1 + API;
              baseUrlBearerToken = options.baseUrl1BearerToken;
            } else if (BaseUrl === "baseUrl2") {
              url = options.baseUrl2 + API;
              baseUrlBearerToken = options.baseUrl2BearerToken;
            } else if (BaseUrl === "baseUrl3") {
              url = options.baseUrl3 + API;
              baseUrlBearerToken = options.baseUrl3BearerToken;
            } else if (BaseUrl === "baseUrl4") {
              url = options.baseUrl4 + API;
              baseUrlBearerToken = options.baseUrl4BearerToken;
            } else if (BaseUrl === "baseUrl5") {
              url = options.baseUrl5 + API;
              baseUrlBearerToken = options.baseUrl5BearerToken;
            }
            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

            // if nothing is provided for auth
            if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              baseUrlBearerToken &&
              !BearerToken &&
              !Username &&
              !Password
            ) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${baseUrlBearerToken}`,
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          console.log("apiData:", apiData);

          // -------- Populating API Data, into the dropdown list
          if (apiData !== undefined) {
            console.log("#");
            // if API response is directly an array-of-objects
            if (isArrayofObjects(apiData)) {
              if (ArrOfObjKyename) {
                console.log("##");
                // remove all components in dropdown except the search input
                dropdown.components("");
                dropdown.append({
                  tagName: "input",
                  type: "dropdown-search",
                  attributes: {
                    type: "text",
                    class: "dropdown-search",
                    placeholder: "Search options here...",
                  },

                  "style-default": {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },

                  style: {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },
                });

                // add new options
                apiData.forEach((opt) => {
                  let optionEle = {
                    tagName: "div",
                    type: "dropdown-option",
                    attributes: {
                      "data-value": JSON.stringify({
                        _id: opt['_id'],
                        ArrOfObjKyename: opt[`${ArrOfObjKyename}`],
                      }),

                      "data_db_id": `${opt[`_id`]}` ? `${opt[`_id`]}` : `${opt[`${ArrOfObjKyename}`]}`,
                    },

                    components: [
                      {
                        tagName: "input",
                        type: "dropdown-checkbox",
                        attributes: {
                          type: "checkbox",
                        },
                      }, {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: opt[`${ArrOfObjKyename}`],
                        },

                        // "style-default": {
                        //   margin: "auto",
                        //   "text-align": "center",
                        // },

                        // style: {
                        //   margin: "auto",
                        //   "text-align": "center",
                        // },
                      },
                    ],
                  };

                  dropdown.append(optionEle);
                });
              }
            }

            // if API response is a plain array of single elements
            else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
              console.log("###");
              // remove all components in dropdown except the search input
              dropdown.components("");
              dropdown.append({
                tagName: "input",
                type: "dropdown-search",
                attributes: {
                  type: "text",
                  class: "dropdown-search",
                  placeholder: "Search options here...",
                },

                "style-default": {
                  width: "100%",
                  padding: "8px",
                  border: "none",
                  "border-bottom": "1px solid #ddd",
                  "box-sizing": "border-box",
                },

                style: {
                  width: "100%",
                  padding: "8px",
                  border: "none",
                  "border-bottom": "1px solid #ddd",
                  "box-sizing": "border-box",
                },
              });

              apiData.forEach((opt) => {
                let optionEle = {
                  tagName: "div",
                  type: "dropdown-option",
                  attributes: {
                    "data-value": `${opt}`,
                  },

                  components: [
                    {
                      tagName: "input",
                      type: "dropdown-checkbox",
                      attributes: {
                        type: "checkbox",
                      },
                    }, {
                      tagName: "p",
                      type: "text",
                      editable: true,

                      components: {
                        type: "textnode",
                        content: `${opt}`,
                      },

                      // "style-default": {
                      //   margin: "auto",
                      //   "text-align": "center",
                      // },

                      // style: {
                      //   margin: "auto",
                      //   "text-align": "center",
                      // },
                    },
                  ],
                };

                dropdown.append(optionEle);
              });
            }

            // if API response is an object
            else if (!isArrayofObjects(apiData) && typeof apiData === "object") {
              console.log("#@");
              // if API response to plot is a nested array-of-objects inside the main response object
              if (NestedKeyname && ArrOfObjKyename) {
                console.log("####");
                // remove all components in dropdown except the search input
                dropdown.components("");
                dropdown.append({
                  tagName: "input",
                  type: "dropdown-search",
                  attributes: {
                    type: "text",
                    class: "dropdown-search",
                    placeholder: "Search options here...",
                  },

                  "style-default": {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },

                  style: {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },
                });

                // find the array-of-objects value
                let data = findKeyValue(apiData, NestedKeyname);

                if (isArrayofObjects(data)) {
                  data.forEach((opt) => {
                    let optionEle = {
                      tagName: "div",
                      type: "dropdown-option",
                      attributes: {
                        "data-value": JSON.stringify({
                          _id: opt['_id'],
                          ArrOfObjKyename: opt[`${ArrOfObjKyename}`],
                        }),

                        "data_db_id": `${opt[`_id`]}` ? `${opt[`_id`]}` : `${opt[`${ArrOfObjKyename}`]}`,
                      },

                      components: [
                        {
                          tagName: "input",
                          type: "dropdown-checkbox",
                          attributes: {
                            type: "checkbox",
                          },
                        }, {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: opt[`${ArrOfObjKyename}`],
                          },

                          // "style-default": {
                          //   margin: "auto",
                          //   "text-align": "center",
                          // },

                          // style: {
                          //   margin: "auto",
                          //   "text-align": "center",
                          // },
                        },
                      ],
                    };

                    dropdown.append(optionEle);
                  });
                }
              } else if (NestedKeyname && !ArrOfObjKyename) {
                console.log("####@");
                // remove all components in dropdown except the search input
                dropdown.components("");
                dropdown.append({
                  tagName: "input",
                  type: "dropdown-search",
                  attributes: {
                    type: "text",
                    class: "dropdown-search",
                    placeholder: "Search options here...",
                  },

                  "style-default": {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },

                  style: {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },
                });

                // find the array value
                let data = findKeyValue(apiData, NestedKeyname);

                // if API response to plot is a nested plain array of single elements inside the main response object
                if (Array.isArray(data) && !isArrayofObjects(data)) {
                  console.log("#####");
                  data.forEach((opt) => {
                    let optionEle = {
                      tagName: "div",
                      type: "dropdown-option",
                      attributes: {
                        "data-value": `${opt}`,
                      },

                      components: [
                        {
                          tagName: "input",
                          type: "dropdown-checkbox",
                          attributes: {
                            type: "checkbox",
                          },
                        }, {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: `${opt}`,
                          },

                          // "style-default": {
                          //   margin: "auto",
                          //   "text-align": "center",
                          // },

                          // style: {
                          //   margin: "auto",
                          //   "text-align": "center",
                          // },
                        },
                      ],
                    };

                    dropdown.append(optionEle);
                  });
                }

                // if API response to plot are all key:value pairs of a plain object inside main object
                else if (!Array.isArray(data) && !isArrayofObjects(data) && typeof data === "object") {
                  console.log("######");
                  Object.keys(data).forEach((key) => {
                    if (!Array.isArray(data[`${key}`]) && typeof data[`${key}`] !== "object") {
                      let optionEle = {
                        tagName: "div",
                        type: "dropdown-option",
                        attributes: {
                          "data-value": JSON.stringify({
                            _id: data['_id'] ? data['_id'] : '',
                            key: data[`${key}`] ? data[`${key}`] : '',
                          }),
                        },

                        components: [
                          {
                            tagName: "input",
                            type: "dropdown-checkbox",
                            attributes: {
                              type: "checkbox",
                            },
                          }, {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${key}`,
                            },

                            // "style-default": {
                            //   margin: "auto",
                            //   "text-align": "center",
                            // },

                            // style: {
                            //   margin: "auto",
                            //   "text-align": "center",
                            // },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    }
                  });
                }
              }

              // if API response to plot are all key:value pairs of the plain object
              else if (!NestedKeyname && !ArrOfObjKyename) {
                console.log("#######@");
                // remove all components in dropdown except the search input
                dropdown.components("");
                dropdown.append({
                  tagName: "input",
                  type: "dropdown-search",
                  attributes: {
                    type: "text",
                    class: "dropdown-search",
                    placeholder: "Search options here...",
                  },

                  "style-default": {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },

                  style: {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },
                });

                Object.keys(apiData).forEach((key) => {
                  if (!Array.isArray(apiData[`${key}`]) && typeof apiData[`${key}`] !== "object") {
                    let optionEle = {
                      tagName: "div",
                      type: "dropdown-option",
                      attributes: {
                        "data-value": JSON.stringify({
                          _id: apiData['_id'] ? apiData['_id'] : '',
                          key: apiData[`${key}`] ? apiData[`${key}`] : '',
                        }),
                      },

                      components: [
                        {
                          tagName: "input",
                          type: "dropdown-checkbox",
                          attributes: {
                            type: "checkbox",
                          },
                        }, {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: `${key}`,
                          },

                          // "style-default": {
                          //   margin: "auto",
                          //   "text-align": "center",
                          // },

                          // style: {
                          //   margin: "auto",
                          //   "text-align": "center",
                          // },
                        },
                      ],
                    };

                    dropdown.append(optionEle);
                  }
                });
              }

              // if there is a 'message' in api response instead of data
              else if (Object.keys(apiData).includes("message")) {
                console.log("#@@");
                // remove all components in dropdown except the search input
                dropdown.components("");
                dropdown.append({
                  tagName: "input",
                  type: "dropdown-search",
                  attributes: {
                    type: "text",
                    class: "dropdown-search",
                    placeholder: "Search options here...",
                  },

                  "style-default": {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },

                  style: {
                    width: "100%",
                    padding: "8px",
                    border: "none",
                    "border-bottom": "1px solid #ddd",
                    "box-sizing": "border-box",
                  },
                });

                // initializing with a disabled first option
                let disabledOptionEle = {
                    tagName: "div",
                    type: "dropdown-option",
                    attributes: {
                      "data-value": "",
                      disabled: true,
                      selected: true,
                    },

                    components: [
                      {
                        tagName: "input",
                        type: "dropdown-checkbox",
                        attributes: {
                          type: "checkbox",
                        },
                      }, {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: `No Values.`,
                        },

                        // "style-default": {
                        //   margin: "auto",
                        //   "text-align": "center",
                        //   "color": "gray",
                        //   "pointer-events": "none",
                        // },

                        // style: {
                        //   margin: "auto",
                        //   "text-align": "center",
                        //   "color": "gray",
                        //   "pointer-events": "none",
                        // },
                      },
                    ],
                  };

                dropdown.append(disabledOptionEle);
              }
            }
          }

          this.set("SetOptionsManually", "");
          // this.attributes.script.call(this.getEl(), this.props());
          this.view.render();
        },
      },
    });

    editor.on("component:mount", (component) => {
      if (component.get("type") === "input_select_with_search") {
        let childSearchSelect = component.find("select.selectpicker")[0];
        let childSearchSelectInitId = childSearchSelect.getEl().getAttribute("id");
        // console.log("childSearchSelectInitId", childSearchSelectInitId);

        let childSearchSelectIdInParent = component.getAttributes()["data-child-search-select-id"];
        // console.log("childSearchSelectIdInParent", childSearchSelectIdInParent);
        if (childSearchSelectIdInParent === "") {
          // add search select's id to parent
          component.addAttributes({
            "data-child-search-select-id": `${childSearchSelectInitId}`,
          });
        } else {
          // take the id from parent and add it back to search select
          childSearchSelect.addAttributes({
            id: `${childSearchSelectIdInParent}`,
          });
        }

        // console.log("childSearchSelectInitId new", childSearchSelect.getEl().getAttribute('id'));
      }
    });
  }
};

export default customFormElements;
