import biBlocks from "./utils/bi_blockly/blocks/bi_blockly";
import biBlocksJS from "./utils/bi_blockly/generators/javascript/bi_blockly";
import commands from "./commands";
import { API_HOST } from "../../../../api_utils";
import { error } from "jquery";

function createModal() {
  const modal = document.createElement("div");
  modal.id = "myModal";
  modal.style.display = "none";
  modal.style.position = "fixed";
  modal.style.zIndex = "1000";
  modal.style.left = "0";
  modal.style.top = "0";
  modal.style.width = "100%";
  modal.style.height = "100%";
  modal.style.overflow = "auto";
  modal.style.backgroundColor = "rgba(0,0,0,0.5)";

  const modalContent = document.createElement("div");
  modalContent.style.position = "absolute";
  modalContent.style.top = "50%";
  modalContent.style.left = "50%";
  modalContent.style.transform = "translate(-50%, -50%)";
  modalContent.style.width = "50vw";
  modalContent.style.height = "50vh";
  modalContent.style.backgroundColor = "white";
  modalContent.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.25)";
  modalContent.style.borderRadius = "10px";
  modalContent.style.display = "flex";
  modalContent.style.alignItems = "center";
  modalContent.style.justifyContent = "center";
  modalContent.style.textAlign = "center";
  modalContent.style.padding = "20px";

  // Create the close button
  const closeButton = document.createElement("span");
  closeButton.className = "close";
  closeButton.innerHTML = "&times;";
  closeButton.style.position = "absolute";
  closeButton.style.top = "10px";
  closeButton.style.right = "20px";
  closeButton.style.color = "black";
  closeButton.style.fontSize = "30px";
  closeButton.style.fontWeight = "bold";
  closeButton.style.cursor = "pointer";

  // Create the modal message
  const modalMessage = document.createElement("p");
  modalMessage.id = "modalMessage";

  // Append elements
  modalContent.appendChild(closeButton);
  modalContent.appendChild(modalMessage);
  modal.appendChild(modalContent);
  document.body.appendChild(modal);

  // Close the modal when the user clicks on <span> (x)
  closeButton.onclick = function () {
    modal.style.display = "none";
  };

  // Close the modal when the user clicks anywhere outside of the modal
  window.onclick = function (event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  };

  return modal;
}

// Function to open modal with a message
function openModal(message, val) {
  const modal = document.getElementById("myModal") || createModal();
  const modalMessage = modal.querySelector("#modalMessage");
  modalMessage.textContent = message;
  modal.style.display = "block";
  modalMessage.style.fontSize = "1.5rem";
  modalMessage.style.letterSpacing = "0.05rem";
  if (val === "success") {
    modalMessage.style.color = "#15803d";
  } else {
    modalMessage.style.color = "#b91c1c";
  }
}

let tableData;
let tableName;
let trackProjectChange;

function getAllNotifications(projectId) {
  return new Promise((resolve, reject) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // const apihost = "http://127.0.0.1:8085/";
    
    // const apihost = "https://backend1.redsling.com/";

    const apihost = "https://staging-backend1.redsling.com/";

    fetch(`${apihost}customroutes/get/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      body: JSON.stringify({ projectId: projectId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          tableData = data;
          let tableNameNew = data.map((x) => {
            return x.table_name;
          });

          tableName = tableNameNew.map((name) => [name, name]);

          console.log("tableData::::::", tableData);
          console.log("tableName::::::", tableName);

          resolve({ tableName, tableData });
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
        reject(error);
      });
  });
}

setInterval(() => {
  if (window.location.pathname.includes("editor")) {
    const projectId = window.location.search.split("=")[1];
    if (projectId !== trackProjectChange) {
      trackProjectChange = projectId;
      getAllNotifications(projectId);
    }
  }
}, 2000);

export default (editor, opts = {}) => {
  const options = {
    ...{
      // Blockly options
      blocklyOptions: {
        toolbox: "",
        toolboxPush: "",
        workspaceOptions: {},
      },

      // Starter xml
      starter:
        '<xml xmlns="https://developers.google.com/blockly/xml"><block type="bi_var" id="OHq;p^O:()AR3;aG%CwD" x="90" y="30"><field name="var_type">let</field><field name="var">el</field><value name="val"><block type="bi_var_name" id="Je,,~AE%W2MCRA?7u.^0"><field name="NAME">this</field></block></value></block></xml>',

      toolbarIcon: '<i class="fa fa-puzzle-piece"></i>',

      // Component types to allow script editing
      // Avoid components with predefined scripts
      blocklyTypesSupport: [
        "default",
        "wrapper",
        "text",
        "textnode",
        "image",
        "video",
        "svg",
        "cell",
        "row",
        "table",
        "thead",
        "tfoot",
        "map",
        "link",
        "label",
        "script",
        "comment",
        // "customCardElementWrapperFH",
        // "customCardParentFH",
        // "customCardWrapperFH",
        // "listCardHeader",
        // "listCardWrapper",
        // "customListCard",
        // "customCardElementWrapper",
        // "customCardParent",
        // "customCardWrapper",
        // "headerText",
        // "bodyText",
        // "customIcons",
        // "customTd",
        // "customTdWrapper",
        // "customPagination",
        // "customToolbar",
        // "customTableWrapper",
        // "login_universal",
        // "login_fraunhofer",
        // "lineChart",
        // "barChart",
        // "bubbleChart",
        // "doughnutChart",
        // "pieChart",
        // "polarAreaChart",
        // "lineBarBubbleMixChart",
        // "radio-regular-form",
        // "smiley-ratings-slider",
        // "colorPalette-regular-form",
        // "week-regular-form",
        // "monthYear-regular-form",
        // "time-regular-form",
        // "file-regular-form",
        // "number-regular-form",
        // "range-regular-form",
        // "reset-regular-form",
        // "center-aligned-element-container",
        // "test-multiple",
        // "rest-api-display",
        // "g-translate",
        // "calendy-admin",
        // "3d-urdf",
        // "calendy",
        // "calendly-btn",
        // "stellar-button",
        // "predefined-email-dispatch",
        // "customize-receiver-email-dispatch",
        // "popup-button",
        // "page-popup",
        // "selections-regular-form",
        // "input-select",
        // "text-box-auto-search",
        // "progress-bar-height1px",
        // "ion-toggle-menu",
        // "offcanvas-menu-btn",
        // "offcanvas-menu",
        // "regularform",
        // "dynamicForm",
      ],

      // Object to extend the default component's toolbar button for the code, eg. `{ label: '</>', attributes: { title: 'Open blockly editor' } }`
      // Pass a falsy value to avoid adding the button
      toolbarBtnBlockly: {},

      // On run success
      onRun: () => {
        let msg = "There is no syntax error !!!";
        openModal(msg, "success");
      },

      // Logic when there is an error on run
      onError: (err) => {
        let msg = `Error: ${err}`;
        openModal(msg, "error");
      },

      // Title for the blockly modal
      modalTitle: "Blockly",

      // Additional options for the code viewer, eg. `{ theme: 'hopscotch', readOnly: 0 }`
      codeViewOptions: {},

      // Label for the default save button
      buttonLabel: "Save",

      // Object to extend the default blockly command.
      // Check the source to see all available methods
      commandBlocklyScript: {},
    },
    ...opts,
  };

  if (!Blockly) {
    throw new Error("Blockly instance not found");
  }

  // set blockly globally
  biBlocks(Blockly, editor, tableName, tableData);

  biBlocksJS(Blockly, editor, API_HOST, tableData);

  // load commands
  commands(editor, options);
};
